import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Creators as InfoCreators } from "~/store/ducks/info";
import { colors } from "~/styles";

import {
  Content,
  TechBox,
  TechLabel,
  TechCloseButton,
  TechClose,
  Info,
  BoxInfo,
  ButtonList,
  IconBlock,
  IconList
} from "./style";

/* eslint eqeqeq: 0 */
class ShowHeader extends Component {
  handleFilter = () => {
    this.props.setMode("dashboard");
  };

  handleList = e => {
    e.preventDefault();
    this.props.toogleList();
  };

  ShowTech = () => {
    const { mode, tech, letter } = this.props;

    if (["technologies", "letter"].includes(mode)) {
      return (
        <TechBox color={tech ? tech.backgroundColor : colors.backgroundSignals}>
          <TechLabel>
            {mode == "technologies" ? tech.nome : `Iniciando com: "${letter}"`}
            <TechCloseButton onClick={this.handleFilter}>
              <TechClose />
            </TechCloseButton>
          </TechLabel>
        </TechBox>
      );
    }
    return <div />;
  };

  ShowInfo = () => {
    const { current, listFormat } = this.props;

    return (
      <BoxInfo>
        <Info>
          Exibindo {current.data.edges.length} de {current.data.totalCount}
        </Info>
        <ShowIcon listFormat={listFormat} action={this.handleList} />
      </BoxInfo>
    );
  };

  render() {
    const { ShowTech, ShowInfo } = this;
    const { current, mode } = this.props;

    if (!current.data) return null;

    return (
      <Content show={["technologies", "letter"].includes(mode)}>
        <ShowTech />
        <ShowInfo />
      </Content>
    );
  }
}

const ShowIcon = ({ listFormat, action }) => {
  return (
    <ButtonList onClick={action} aria-label="Mudar estilo da lista de verbetes">
      {listFormat ? <IconBlock /> : <IconList />}
    </ButtonList>
  );
};

const mapStateToProps = state => ({
  tech: state.technologies.set,
  mode: state.info.mode,
  letter: state.letter.letter,
  listFormat: state.info.listFormat
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setMode: InfoCreators.setMode,
      toogleList: InfoCreators.listFormatRequest
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShowHeader);
