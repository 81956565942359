import styled from "styled-components";
import { colors, fonts } from "~/styles";
// import { MaterialCommunityIcons } from "react-web-vector-icons";

// import { MIN_TAB, MIN_WEB } from "~/constants/config";

// import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  /* border: 1px solid red; */
  margin-bottom: 10px;
`;

export const Button = styled.button`
  background-color: #dbe5ff;
  border-radius: 6px;
  width: 110px;
  height: 40px;

  :hover {
    background-color: #4a7aff;
    & span {
      color: #c7d6fa;
    }
  }

  :active {
    background-color: #c7d6fa;
    & span {
      color: white;
    }
  }
`;

export const Label = styled.span`
  color: ${colors.primary};
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 16px;

  /* & :hover {
    color: #4a7aff;
  } */
`;
