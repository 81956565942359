import styled from "styled-components";
import { fonts } from "~/styles";
import { lighten } from "polished";

export const Tabs = styled.div`
  display: flex;
  button {
    width: 50%;
  }
  margin-bottom: 30px;
`;

export const TabButton = styled.button`
  padding: 10px;
  font-weight: bold;
  color: #4b78fb;
  opacity: ${(e) => (e.active ? 1 : 0.5)};
`;
export const Error = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 25px;
  background: ${lighten(0.4, "red")};
  color: rgb(255, 0, 0);
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 14px;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 20px;
`;
