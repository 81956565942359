import React, { Component } from "react";
import { Helmet } from 'react-helmet';

import { PoliciesRoute, PoliciesDescription } from '~/constants/routesName';

import senai_logo from "~/assets/image/senai_logo.png";

import {
  Container,
  TitleDiv,
  Title,
  SubTitle,
  Paragraph,
  Topic,
  ListParagraph,
  List,
  Logo,
  Image
} from "./style";

export default class PrivacyPolicies extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <title>{PoliciesRoute}</title>
          <meta name="description" content={PoliciesDescription}></meta>
        </Helmet>
        <TitleDiv>
          <Title>Política de Privacidade</Title>
        </TitleDiv>
        <Image>
          <Logo src={senai_logo} />
        </Image>
        <Container>
          <SubTitle>Programa SENAI de Ações Inclusivas</SubTitle>
          <SubTitle>Política de Privacidade APP SENAI LIBRAS</SubTitle>

          <Paragraph>
            <b>Última atualização:</b> agosto de 2019.
          </Paragraph>
          <Topic>Finalidade</Topic>
          <Paragraph>
            O Departamento Nacional do SENAI, no âmbito do programa SENAI de
            Ações Inclusivas desenvolveu um aplicativo móvel de realidade
            virtual, denominado LIBRAS SENAI – servindo de interface para acesso
            dos docentes, alunos e demais profissionais dos Departamentos
            Regionais e Unidades Operacionais por meio da ACESSIBILIDADE
            INSTRUMENTAL.
          </Paragraph>
          <Paragraph>
            A finalidade dessa Política de Privacidade é descrever como o SENAI
            coleta, utiliza e compartilha informações particulares por meio do
            aplicativo para dispositivos móveis.
          </Paragraph>
          <Paragraph>
            Leia esta notificação cuidadosamente para entender o que fazemos. Se
            não compreender algum aspecto da nossa Política de Privacidade, fale
            conosco pelo e-mail abertoldi@senaicni.com.br.
          </Paragraph>
          <Topic>As informações que esta Política de Privacidade cobre</Topic>
          <Paragraph>
            Esta Política de Privacidade cobre informações que coletamos sobre
            você por meio do nosso aplicativo para dispositivos móveis SENAI
            LIBRAS APP.
          </Paragraph>
          <Paragraph>
            O uso de recursos para realização de interpretação e tradução de
            termos técnicos dos cursos oferecidos nas áreas tecnológicas do
            SENAI pode requerer “informações pessoais” (ou seja, informações que
            podem ser usadas para identificá-lo). Informações pessoais podem
            incluir, por exemplo, seu nome, estado e endereço de e-mail, entre
            outros dados. Você é responsável por manter sua senha de utilização
            segura, caso haja. Informações incorretas podem afetar sua
            capacidade de usar o aplicativo, as informações que você recebe
            quando usa o aplicativo e a nossa capacidade de contatá-lo.
          </Paragraph>
          <Topic>Coleta de informações</Topic>
          <ListParagraph>
            Quando você acessa o aplicativo SENAI LIBRAS APP, as seguintes
            informações poderão ser coletadas:
          </ListParagraph>
          <List>
            <li>Nome de usuário (Login);</li>
            <li>Estado;</li>
            <li>Senha;</li>
            <li>Nome completo;</li>
            <li>E-mail;</li>
            <li>Curso;</li>
            <li>Necessidades especial.</li>
          </List>
          <Paragraph>
            Também poderão ser coletadas informações de utilização do aplicativo
            relativas a melhoria e inserção de termos técnicos pelos usuários
            (alunos, docentes e equipe dos Departamentos Regionais e Unidades
            Operacionais) essas informações podem ser: mensagem de texto,
            imagens, arquivos com vídeo ou áudio.
          </Paragraph>
          <Topic>Links externos</Topic>
          <Paragraph>
            Para auxiliar no desenvolvimento das capacidades requeridas nos
            diferentes cursos ofertados pelo SENAI poderemos fornecer links para
            sites ou aplicativos operados por organizações diferentes do SENAI
            (“sites ou aplicativos de terceiros”) que acreditamos ser relevantes
            para o processo de aprendizagem. Não revelamos suas informações
            pessoais para esses sites ou aplicativos de terceiros. Não
            endossamos e não somos responsáveis pelas práticas de privacidade
            desses sites ou aplicativos de terceiros. Se você clicar no link
            para um desses sites ou aplicativos de terceiros, deve revisar a
            política de privacidade publicada no outro site para entender como o
            site desse terceiro coleta e usa suas informações pessoais.
          </Paragraph>
          <Topic>
            Utilização das informações, imagens, arquivos com vídeo ou áudio
          </Topic>
          <Paragraph>
            As informações, imagens, arquivos com vídeo ou áudio e dados dos
            usuários serão coletados e/ou recebidos pelo SENAI LIBRAS APP e
            armazenados em seu banco de dados, se necessário.
          </Paragraph>
          <Topic>Alterações nesta Política de Privacidade</Topic>
          <Paragraph>
            O SENAI se reserva o direito de alterar os termos desta Política de
            Privacidade a qualquer momento. Quando houver alguma alteração, a
            data da última atualização exibida será modificada na parte superior
            deste documento. No caso de alterações significativas nesta política
            ou na forma como são usadas suas informações, uma notificação será
            feita via e-mail previamente cadastrado
          </Paragraph>
        </Container>
      </div>
    );
  }
}
