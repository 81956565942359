import styled from "styled-components";
import { FontAwesome } from "react-web-vector-icons";

import { MIN_TAB, MIN_WEB, MIN_CEL } from "~/constants/config";

import { colors, fonts } from "~/styles";

export const Header = styled.div`
  z-index: 200;
  background-color: ${colors.headerBackground};

  @media only screen and (min-width: ${MIN_TAB}px) {
    padding: 0 calc(10% + 10px);
  }
  @media only screen and (min-width: ${MIN_WEB}px) {
    padding: 0 calc(8% + 10px);
  }
  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 0 10px;
  }
`;

export const Content = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  /* grid-gap: 45px 0px; */
  padding: 25px 0;

  @media only screen and (max-width: ${MIN_WEB}px) {
    grid-template-rows: auto;
    grid-template-areas:
      ". divCenter ."
      "divLeft . divRight";
  }
`;

export const Center = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 10px 0;
  width: fit-content;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: ${MIN_WEB}px) {
    grid-area: divCenter;
    position: absolute;
    left: 0;
    right: 0;
  }
`;

export const AsideLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  @media only screen and (max-width: ${MIN_WEB}px) {
    grid-area: divLeft;
  }
`;

export const AsideRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  @media only screen and (max-width: ${MIN_WEB}px) {
    grid-area: divRight;
  }
`;

export const BackButton = styled.button`
  display: flex;
  padding: 5px 10px;
  align-items: center;
`;

export const BackText = styled.span`
  font-family: "VisbyRoundCF-Medium";
  font-weight: bold;
  padding: 0 5px;
  color: white;
  padding: 10px 10px;
`;

export const IconsArrowLeft = styled(FontAwesome).attrs((props) => ({
  name: "arrow-left",
  size: 20,
  color: "white",
}))``;

export const AudiodescricaoImg = styled.img``;

export const AudiodescricaoText = styled.p`
  padding-left: 10px;
`;

export const LoboBox = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-left: 25px;
  & :hover {
    cursor: pointer !important;
  }
`;

export const Logo = styled.img.attrs((props) => ({}))`
  max-width: 150px;
  height: auto;

  :hover {
    cursor: pointer;
  }

  @media only screen and (max-width: 375px) {
    max-width: 100px;
  }
`;

export const AudioDescriptionButton = styled.button`
  margin-left: 80px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: auto;
  height: 40px;
  border: 1px solid #fff;
  border-radius: 5px;
  font-weight: ${fonts.VisbyRoundLight};
  color: #fff;
  padding: 0 10px;

  @media only screen and (max-width: 1800px) {
    margin-left: 35px;
  }

  @media only screen and (max-width: 1024px) {
    margin-left: 0;
  }
`;
