const handleCursor = cursor => {
  if (cursor) return `"${cursor}"`;
  return "null";
};

export const dashboard = (cursor = "") => ({
  query: `
    query($cursor: String) {
      sinals(first: 20, after: $cursor) {
        totalCount
        edges {
          node {
            _id
            titulo
            descricaoMovimento
            iconeRepresentativo
            persona {
              id
              nome
              avatar
            }
            areaTecnologica {
              _id
              nome
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `,
  variables: `{
    "cursor": ${handleCursor(cursor)}
  }`
});

export const search = (cursor = null, search = "") => ({
  query: `
    query ($cursor: String, $titulo: String) {
      sinals(first: 20, after: $cursor, titulo: $titulo) {
        totalCount
        edges {
          node {
            _id
            titulo
            descricaoMovimento
            iconeRepresentativo
            persona {
              id
              nome
              avatar
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `,
  variables: `
  {
    "cursor": ${handleCursor(cursor)},
    "titulo": "%${search}"
  }`
});

export const signal = id => ({
  query: `
    query($id: ID!) {
      sinal(id: $id) {
        _id
        titulo
        descricao
        descricaoMovimento
        iconeRepresentativo
        isVideo
        arquivos {
          edges {
            node {
              caminho
            }
          }
        }
        persona {
          id
          nome
          avatar
        }
      }
    }
  `,
  variables: `{
    "id": "/sinals/${id}"
  }`
});

export const buscarPorAreaTecnologica = (id, cursor = "") => ({
  query: `
    query($id: ID!, $cursor: String) {
      areaTecnologica(id: $id) {
        sinais(first: 20, after: $cursor){
          totalCount
          edges {
            node {
              _id
              titulo
              descricaoMovimento
              iconeRepresentativo
              areaTecnologica {
                _id
                nome
              }
              persona {
                id
                nome
                avatar
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  `,
  variables: `{
    "id": "/area_tecnologicas/${id}",
    "cursor": ${handleCursor(cursor)}
  }`
});

export const buscarUsuarioLogado = () => ({
  query: `
    query {
      logadoUsuario {
        name
        email
        roles
        avatar
      }
    }
  `
});

export const technologies = () => ({
  query: `
    query ($first: Int, $after: String) {
      areaTecnologicas(first: $first, after: $after) {
        totalCount
        edges {
          node {
            _id
            nome
            backgroundColor
            iconeRepresentativo
            isCategoria
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `
  // variables: `{
  // }`,
});

export const technology = (cursor = "", technology) => ({
  query: `
    query ($id: ID!, $cursor: String) {
      areaTecnologica(id: $id) {
        sinais(first: 20, after: $cursor) {
          totalCount
          edges {
            node {
              _id
              titulo
              descricaoMovimento
              iconeRepresentativo
              persona {
                id
                nome
                avatar
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  `,
  variables: `{
    "id": "/area_tecnologicas/${technology}",
    "cursor": ${handleCursor(cursor)}
  }`
});

export const letter = (cursor = "", letter = "A") => ({
  query: `
    query ($cursor: String, $titulo: String) {
      sinals(first: 20, after: $cursor, titulo: $titulo) {
        totalCount
        edges {
          node {
            _id
            titulo
            iconeRepresentativo
            persona {
              id
              nome
              avatar
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `,
  variables: `{
    "cursor": ${handleCursor(cursor)},
    "titulo": "${letter}"
  }`
});

export const addHistoricoUsuario = payload => ({
  query: `
    mutation(
      $idSinal: Int!,
      $emailUsuario: String!,
      $titulo: String!,
      $iconeRepresentativo: String!,
      $personaNome: String!,
      $personaAvatar: String!,
      ){
      mutationUsuarioHistorico(input: {
        idSinal: $idSinal,
        emailUsuario: $emailUsuario,
        titulo: $titulo,
        iconeRepresentativo: $iconeRepresentativo,
        personaNome: $personaNome,
        personaAvatar: $personaAvatar
      }){
        clientMutationId
      }
    }
  `,
  variables: `{
    "idSinal": ${payload.idSinal},
    "emailUsuario": "${payload.emailUsuario}",
    "titulo": "${payload.titulo}",
    "iconeRepresentativo": "${payload.iconeRepresentativo}",
    "personaNome": "${payload.personaNome}",
    "personaAvatar": "${payload.personaAvatar}"
  }`
});

export const buscarUsuarioHistorico = (emailUsuario, cursor) => ({
  query: `
    query($emailUsuario: String, $cursor: String){
      usuarioHistoricos(first: 20, after: $cursor, emailUsuario: $emailUsuario) {
        edges {
          node {
            _id
            idSinal
            emailUsuario
            titulo
            iconeRepresentativo
            personaNome
            personaAvatar
            updateAt
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `,
  variables: `{
    "emailUsuario": "${emailUsuario}",
    "cursor": ${handleCursor(cursor)}
  }`
});

export const enviarSugestaoVerbete = data => ({
  query: `
    mutation ($nome: String!, $descricao: String) {
      createSugestao(input: {nome: $nome, descricao: $descricao}) {
        sugestao {
          id
          createdAt
        }
      }
    }
  `,
  variables: `{
    "nome": "${data.nome}",
    "descricao": "${data.descricao}"
  }`
});

export const createPasta = (emailUsuario, titulo) => ({
  query: `
    mutation(
      $titulo: String!,
      $emailUsuario: String!,
      ){
      createPasta(input: {
        titulo: $titulo,
        emailUsuario: $emailUsuario,
      }){
        clientMutationId
        pasta {
          _id
          titulo
        }
      }
    }
  `,
  variables: `{
    "titulo": "${titulo}",
    "emailUsuario": "${emailUsuario}"
  }`
});

export const buscarPastasLibrary = (emailUsuario, cursor) => ({
  query: `
    query($emailUsuario: String, $cursor: String){
      pastas(first: 20, after: $cursor, usuario_username: $emailUsuario){
        totalCount
        edges{
          node{
            _id
            titulo
            shareToken
            sinais {
              totalCount
              edges {
                node {
                  _id
                }
              }
            }
          }
        }
        pageInfo{
          endCursor
          hasNextPage
        }
      }
    }
  `,
  variables: `{
    "emailUsuario": "${emailUsuario}",
    "cursor": ${cursor ? `"${cursor}"` : "null"}
  }`
});

export const editPasta = ({ id, titulo }) => ({
  query: `
    mutation ($_id: Int!, $titulo: String!) {
      updatePasta(input: {titulo: $titulo, _id: $_id}) {
        clientMutationId
        pasta {
          _id
          titulo
          sinais {
            totalCount
          }
        }
      }
    }
  `,
  variables: `{
    "_id": "${id}",
    "titulo": "${titulo}"
  }`
});

export const deletePasta = ({ id }) => ({
  query: `
    mutation ($id: ID!) {
      deletePasta(input: {id: $id}) {
        clientMutationId
      }
    }
  `,
  variables: `{
    "id": "/pastas/${id}"
  }`
});

export const addSinalPasta = ({ folderId, signalId }) => ({
  query: `
    mutation ($_id: Int!, $idSinal: Int!) {
      sinalAddPasta(input: {_id: $_id, idSinal: $idSinal}) {
        clientMutationId
      }
    }
  `,
  variables: `{
    "_id": ${folderId},
    "idSinal":  ${signalId}
  }`
});

export const buscarSinaisPasta = (idPasta, cursor) => ({
  query: `
  query($id: ID!, $cursor: String){
    pasta(id: $id){
      _id
      titulo
      shareToken
      sinais(first: 20, after: $cursor) {
        totalCount
        edges {
          node{
            _id
            titulo
            descricaoMovimento
            iconeRepresentativo
            persona {
              _id
              nome
              avatar
            }
          }
        }
        pageInfo{
          endCursor
          hasNextPage
        }
      }
    }
  }
  `,
  variables: `{
    "id": "/pastas/${idPasta}",
    "cursor": ${cursor ? `"${cursor}"` : "null"}
  }`
});

export const buscarSinaisPublicPasta = (shareToken, cursor) => ({
  query: `
    query($shareToken: String!, $cursor: String){
      publicPasta(shareToken: $shareToken){
        _id
        titulo
        shareToken
        sinais(first: 20, after: $cursor) {
          totalCount
          edges{
            node{
              _id
              titulo
              descricaoMovimento
              iconeRepresentativo
              persona {
                _id
                nome
                avatar
              }
            }
          }
          pageInfo{
            endCursor
            hasNextPage
          }
        }
      }
    }
  `,
  variables: `{
    "shareToken": "${shareToken}",
    "cursor": ${cursor ? `"${cursor}"` : "null"}
  }`
});

export const deleteSinalPasta = ({ folderId, signalId }) => ({
  query: `
    mutation ($id: Int!, $idSinal:Int!) {
      sinaisRemovePasta(input: {_id: $id, idSinal: $idSinal}) {
        clientMutationId
      }
    }
  `,
  variables: `{
    "id": ${folderId},
    "idSinal": ${signalId}
  }`
});
