import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { recoveryPassword } from "~/constants/loginFunctions";
import * as Yup from "yup";

import {
  Form,
  ButtonOutline,
  FormInput,
  ForgetPasswordButtons,
  InputBorder,
  InternalTitle,
  TextMailSent,
  Error,
} from "./style";

const ForgetPassword = ({ isMailSent, setForm }) => {
  const formRef = useRef(null);
  const { error_reset } = useSelector((state) => state.login);
  async function handleSubmit(data) {
    try {
      resetErros();

      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Insira um e-mail válido")
          .required("O e-mail é obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      recoveryPassword(data.email, setForm);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function resetErros() {
    formRef.current.setErrors({});
  }
  return (
    <>
      <InternalTitle>Recuperar senha</InternalTitle>
      {error_reset && <Error>{error_reset}</Error>}
      <Form ref={formRef} onSubmit={handleSubmit}>
        {(isMailSent && (
          <TextMailSent>
            Enviamos para o seu e-mail um link para recuperação da sua senha.
          </TextMailSent>
        )) || (
          <InputBorder>
            <FormInput
              aria-label={"Digite seu e-mail"}
              id="email"
              type="text"
              name="email"
              placeholder="E-mail"
              onFocus={resetErros}
            />
          </InputBorder>
        )}
        <ForgetPasswordButtons>
          {(isMailSent && (
            <ButtonOutline onClick={() => setForm("login")}>
              Logar
            </ButtonOutline>
          )) || (
            <>
              <ButtonOutline
                aria-label={"Cancela e volta para a tela de login"}
                inverse
                marginRight
                tabindex="0"
                onClick={() => setForm("login")}
              >
                Cancelar
              </ButtonOutline>
              <ButtonOutline
                aria-label={"Confirma e envia dados para recuperar senha"}
                id={"sendButton"}
                type="submit"
              >
                Enviar
              </ButtonOutline>
            </>
          )}
        </ForgetPasswordButtons>
      </Form>
    </>
  );
};

export default ForgetPassword;
