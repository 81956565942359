import React, { Component } from "react";

import Description from "./components/Description";
import StepByStep from "./components/StepByStep";
import Media from "./components/Media";

import { Container, Content, Fill } from "./style";

class SignalBody extends Component {
  render() {
    return (
      <>
        <Container>
          <Content>
            <Description />
            <StepByStep />
            <Media />
          </Content>
        </Container>
        <Fill />
      </>
    );
  }
}

export default SignalBody;
