import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  column-gap: 1%;
  padding: 10% 2%;
  background: ${colors.white_label};
  overflow: hidden;
  max-width: 1400px;
  margin: auto;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 20% 20px;
  }
`;

export const TitleBox = styled.div`
  margin: auto auto auto 0;
  max-width: 650px;
  @media only screen and (max-width: 1200px) {
    max-width: initial;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: ${MIN_TAB}px) {
    max-width: 100%;
  }

  p:last-child{
    margin-bottom: 0;
  }
`;

export const Title = styled.h4`
  max-width: 500px;
  font-size: 60px;
  color: ${colors.primary};
  font-family: ${fonts.VisbyRoundMedium};
  margin-bottom: 25px;
  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 45px;
  }
`;

export const SubTitle = styled.p`
  font-size: 18px;
  font-weight: normal;
  margin: 15px 10px 15px 0;
  color: ${colors.internalTitle};
  font-family: ${fonts.VisbyRoundMedium};
  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 18px;
  }
`;

export const MockupBox = styled.div`
  position: relative;
  @media only screen and (max-width: 1200px) {
    margin: auto;
  }
  iframe {
    position: absolute;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    max-width: 580px;
    height: 65%;
  }
  video {
    position: absolute;
    border-radius: 10px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 580px;
    height: 67.5%;
  }
`;

export const MockupVideo = styled.img`
  box-shadow: inset 0px 0px 20px 10px #000000, -6px 6px 10px #00000029;
  border-radius: 10px;
  width: 100%;
  max-width: 655px;
  height: auto;
`;
