import styled from "styled-components";

import { MIN_TAB, MIN_WEB } from "~/constants/config";

import { colors } from "~/styles";

import { ReactComponent as Logo } from "~/assets/svg/step_by_step.svg";

export const Container = styled.div`
  display: flex;
  flex: 1;
  /* border: 1px solid gold; */
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
  background-color: white;
  border-radius: 10px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    margin: 10px 10%;
    /* align-items: flex-start; */
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    margin: 10px 20%;
  }
`;

export const BoxHeader = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px 10px;
`;

export const BoxStep = styled.div`
  /* font-size: 13px; */
  font-family: "VisbyRoundCF-Medium";
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;
  margin: 0 10px;
  padding: 10px 20px;
  line-height: 25px;
  width: 100%;

  @media only screen and (min-width: ${MIN_TAB}px) {
    margin: 0 10px;
    margin-top: -10px;
  }
`;

export const BoxTitle = styled.h2`
  font-size: 18px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: 600;
  color: ${colors.backgroundSignal};
`;

export const LogoPalms = styled(Logo)`
  padding: 5px;
`;

export const Box = styled.div`
  border: 1px solid white;
  margin: 5px;
  padding: 5px;
`;
