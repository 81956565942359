import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import MediaQuery from "react-responsive";
import { truncate } from "lodash";

import { MIN_TAB } from "~/constants/config";
// import DropDown from "~/components/DropDown";

import Empty from "./components/Empty";

import Waiting from "~/components/Waiting";

import ModalDelete from "./components/ModalDelete";
import ModalEdit from "./components/ModalEdit";
import ModalShare from "./components/ModalShare";

import { Creators as LibraryCreators } from "~/store/ducks/library";
import { Creators as FolderCreators } from "~/store/ducks/folder";

import {
  Content,
  Center,
  FolderList,
  FolderItem,
  FolderBox,
  FolderDesc,
  FolderTitle,
  FolderIconAll,
  LoadigContainer,
  MenuMobileContainer,
  ContainerMobile,
  MobileItem,
  EmptyContent,
} from "./style";

/* eslint eqeqeq: 0 */
const ShowList = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { data, loading, error } = useSelector((state) => state.library);

  const loadMore = useCallback(() => {
    dispatch(LibraryCreators.libraryRequest());
  }, [dispatch]);

  const refresh = useCallback(() => {
    dispatch(LibraryCreators.libraryRefresh());
    dispatch(FolderCreators.folderRefresh());
    loadMore();
  }, [dispatch, loadMore]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  if (error) return null;

  if (!data)
    return (
      <Content>
        <Waiting size={"small"} />
      </Content>
    );

  const currentList = data && data.edges.length;

  if (!currentList && !loading)
    return (
      <EmptyContent>
        <Empty />
      </EmptyContent>
    );

  return (
    <Content>
      {data && data.edges && (
        <FolderList
          loadMore={loadMore}
          hasMore={data.pageInfo.hasNextPage}
          loader={
            <LoadigContainer>
              <Waiting size={"medium"} />
            </LoadigContainer>
          }
        >
          {data.edges.map((e) => (
            <Item item={e.node} key={String(e._id)} history={history} />
          ))}
        </FolderList>
      )}
    </Content>
  );
};

/**
 * Componente interno
 */
const Item = ({ item, history }) => {
  const [modal, setModal] = React.useState(null);

  function navigate() {
    history.push("pasta", { folderId: item._id });
  }

  return (
    <FolderItem>
      <ModalShare
        isOpen={modal == "share"}
        close={() => setModal(null)}
        shareToken={item.shareToken}
      />
      <ModalEdit
        isOpen={modal == "edit"}
        close={() => setModal(null)}
        title={item.titulo}
        id={item._id}
      />
      <ModalDelete
        isOpen={modal == "delete"}
        close={() => setModal(null)}
        title={item.titulo}
        id={item._id}
      />
      <FolderBox row>
        <ShowIcon
          name={"folder"}
          font={"MaterialCommunityIcons"}
          onClick={navigate}
        />
        <FolderBox onClick={navigate}>
          <FolderTitle>{truncate(item.titulo, 30)}</FolderTitle>
          <FolderDesc>
            {item.sinais.totalCount
              ? `${item.sinais.totalCount} Verbetes`
              : "Vazio"}
          </FolderDesc>
        </FolderBox>
      </FolderBox>
      <FolderBox row>
        <MenuMobile setModal={setModal} navigate={navigate} />
        <MenuWeb setModal={setModal} navigate={navigate} title={item.titulo} />
      </FolderBox>
    </FolderItem>
  );
};

/**
 * Componente interno
 */
const MenuMobile = ({ setModal, navigate }) => {
  const [open, setOpen] = React.useState(false);
  const id = Math.floor(Math.random() * 1000);

  function actionClick(action) {
    setOpen(false);
    setModal(action);
  }

  return (
    <MediaQuery maxWidth={MIN_TAB - 1}>
      <ContainerMobile>
        <label for={String(id)}>
          <ShowIcon
            name={"ellipsis-v"}
            font={"FontAwesome"}
            action={() => setOpen(!open)}
          />
        </label>
        {open && (
          <MenuMobileContainer id={String(id)}>
            <MobileItem onClick={() => navigate()}>
              <ShowIcon name={"eye"} font={"AntDesign"} />
              <div>
                <span>Visualizar</span>
              </div>
            </MobileItem>
            <MobileItem onClick={() => actionClick("edit")}>
              <ShowIcon name={"pencil"} font={"MaterialCommunityIcons"} />
              <div>
                <span>Editar</span>
              </div>
            </MobileItem>
            <MobileItem onClick={() => actionClick("delete")}>
              <ShowIcon name={"trash-can"} font={"MaterialCommunityIcons"} />
              <div>
                <span>Excluir</span>
              </div>
            </MobileItem>
            <MobileItem onClick={() => actionClick("share")}>
              <ShowIcon
                name={"share-variant"}
                font={"MaterialCommunityIcons"}
              />
              <div>
                <span>Compartilhar</span>
              </div>
            </MobileItem>
          </MenuMobileContainer>
        )}
      </ContainerMobile>
    </MediaQuery>
  );
};

/**
 * Componente interno
 */
const MenuWeb = ({ setModal, navigate, title }) => {
  return (
    <MediaQuery minWidth={MIN_TAB}>
      <ShowIcon
        name={"eye"}
        font={"AntDesign"}
        action={() => navigate()}
        label={`Visualizar ${title}`}
      />
      <ShowIcon
        name={"pencil"}
        font={"MaterialCommunityIcons"}
        action={() => setModal("edit")}
        label={`Editar ${title}`}
      />
      <ShowIcon
        name={"trash"}
        font={"Foundation"}
        action={() => setModal("delete")}
        label={`Excluir ${title}`}
      />
      <ShowIcon
        name={"share-variant"}
        font={"MaterialCommunityIcons"}
        action={() => setModal("share")}
        label={`Compartilhar ${title}`}
      />
    </MediaQuery>
  );
};

/**
 * Componente interno
 * Needs params: (name, font, action)
 */
const ShowIcon = ({ name, font, action, label }) => {
  const [hover, set] = React.useState(false);

  return (
    <Center
      onMouseEnter={() => set(true)}
      onMouseLeave={() => set(false)}
      onClick={action}
      aria-label={label}
    >
      <FolderIconAll name={name} font={font} hover={hover} />
    </Center>
  );
};

export default ShowList;
