import styled from "styled-components";

import { fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 17;
  flex-direction: column;
`;

export const TechList = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);
  /* grid-auto-rows: 100px; */
`;

export const TechBox = styled.button`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 12px;
  border-radius: 22px;
  min-height: 70px;
  width: 100%;
  background-color: ${props => props.color || "orange"};
`;

export const TechTitle = styled.span`
  color: white;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 18px;
  padding: 0 12px;
  text-align: start;
`;

export const ShowImage = styled.img.attrs(props => ({}))`
  width: 50px;
  height: 50px;
`;
