import React from "react";
import MediaQuery from "react-responsive";

import HOCModal from "~/components/HOCModal";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

import List from "./components/List";

import { Content, TitleBox, BodyBox, FooterBox } from "./styles";
import { Title, FooterButton } from "./styles";

const Modal = ({ isOpen, close, width, mobile, signalId, setModalCreate }) => {
  function handleOpenCreateModal() {
    setModalCreate(true);
    close();
  }

  return (
    <HOCModal
      isOpen={isOpen}
      close={close}
      modalHeigth={350}
      modalWidth={width}
      mobile={mobile}
    >
      <Content>
        <TitleBox>
          <Title>Salvar em...</Title>
        </TitleBox>
        <BodyBox>
          <List signalId={signalId} close={close} />
        </BodyBox>
        <FooterBox>
          <FooterButton
            aria-label={"Cancelar e voltar para tela anterior"}
            onClick={close}
            warn
          >
            Voltar
          </FooterButton>
          <FooterButton
            aria-label={"Confirma informações e cadastra nova biblioteca"}
            onClick={handleOpenCreateModal}
          >
            Criar nova biblioteca
          </FooterButton>
        </FooterBox>
      </Content>
    </HOCModal>
  );
};

const ModalEdit = (props) => {
  const { isOpen } = props;

  if (!isOpen) return null;

  return (
    <>
      <MediaQuery minWidth={MIN_WEB}>
        <Modal width={500} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_WEB} minWidth={MIN_TAB}>
        <Modal width={500} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_TAB}>
        <Modal width={320} height={270} {...props} mobile />
      </MediaQuery>
    </>
  );
};

export default ModalEdit;
