import { TechTypes as Types } from "~/constants/types";
import immer from "immer";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  list: null,
  set: null
};

export default function tech(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case Types.REQUEST:
      return { ...state, loading: true };

    case Types.SUCCESS:
      return immer(state, draft => {
        const oldList = state.data && state.data.edges;
        const newerList = payload.edges;
        const edges = oldList ? oldList.concat(newerList) : newerList;
        draft.data = { ...payload, edges };
        draft.loading = false;
      });

    case Types.FAILURE:
      return { ...state, error: true, loading: false };

    case Types.SAVE:
      return { ...state, list: payload };

    case Types.SELECTED:
      return { ...state, data: null, set: payload };
    default:
      return state;
  }
}

export const Creators = {
  technologiesLoad: payload => ({ type: Types.LOAD, payload }),
  technologiesSave: payload => ({ type: Types.SAVE, payload }),

  technologySet: payload => ({ type: Types.SELECTED, payload }),

  technologyRequest: payload => ({ type: Types.REQUEST, payload }),
  technologySuccess: payload => ({ type: Types.SUCCESS, payload }),
  technologyFailure: payload => ({ type: Types.FAILURE, payload })
};
