import React from "react";
import MediaQuery from "react-responsive";

// isOpen, close, modalWidth, modalHeigth
// import HOCModal from "~/components/HOCModal";
import { MIN_TAB, MIN_WEB } from "~/constants/config";
import ModalShare from "~/components/ModalShare";

// import { Content, TitleBox, BodyBox, FooterBox } from "./style";
// import { Title, Body, FooterButton } from "./style";

const Modal = ({ isOpen, close, shareToken, ...rest }) => {
  const url = `${window.location.href.replace(
    "library",
    "public-folder"
  )}/${shareToken}`;

  return (
    <ModalShare
      show={isOpen}
      close={close}
      width={650}
      height={300}
      url={url}
      {...rest}
    />
  );
};

const ModalShareFolder = props => {
  const { isOpen } = props;

  if (!isOpen) return null;

  return (
    <>
      <MediaQuery minWidth={MIN_WEB}>
        <Modal width={650} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_WEB} minWidth={MIN_TAB}>
        <Modal width={500} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_TAB}>
        <Modal width={320} height={270} {...props} mobile />
      </MediaQuery>
    </>
  );

  // return (
  //   <ModalShare show={isOpen} close={close} width={650} height={300} url={""} />
  // );
};

export default ModalShareFolder;
