import { call, put, select, delay } from "redux-saga/effects";

import { Creators as LetterCreators } from "~/store/ducks/letter";
import { Creators as InfoCreators } from "~/store/ducks/info";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

export function* letterLoading({ payload }) {
  try {
    // yield put(LetterCreators.letterSet(payload));
    yield delay(20);

    yield put(InfoCreators.setMode("letter"));
    yield put(LetterCreators.letterRequest());
  } catch (e) {
    yield put(LetterCreators.letterFailure());
  }
}

export function* letterRequest({ payload }) {
  try {
    const { cursor, letter } = yield select(state => ({
      letter: state.letter.letter,
      cursor: state.letter.data && state.letter.data.pageInfo.endCursor
    }));

    const { data, error } = yield call(graphql, query.letter(cursor, letter));

    if (error) throw new Error("Erro durante o request");

    yield put(LetterCreators.letterSuccess(data.data.sinals));
  } catch (e) {
    yield put(LetterCreators.letterFailure());
  }
}
