import { SugestaoTypes as Types } from "~/constants/types";

const INITIAL_STATE = {
  data: null,
  loading: false
};

export default function sugestao(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.MUTATION:
      return { ...state, data: action.payload, loading: true };
    case Types.SUCCESS:
      return {
        ...state,
        data: { ...action.payload, success: true },
        loading: false
      };
    case Types.ERROR:
      return {
        ...state,
        data: { ...action.payload, error: true },
        loading: false
      };
    case Types.INIT:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export const Creators = {
  sugestaoMutation: payload => ({
    type: Types.MUTATION,
    payload
  }),
  sugestaoSuccess: payload => ({
    type: Types.SUCCESS,
    payload
  }),
  sugestaoError: payload => ({
    type: Types.ERROR,
    payload
  }),
  sugestaoInit: () => ({
    type: Types.INIT
  })
};
