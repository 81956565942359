import React from "react";

import Container from "~/components/Container";
import AppHeader from "~/components/AppHeader";
import Error404 from "~/components/Error404";

const Error404Geral = props => {
  return (
    <Container>
      <AppHeader back={true} />
      <Error404 />
    </Container>
  );
};

export default Error404Geral;
