import styled from "styled-components";

import { MIN_TAB, MIN_CEL } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2%;
  padding: 10% 0;
  max-width: 1800px;
  margin: auto;

  @media only screen and (max-width: ${MIN_TAB}px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1.4fr;
    grid-template-areas:
      "mockupBox"
      "titleBox";
    padding: 10% 0 20%;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 5% 0 0 2%;
  @media only screen and (max-width: ${MIN_TAB}px) {
    margin: 5% 0;
    grid-area: titleBox;
  }
`;

export const Title = styled.h1`
  font-size: 90px;
  font-weight: normal;
  color: ${colors.primary};
  font-family: ${fonts.VisbyRoundMedium};
  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 55px;
  }
`;

export const SubTitle = styled.h2`
  font-size: 45px;
  font-weight: normal;
  text-align: initial;
  margin: 20px auto 40px;
  max-width: 515px;
  font-weight: bold;
  color: ${colors.primary};
  font-family: ${fonts.VisbyRoundMedium};
  @media only screen and (max-width: ${MIN_CEL}px) {
    text-align: center;
  }
`;

export const LogoWrapper = styled.div`
  width: min-content;
  justify-content: left;
  align-items: left;
  @media only screen and (max-width: ${MIN_CEL}px) {
    width: auto;
  }
`;
export const Logo = styled.img`
  width: min-content;
`;

export const Experimente = styled.p`
  color: ${colors.primary};
  font-size: 18px;
  text-align: initial;
  @media only screen and (max-width: ${MIN_CEL}px) {
   margin: 15px;
  }
`;

export const DisponivelPara = styled.p`
  color: ${colors.primary};
  font-size: 16px;
  text-align: initial;
  margin-top: 10px;
  margin-bottom: 10px;
  @media only screen and (max-width: ${MIN_CEL}px) {
    text-align: center;
  }
`;

export const DownloadBox = styled.div`
  display: flex;
  justify-content: left;
  column-gap: 25px;
  @media only screen and (max-width: ${MIN_TAB}px) {
    justify-content: start;
    column-gap: 15px;
  }
  @media only screen and (max-width: ${MIN_CEL}px) {
      justify-content: center;
  }
`;

export const DownloadLink = styled.a``;

export const DownloadImg = styled.img`
  height: 50px;
  @media only screen and (max-width: ${MIN_TAB}px) {
    height: 35px;
  }
`;

export const MockupBox = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-top: 10%;
  div {
    height: -webkit-fill-available;
    width: -webkit-fill-available;
    position: absolute;
    width: auto;
    &:nth-child(2) {
      height: auto;
    }
  }

  @media only screen and (max-width: ${MIN_TAB}px) {
    grid-area: mockupBox;
    margin: 0 0 0 5%;
  }
`;

export const MockupCelular = styled.img`
  width: auto;
  @media only screen and (max-width: ${MIN_TAB}px) {
    height: 280px;
  }
`;

export const MockupNotebook = styled.img`
  position: absolute;
  height: -webkit-fill-available;
`;
