import React from "react";

import IOSStep1 from "~/assets/image/audioDescription/ios-step1.png";
import IOSStep2 from "~/assets/image/audioDescription/ios-step2.png";
import IOSStep3 from "~/assets/image/audioDescription/ios-step3.png";
import IOSStep4 from "~/assets/image/audioDescription/ios-step4.png";

import { Content, StepsBox, StepContainer } from "./styles";

const ChromeSteps = () => (
  <Content>
    <span tabIndex={5}>
      Siga os passos abaixo para ativar em seu IPhone.
      <b>
        Também é possível ativar o VoiceOver com a Siri, a assistente da Apple.
        Depois de chamar a Siri, basta dizer “Ativar o VoiceOver” ou “Desativar
        o VoiceOver”
      </b>
    </span>

    <StepsBox>
      <StepContainer tabIndex={6}>
        <img src={IOSStep1} alt="Passo 1 ativação VoiceOver" />
        <span>
          Em seu smartphone, acesse a opção de ajustes e acesse as opções de
          “Acessibilidade”
        </span>
      </StepContainer>
      <StepContainer tabIndex={7}>
        <img src={IOSStep2} alt="Passo 2 ativação VoiceOver" />
        <span>Vá em VoiceOver</span>
      </StepContainer>
      <StepContainer tabIndex={8}>
        <img src={IOSStep3} alt="Passo 3 ativação VoiceOver" />
        <span>Habilite o VoiceOver</span>
      </StepContainer>
      <StepContainer tabIndex={9}>
        <img src={IOSStep4} alt="Opção pela Siri de configuração VoiceOver" />
        <span>
          Se preferir ative o VoiceOver via comando de voz chamando a Siri e
          depois dizendo “Ativar VoiceOver”
        </span>
      </StepContainer>
    </StepsBox>
  </Content>
);

export default ChromeSteps;
