import styled from "styled-components";

import { MIN_TAB, MIN_WEB } from "~/constants/config";

export const Content = styled.div`
  background-color: white;

  @media only screen and (max-width: ${MIN_TAB}px) {
  }
  @media only screen and (max-width: ${MIN_WEB}px) {
  }
`;
