import React, { Component } from "react";

import Areas from "./components/Areas";

import sebastiaoNoCelular from "~/assets/image/landingPage/sebastiaoNoCelular.png";

import { Content, TitleBox, Title, TitleImg, AreasBox } from "./style";

class ExploreAreas extends Component {
  render() {
    return (
      <Content>
        <TitleBox>
          <div data-aos="fade-right" data-aos-offset="250">
            <TitleImg src={sebastiaoNoCelular} />
          </div>
          <div data-aos="fade-left" data-aos-offset="200">
            <Title>Explore nossas áreas tecnológicas</Title>
          </div>
        </TitleBox>

        <div data-aos="fade-up" data-aos-offset="200">
          <AreasBox>
            <Areas />
          </AreasBox>
        </div>
      </Content>
    );
  }
}

export default ExploreAreas;
