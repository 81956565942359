import styled from "styled-components";
import { fonts } from "~/styles";

export const LoginBody = styled.div`
  margin: auto;
  max-width: 444px;
  width: 100%;
  padding: 10px;
  min-height: 465px;
  font-family: ${fonts.VisbyRoundMedium};
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;

  color: rgb(255, 0, 0);
  font-family: ${fonts.VisbyRoundLight};
  font-size: 13px;
`;
