import React from "react";
import { Provider } from "react-redux";
// import WebFont from "webfontloader";
// import WebfontLoader from "@dr-kobros/react-webfont-loader";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer, toast } from "react-toastify";

import "./config/reactotron";
import { store, persistor } from "./store";
import Routes from "./routes";
import GlobalStyle from "./styles/global";

import "react-web-vector-icons/fonts";
import "./assets/fonts/VisbyRound";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GlobalStyle />
        <Routes />
        <ToastContainer
          position={toast.POSITION.BOTTOM_RIGHT}
          autoClose={4000}
        />
      </PersistGate>
    </Provider>
  );
}

export default App;
