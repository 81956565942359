import Img from "react-image";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { MIN_TAB, CDN, MIN_WEB } from "~/constants/config";
import noImage from "~/assets/image/icon-hand.png";
import { colors } from "~/styles";

export const Container = styled(Link)`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #2a353e33;
  border-radius: 10px;
  padding: 5px;

  :hover {
    background-color: #fbfafd;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImageVerbete = styled(Img).attrs(props => ({
  src: `${CDN}${props.image}`,
  onError: e => (e.target.src = noImage),
  alt: `Imagem Representativa do Verbete ${props.title}`
}))`
  width: ${props => (props.row ? "40" : "70")}px;
  object-fit: contain;
  padding: 10px 0;

  @media only screen and (min-width: ${MIN_TAB}px) {
    width: ${props => (props.row ? "50" : "90")}px;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    width: ${props => (props.row ? "50" : "120")}px;
  }
`;

export const Title = styled.span`
  font-size: 13px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: 600;
  color: ${colors.itemCardName};
  margin: 5px 0;

  text-align: ${props => (props.row ? "flex-start" : "center")};
  width: ${props => (props.row ? "125px" : "auto")};
  padding: 0 ${props => (props.row ? 15 : 0)}px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    font-size: 16px;
    width: ${props => (props.row ? "250px" : "auto")};
    padding: 0 ${props => (props.row ? 25 : 0)}px;
  }
`;

export const AvatarRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
`;

// const teste =
//   "https://homolog-api-senai-libras.senai.br/media/5d/7f/a5/5d7fa52e76ff6400883564.png";
export const ImageAvatar = styled(Img).attrs(props => ({
  src: `${CDN}${props.image}`,
  onError: e => (e.target.src = noImage),
  alt: `Personagem ${props.persona}`
}))`
  width: 22px;
  height: auto;
  border-radius: 11px;
  object-fit: contain;
`;

export const AvatarName = styled.span`
  font-size: 13px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: 600;
  color: ${colors.itemCardName};
  padding: 0px 10px;
  text-align: center;
  width: 100%;
`;

export const ContentRow = styled.ul`
  display: flex;
  height: 60px;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid #eaeaea;

  :hover {
    background-color: #fbfafd;
  }
`;

export const Row = styled(Link)`
  display: flex;
  flex: 1;
  align-items: center;
`;
