import React from "react";

import AppHeader from "~/components/AppHeader";
import Search from "./components/Search";

import { Content } from "./style";

const DashboardHeader = props => {
  return (
    <Content>
      <AppHeader />
      <Search />
    </Content>
  );
};

export default DashboardHeader;
