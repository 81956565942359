import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";
import BackgroundOpinion from "~/assets/image/landingPage/BackgroundOpinion.png";

import { colors, fonts } from "~/styles";

export const Content = styled.div`
  padding: 10% 0;
  background: ${colors.white_label};
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 20% 0 30%;
  }
`;

export const TitleBox = styled.div`
  text-align: center;
  max-width: 700px;
  margin: auto;
`;

export const Title = styled.h3`
  font-size: 50px;
  font-weight: bold;
  color: ${colors.itemCardName};
  font-family: ${fonts.VisbyRoundMedium};
`;

export const OpinionsBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 5% auto 0;
  position: relative;

  .BrainhubCarousel__arrowLeft,
  :focus {
    height: inherit;
    border-radius: 0 10px 10px 0;
    box-shadow: none;
    background-color: ${colors.backgroundLightBlue};
    :hover {
      background: transparent
        linear-gradient(90deg, #ffce00 0%, #ffce00 35%, #ffce00b3 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: none;
    }
  }
  .BrainhubCarousel__arrowRight,
  :focus {
    height: inherit;
    border-radius: 10px 0 0 10px;
    box-shadow: none;
    background-color: ${colors.backgroundLightBlue};
    :hover {
      background: transparent
        linear-gradient(270deg, #ffce00 0%, #ffce00 35%, #ffce00b3 100%) 0% 0%
        no-repeat padding-box;
      box-shadow: none;
    }
  }
  .BrainhubCarousel__container {
    @media only screen and (max-width: 1400px) {
      width: 90%;
    }
  }
  .BrainhubCarouselItem--active {
    div {
      max-width: max-content;
      font-size: 18px;
      padding: 25px;
      img {
        max-width: 60px;
      }
      p:nth-child(3) {
        margin: 15px 0 4px;
      }

      @media only screen and (max-width: ${MIN_TAB}px) {
        max-width: 88%;
      }
    }
  }
`;

export const OpinionsBackground = styled.div`
  background: url(${BackgroundOpinion}),
    transparent linear-gradient(180deg, #ffce00 0%, #ffce00 35%, #ffce00b3 100%)
      0% 0% no-repeat padding-box;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  position: absolute;
  width: 70%;
  max-width: 850px;
  height: -webkit-fill-available;

  @media only screen and (max-width: 1400px) {
    width: 96%;
    max-width: inherit;
  }
`;
