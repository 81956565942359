import { LetterTypes as Types } from "~/constants/types";
import immer from "immer";

let letters = [];

for (let i = 65; i < 65 + 26; i++) {
  letters.push(String.fromCharCode(i));
}

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  letter: null,
  letters: letters
};

export default function letter(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case Types.SET:
      return { ...state, letter: payload };

    case Types.LOADING:
      return { ...state, data: null, letter: payload };

    case Types.REQUEST:
      return { ...state, loading: true };

    case Types.SUCCESS:
      return immer(state, draft => {
        const oldList = state.data && state.data.edges;
        const newerList = payload.edges;
        const edges = oldList ? oldList.concat(newerList) : newerList;
        draft.data = { ...payload, edges };
        draft.loading = false;
      });

    case Types.FAILURE:
      return { ...state, error: true, loading: false };

    default:
      return state;
  }
}

export const Creators = {
  letterSet: payload => ({ type: Types.SET, payload }),
  letterLoading: payload => ({ type: Types.LOADING, payload }),
  letterRequest: payload => ({ type: Types.REQUEST, payload }),
  letterSuccess: payload => ({ type: Types.SUCCESS, payload }),
  letterFailure: payload => ({ type: Types.FAILURE, payload })
};
