import React from "react";

import { ButtonPlatform } from "./styles";

const Button = ({ image, alt, index, selected, onClick }) => (
  <ButtonPlatform selected={selected} onClick={onClick}>
    <img src={image} alt={alt} tabIndex={index} />
  </ButtonPlatform>
);

export default Button;
