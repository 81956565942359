import Img from "react-image";
import styled from "styled-components";
import { darken } from "polished";
import { FontAwesome, MaterialIcons } from "react-web-vector-icons";

import { fonts } from "~/styles";

import hand from "~/assets/image/icon-hand.png";
import { MIN_TAB, MIN_WEB, CDN } from "~/constants/config";

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 5px 10px;
  border-radius: 10px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    margin: 10px 10%;
    flex-direction: row;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    margin: 10px 20%;
  }
`;

export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin: 5px;
  padding: 5px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    align-items: flex-start;
    margin: 0 10px;
  }
`;

export const BoxMobile = styled.button`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
`;

export const BoxImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px;
  border-radius: 15px;
`;

export const Image = styled(Img).attrs(props => ({
  src: [`${CDN}${props.image}`, hand]
}))`
  border-radius: 5px;
  width: 50px;
  height: auto;

  @media only screen and (min-width: ${MIN_TAB}px) {
    width: 120px;
    height: auto;
  }
`;

export const Icons = styled(FontAwesome).attrs(props => ({
  name: props.collapsed ? "chevron-up" : "chevron-down",
  size: 15,
  color: "white"
}))``;

export const BoxTitle = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`;

export const Title = styled.h1`
  margin: 0 10px;
  font-size: 20px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: 600;
  letter-spacing: 1px;
  color: white;
`;

export const BoxDescription = styled.div`
  /* font-size: 13px; */
  font-family: "VisbyRoundCF-Medium";
  font-weight: 400;
  margin: 5px 10px;
  text-align: justify;
  text-justify: inter-word;
  color: white;
  line-height: 25px;
`;

export const TitleFavorite = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  width: 100%;
`;

export const FavoriteMobileDiv = styled.div`
  margin-left: 20px;
`;

export const FavoriteA = styled.a``;

export const IFrameRedirect = styled.iframe`
  display: none;
`;

export const Center = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  color: #ffce00;

  &:hover {
    color: ${darken(0.1, "#ffce00")};
  }
`;

export const IconAdd = styled(MaterialIcons).attrs(props => ({
  name: "playlist-add",
  size: 26,
  color: props.hover ? darken(0.1, "#ffce00") : "#ffce00"
}))`
  padding: 5px;
`;

export const TextAdd = styled.span`
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 18px;
  font-weight: bold;
  margin-left: 10px;
`;
