import React from "react";
import ReactLoading from "react-loading";

import { Container } from "./style";

const Waiting = ({ type = "spinningBubbles", color = "blue" }) => (
  <Container>
    <ReactLoading type={type} color={color} height={"5%"} width={"5%"} />
  </Container>
);

export default Waiting;
