import React, { Component } from "react";

import { Content, TitleRow, Title, CloseButton, CloseIcon } from "./style";

class Header extends Component {
  render() {
    const { close } = this.props;

    return (
      <Content>
        <TitleRow>
          <Title>Áreas Tecnologicas</Title>
          <CloseButton aria-label={"Fechar Filtro"} onClick={close}>
            <CloseIcon />
          </CloseButton>
        </TitleRow>
      </Content>
    );
  }
}

export default Header;
