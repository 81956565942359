import styled from "styled-components";
import ReactPlayer from "react-player";

import { MIN_TAB, CDN } from "~/constants/config";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: black;
`;

export const Video = styled(ReactPlayer).attrs(props => ({
  url: `${CDN}${props.url}`
}))``;

export const Speed = styled.div`
  border: 1.3px solid #ddd;
  font-size: 14px;
  padding: 5px;
  position: absolute;
  bottom: ${props => (props.mobile ? "22%" : "9.6%")};
  left: ${props => (props.mobile ? "3%" : "22%")};
  color: white;
  cursor: pointer;
  display: block;
  width: 93px;
  text-align: center;
  border-radius: 4px;

  &:hover {
    background: white;
    color: black;
    border: 2.5px solid white;
  }
`;

export const SpeedBox = styled.ul`
  font-size: 14px;
  list-style: none;
  position: absolute;
  bottom: ${props => (props.mobile ? "31%" : "19%")};
  left: ${props => (props.mobile ? "3%" : "22%")};
  width: 90px;
  text-align: center;
  border-radius: 2px;
  display: ${props => (props.showSpeedBox ? "block" : "none")};

  & li {
    color: white;
    border: 1px solid #fff;
    padding: 6px;
    border-radius: 2px;
    background: black;
    width: 92px;
    border-radius: 4px;
    transition: 0.1s;
    margin-top: 5px;
  }

  & :hover {
    cursor: pointer;
    background: white;
    color: black;
  }
`;

export const SampleVideo = styled.img`
  width: 100%;
  height: auto;
  @media only screen and (min-width: ${MIN_TAB}px) {
    width: 300px;
  }
`;

