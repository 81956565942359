import styled from "styled-components";
import { fonts, colors } from "~/styles";
import { Link } from "react-router-dom";
import { FontAwesome } from "react-web-vector-icons";
import { MIN_CEL } from "~/constants/config";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: center;
`;

export const Username = styled.div`
  color: white;
`;

export const ButtonLogin = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.primary};
  background: ${colors.white_label};
  padding: 0 10px;
  min-width: 70px;
  height: 40px;
  border: 1px solid ${colors.white_label};
  font-weight: ${fonts.VisbyRoundLight};
  border-radius: 5px;
  margin-right: 15px;
  @media only screen and (max-width: ${MIN_CEL}px) {
    margin-right: 5px;
  }
`;

export const IconsSingIn = styled(FontAwesome).attrs((props) => ({
  name: "sign-in",
  size: 20,
  color: "#4B78F",
}))``;
