import React from "react";
import { connect } from "react-redux";

// import AppModalShare from "~/components/AppModalShare";

import ItemVideo from "../ItemVideo";
import ItemGallery from "../ItemGallery";
import ButtonShare from "../ButtonShare";

import {
  Container,
  Content,
  Icons,
  BoxTitle,
  Box,
  Title,
  BoxMedia
} from "./style";

const Media = ({ signal }) => {
  if (!signal || Number.isInteger(signal)) return null;

  const { isVideo } = signal;

  const Main = props => {
    if (isVideo) return <ItemVideo signal={signal} />;
    return <ItemGallery signal={signal} />;
  };

  return (
    <Container>
      <Content>
        <Box>
          <BoxTitle>
            <Icons />
            <Title>Demonstração</Title>
          </BoxTitle>
          <BoxMedia>
            <Main />
          </BoxMedia>
        </Box>
      </Content>
      <ButtonShare />
    </Container>
  );
};

const mapStateToProps = state => ({
  signal: state.signal.data
});

export default connect(mapStateToProps)(Media);
