import styled from "styled-components";
import { fonts, colors } from "~/styles";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

export const Content = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ${fonts.VisbyRoundMedium};
  background-color: ${colors.listBackground};

  @media only screen and (min-width: ${MIN_TAB}px) {
    padding: 0 calc(10% + 10px);
  }
  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 0 10px;
  }

  div {
    max-width: 1000px;
    div {
      h1 {
        margin-top: 30px;
        color: #34495e;
        font-size: 30px;
      }
      p {
        margin-top: 5px;
        color: #34495e;
        font-size: 15px;
        font-weight: 300;
      }
    }
  }
  ul li img {
    width: 100%;
  }
`;
