import { colors } from "~/styles";

export const customStyles = ({ modalWidth, modalHeigth }) => ({
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: modalWidth || "70%",
    height: modalHeigth || "70%",
    borderRadius: 10,
    border: "none",
  },
  overlay: {
    zIndex: "200",
    backgroundColor: colors.darkTransparent,
  },
});
