import Img from "react-image";
import styled from "styled-components";
import { Link } from "react-router-dom";
// import styled from 'styled-components/native';

import { MIN_TAB, CDN } from "~/constants/config";
import { colors } from "~/styles";

export const Container = styled(Link)`
  display: flex;
  flex: 1;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #2a353e33;
  border-radius: 10px;
  opacity: 1;
`;

export const ContainerList = styled(Link)`
  display: flex;
  flex: 1;
  text-decoration: none;
  align-items: center;
  justify-content: flex-start;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #2a353e33;
  border-radius: 10px;
  opacity: 1;
  padding: 10px 25px;
  margin-bottom: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
`;

export const Image = styled.img`
  width: 70px;
  height: auto;
  object-fit: contain;
  margin: 10px 0;

  @media only screen and (min-width: ${MIN_TAB}px) {
    width: 120px;
  }
`;

export const Title = styled.span`
  font-size: 13px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: 600;
  color: ${colors.itemCardName};
  margin: 5px 0;
  text-align: center;
  /* width: 100%; */
  /* border: 1px solid red; */

  @media only screen and (min-width: ${MIN_TAB}px) {
    font-size: 16px;
  }
`;

export const Avatar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
`;

// const teste =
//   "https://homolog-api-senai-libras.senai.br/media/5d/7f/a5/5d7fa52e76ff6400883564.png";
export const AvatarImg = styled(Img).attrs(props => ({
  src: `${CDN}${props.image}`
  // src: teste
}))`
  width: 22px;
  height: auto;
  border-radius: 11px;
  object-fit: contain;
`;

export const AvatarImgList = styled(Img).attrs(props => ({
  src: `${CDN}${props.image}`
  // src: teste
}))`
  width: 22px;
  height: auto;
  border-radius: 11px;
  object-fit: contain;
  margin-right: 8px;
`;

export const AvatarName = styled.span`
  font-size: 13px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: 600;
  color: ${colors.itemCardName};
  padding: 0px 10px;
  text-align: center;
  width: 100%;

  /* @media only screen and (min-width: ${MIN_TAB}px) {
    font-size: 16px;
  } */
`;

export const TitleFavorite = styled.div`
  display: flex;
  align-items: center;
`
