import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import MediaQuery from "react-responsive";
import * as Yup from "yup";

import { Creators as LibraryCreators } from "~/store/ducks/library";

import HOCModal from "~/components/HOCModal";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

import { Content, TitleBox, BodyBox, FooterBox } from "./style";
import { Title, FormContainer, FormInput, FooterButton } from "./style";

const Modal = ({ isOpen, close, width, height, mobile }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    try {
      resetErros();

      const schema = Yup.object().shape({
        name: Yup.string().required("O nome é obrigatório"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      dispatch(LibraryCreators.libraryCreating({ titulo: data.name }));
      close();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function resetErros() {
    formRef.current.setErrors({});
  }
  return (
    <HOCModal
      isOpen={isOpen}
      close={close}
      modalHeigth={height}
      modalWidth={width}
      mobile={mobile}
    >
      <FormContainer ref={formRef} onSubmit={handleSubmit}>
        <Content>
          <TitleBox>
            <Title>Criar Nova biblioteca</Title>
          </TitleBox>
          <BodyBox>
            <FormInput
              aria-label={"Insirir o nome da nova biblioteca"}
              name="name"
              type="text"
              placeholder="Nome"
              onFocus={resetErros}
            />
          </BodyBox>
          <FooterBox>
            <FooterButton
              aria-label={"Cancelar e voltar para tela anterior"}
              onClick={close}
              warn
            >
              Voltar
            </FooterButton>
            <FooterButton
              aria-label={"Confirma informações e cadastra nova biblioteca"}
              type="submit"
            >
              Criar
            </FooterButton>
          </FooterBox>
        </Content>
      </FormContainer>
    </HOCModal>
  );
};

const ModalNewFolder = (props) => {
  const { isOpen } = props;

  if (!isOpen) return null;

  return (
    <>
      <MediaQuery minWidth={MIN_WEB}>
        <Modal width={650} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_WEB} minWidth={MIN_TAB}>
        <Modal width={500} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_TAB}>
        <Modal width={320} height={270} {...props} mobile />
      </MediaQuery>
    </>
  );
};

export default ModalNewFolder;
