import styled from "styled-components";

export const Container = styled.div`
  margin: 80px;
`;

export const TitleDiv = styled.div`
  margin: 15px;
`;

export const Title = styled.h1`
  font-size: 16px;
  font-weight: normal;
`;

export const SubTitle = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;

  margin-bottom: 60px;
`;

export const Paragraph = styled.p`
  display: flex;
  font-size: 15px;
  margin-bottom: 60px;
  line-height: 1.6;
`;

export const Topic = styled.p`
  display: flex;
  font-size: 14px;
  margin-bottom: 60px;
  font-weight: bold;
`;

export const ListParagraph = styled.p`
  display: flex;
  font-size: 15px;
  margin-bottom: 10px;
`;

export const List = styled.p`
  font-size: 15px;
  margin-left: 20px;
  margin-bottom: 40px;
  line-height: 1.8;
`;

export const Logo = styled.img`
  display: flex;
  justify-self: center;
`;

export const Image = styled.div`
  display: flex;
  justify-content: center;
`;
