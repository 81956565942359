import { LoginTypes as Types } from "~/constants/types";
import immer from "immer";

const INITIAL_STATE = {
  profile: {},
  auth: false,
  loading: false,
  error: null,
  error_reset: null,
  confirm_email: null
};

export default function login(state = INITIAL_STATE, { type, payload }) {
  return immer(state, draft => {
    switch (type) {
      case Types.REQUEST: {
        draft.loading = true;
        draft.error = null;
        break;
      }
      case Types.SUCCESS: {
        draft.loading = false;
        draft.confirm_email = null;
        draft.profile = payload;
        draft.auth = true;
        break;
      }
      case Types.FAILURE: {
        draft.loading = false;
        draft.error = payload;
        break;
      }
      case Types.RESET_REQUEST: {
        draft.loading = true;
        draft.error_reset = false;
        break;
      }
      case Types.RESET_SUCCESS: {
        draft.loading = false;
        draft.confirm_email = null;
        break;
      }
      case Types.RESET_FAILURE: {
        draft.loading = false;
        draft.error_reset = payload;
        break;
      }
      case Types.CONFIRM_EMAIL: {
        draft.loading = false;
        draft.confirm_email = payload;
        break;
      }
      case Types.DESTROY_SESSION: {
        draft.error = null;
        draft.error_reset = null;
        draft.loading = false;
        draft.profile = {};
        draft.auth = false;
        break;
      }
      case Types.ERROR_CLEAR: {
        draft.error = null;
        draft.error_reset = null;
        draft.confirm_email = null;
        draft.loading = false;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  loginRequest: payload => ({
    type: Types.REQUEST,
    payload
  }),

  loginSuccess: payload => ({
    type: Types.SUCCESS,
    payload
  }),

  loginFailure: payload => ({
    type: Types.FAILURE,
    payload
  }),

  resetPassRequest: payload => ({
    type: Types.RESET_REQUEST,
    payload
  }),

  resetPassSuccess: payload => ({
    type: Types.RESET_SUCCESS,
    payload
  }),

  resetPassFailure: payload => ({
    type: Types.RESET_FAILURE,
    payload
  }),

  emailConfirm: payload => ({
    type: Types.CONFIRM_EMAIL,
    payload
  }),

  loginErrorClear: payload => ({
    type: Types.ERROR_CLEAR,
    payload
  }),

  loginDESTROY: () => ({ type: Types.DESTROY_SESSION })
};
