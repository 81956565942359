import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, TabButton, Error } from "./style";
import { Creators as LoginCreators } from "~/store/ducks/login";

const Selector = ({ isLogin, isRegister, setForm }) => {
  const { error } = useSelector((state) => state.login);
  const dispatch = useDispatch();

  return (
    <>
      <Tabs>
        <TabButton
          aria-label={"Seleciona e ativa formulário de login"}
          onClick={() => {
            setForm("login");
            dispatch(LoginCreators.loginFailure());
          }}
          active={isLogin}
        >
          Login
        </TabButton>
        <TabButton
          aria-label={"Seleciona e ativa formulário de cadastro"}
          onClick={() => {
            setForm("register");
            dispatch(LoginCreators.loginFailure());
          }}
          active={isRegister}
        >
          Cadastre-se
        </TabButton>
      </Tabs>
      {error && <Error>{error}</Error>}
    </>
  );
};

export default Selector;
