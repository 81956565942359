import React from "react";
import { useSelector } from "react-redux";

import { Content, Title, Info } from "./style";

/* eslint eqeqeq: 0 */
const Header = props => {
  const { data } = useSelector(state => state.folder);

  const title = data ? data.titulo : "";
  const total = data ? data.sinais.totalCount : 0;
  const current = data ? data.sinais.edges.length : 0;

  return (
    <Content>
      <Title>{title}</Title>
      <Info>{`Exibindo ${current} de ${total}`}</Info>
    </Content>
  );
};

export default Header;
