import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import Dashboard from "~/pages/Dashboard";
import LandingPage from "~/pages/LandingPage";
import Signal from "~/pages/Signal";
import Login from "~/pages/Login";
import PrivacyPolicies from "~/pages/PrivacyPolicies";
import History from "~/pages/History";
import Library from "~/pages/Library";
import Folder from "~/pages/Folder";
import PublicFolder from "~/pages/Folder/public";
import AudioDescription from "~/pages/AudioDescription";
import Error404Geral from "~/components/Error404Geral";
import Sugestion from "~/pages/Sugestion";

const Routes = () => {
  const { auth } = useSelector((state) => state.login);
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route
          exact
          path="/politica-de-privacidade"
          component={PrivacyPolicies}
        />
        <Route path="/verbete/:id/" component={Signal} />
        <Redirect from="/redirect/:id/" to="/verbete/:id/" />
        <Route path="/login">{!auth ? <Login /> : <Redirect to="/" />}</Route>
        <Route path="/change-password/:token/" component={Login} />
        <Route path="/confirm-email/:token/" component={Login} />
        <Route path="/audiodescricao" component={AudioDescription} />
        <Route path="/historico">{auth ? <History /> : <Redirect to="login" />}</Route>
        <Route path="/sugerir-verbete">{auth ? <Sugestion /> : <Redirect to="login" />}</Route>
        <Route path="/biblioteca">{auth ? <Library /> : <Redirect to="login" />}</Route>
        <Route path="/pasta">{auth ? <Folder /> : <Redirect to="login" />}</Route>
        <Route path="/public-folder/:shareToken" component={PublicFolder} />
        <Route path="/*" component={Error404Geral} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
