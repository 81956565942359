import React from "react";
import { ModalResult } from "../";

const ModalError = ({ onClose }) => (
  <ModalResult
    title="Erro ao enviar sugestão!"
    text="Tente novamente mais tarde."
    color="#FF4400"
    icon="times"
    onClose={onClose}
  />
);

export default ModalError;
