import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as FolderCreators } from "~/store/ducks/folder";

import Item from "./components/Item";
import Empty from "./components/Empty";

import Waiting from "~/components/Waiting";

import { Content, List, LoadigContainer } from "./style";

const ShowList = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(state => state.folder);

  const folderId = data && data._id;

  const loadMore = useCallback(() => {
    dispatch(FolderCreators.folderRequest({ folderId }));
  }, [dispatch, folderId]);

  if (error) return <div>Algo inesperado aconteceu =(</div>;

  if (!data)
    return (
      <Content>
        <Waiting size={"small"} />
      </Content>
    );

  const currentList = data && data.sinais.edges.length;

  if (!currentList && !loading)
    return (
      <Content>
        <Empty />
      </Content>
    );

  return (
    <>
      {!loading && (
        <Content>
          <List
            loadMore={loadMore}
            hasMore={data.sinais.pageInfo.hasNextPage}
            loader={
              <LoadigContainer>
                <Waiting size={"medium"} />
              </LoadigContainer>
            }
          >
            {data.sinais.edges.map(item => (
              <Item
                item={item.node}
                key={`${item.node._id}`}
                folderId={data._id}
              />
            ))}
          </List>
        </Content>
      )}
    </>
  );
};

export default ShowList;
