import { call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import { Creators as LibraryCreators } from "~/store/ducks/library";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

export function* folderCreate({ payload }) {
  try {
    const { profile } = yield select(state => ({
      profile: state.login.profile
    }));

    const response = yield call(
      graphql,
      query.createPasta(profile.email, payload.titulo)
    );

    const { data, error } = response;

    if (error) {
      throw new { error: "@library/graphql_create_error" }();
    }

    if (data.errors) {
      const { message } = data.errors[0];
      throw new Error(message);
    }

    if (payload.signalId) {
      yield folderAddSinal({
        payload: {
          signalId: payload.signalId,
          folderId: data.data.createPasta.pasta._id,
          folderTitle: data.data.createPasta.pasta.titulo
        }
      });
    } else {
      toast.success("Pasta criada com sucesso.");
      yield put(LibraryCreators.libraryRequest());
    }
  } catch (error) {
    toast.error(error.message);
    yield put(LibraryCreators.libraryFailure());
  }
}

export function* libraryRequest() {
  try {
    const { cursor, profile } = yield select(state => ({
      cursor: state.library.data && state.library.data.pageInfo.endCursor,
      profile: state.login.profile
    }));

    const response = yield call(
      graphql,
      query.buscarPastasLibrary(profile.email, cursor)
    );

    const { error, data } = response;

    if (error) {
      throw new { error: "@library/graphql_request_error" }();
    }

    yield put(
      LibraryCreators.librarySuccess({
        edges: data.data.pastas.edges,
        pageInfo: data.data.pastas.pageInfo
      })
    );
  } catch (error) {
    console.tron.log("Error saga", error.message);
    yield put(LibraryCreators.libraryFailure());
  }
}

export function* folderEdit({ payload }) {
  try {
    const response = yield call(graphql, query.editPasta(payload));

    const { error, data } = response;

    if (error) {
      throw new { error: "@library/graphql_edit_error" }();
    }

    const { oldData } = yield select(state => ({
      oldData: state.library.data
    }));

    const newData = oldData.edges.map(node => {
      if (node.node._id !== data.data.updatePasta.pasta._id) {
        return node;
      }
      return { node: data.data.updatePasta.pasta };
    });

    toast.success("Pasta modificada com sucesso.");

    yield put(
      LibraryCreators.libraryModifySuccess({
        edges: newData,
        pageInfo: oldData.pageInfo
      })
    );
  } catch (error) {
    toast.error("Algo deu errado ao modificar a pasta.");
    console.tron.log("Error saga", error.message);
    yield put(LibraryCreators.libraryFailure());
  }
}

export function* folderDelete({ payload }) {
  try {
    const response = yield call(graphql, query.deletePasta(payload));

    const { error } = response;

    if (error) {
      throw new { error: "@library/graphql_delete_error" }();
    }

    yield put(LibraryCreators.libraryRefresh());
    yield put(LibraryCreators.libraryRequest());
    toast.success("Pasta excluída com sucesso.");
  } catch (error) {
    toast.error("Algo deu errado ao excluír a pasta.");
    console.tron.log("Error saga", error.message);
    yield put(LibraryCreators.libraryFailure());
  }
}

export function* folderAddSinal({ payload }) {
  try {
    const response = yield call(graphql, query.addSinalPasta(payload));

    const { error } = response;

    if (error) {
      throw new { error: "@library/graphql_add_error" }();
    }

    toast.success(
      `Verbete adicionado com sucesso à pasta ${payload.folderTitle}`
    );
  } catch (error) {
    toast.error("Verbete não pode ser adicionado. Tente Novamente");
    yield put(LibraryCreators.libraryFailure());
  }
}
