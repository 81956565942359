import styled from "styled-components";
import { MaterialIcons } from "react-web-vector-icons";

import { colors } from "~/styles";

export const Container = styled.div``;

export const FilterIcon = styled(MaterialIcons).attrs((props) => ({
  name: "search",
  size: 30,
  color: "white",
}))``;

export const SearchButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: ${colors.primary};

  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  position: fixed;
  z-index: 99;
  top: 93%;
  right: 1%;
`;
