import React, { useRef, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form } from "@unform/web";
import { SuggestNewVerbeteRoute, SuggestNewVerbeteDescription } from '~/constants/routesName';
import { AppHeader, AppFooter, Container } from "~/components";
import { ModalSuccess, ModalError } from "./components";
import { Creators as SugestaoCreators } from "~/store/ducks/sugestao";
import * as Yup from "yup";
import { FontAwesome } from "react-web-vector-icons";
import { Helmet } from 'react-helmet';

import {
  Content,
  TitleContainer,
  Title,
  Box,
  InputContainer,
  InputTitle,
  InputBorder,
  InputFileContainer,
  FormInput,
  FormTextArea,
  ButtonContainer,
  Button,
} from "./style";

const Sugestion = ({ sugestaoMutation, sugestaoInit, sugestao, loading }) => {
  const [disabled, setDisabled] = useState(false);
  const formRef = useRef(null);
  const { error, success } = sugestao.data || {};

  async function handleSubmit(data, { reset }) {
    setDisabled(true);
    try {
      formRef.current.setErrors({});

      const schema = Yup.object().shape({
        nome: Yup.string()
          .required("O titulo é obrigatório")
          .max(255, "O limite máximo é de 255 letras"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      sugestaoMutation(data);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      setDisabled(false);
    }
  }

  function resetErros() {
    formRef.current.setErrors({});
  }

  const afterResponse = (sugestaoInit) => {
    setDisabled(false);
    sugestaoInit();
  };

  return (
    (success && <ModalSuccess onClose={() => afterResponse(sugestaoInit)} />) ||
    (error && <ModalError onClose={() => afterResponse(sugestaoInit)} />) || (
      <Container>
        <Helmet>
          <title>{SuggestNewVerbeteRoute}</title>
          <meta name="description" content={SuggestNewVerbeteDescription}></meta>
        </Helmet>
        <AppHeader back={true} />
        <Content>
          <TitleContainer>
            <Title>Sugerir Verbete</Title>
          </TitleContainer>
          <Box>
            <Form ref={formRef} onSubmit={handleSubmit}>
              <InputContainer>
                <InputTitle>Título do verbete</InputTitle>
                <InputBorder>
                  <FormInput name="nome" onFocus={resetErros} />
                </InputBorder>
              </InputContainer>
              <InputContainer>
                <InputTitle>Descrição da necessidade</InputTitle>
                <InputFileContainer>
                  <InputBorder>
                    <FormTextArea name="descricao" />
                  </InputBorder>
                </InputFileContainer>
              </InputContainer>
              <ButtonContainer>
                <Button type="submit" disabled={disabled} loading={loading}>
                  {loading ? (
                    <FontAwesome name="spinner" size={20} color="#fff" />
                  ) : (
                    "Enviar"
                  )}
                </Button>
              </ButtonContainer>
            </Form>
          </Box>
        </Content>

        <AppFooter />
      </Container>
    )
  );
};

const mapStateToProps = (state) => ({
  sugestao: state.sugestao,
  loading: state.sugestao.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      sugestaoMutation: SugestaoCreators.sugestaoMutation,
      sugestaoInit: SugestaoCreators.sugestaoInit,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sugestion);
