import React from "react";
import { Helmet } from 'react-helmet';

import { LibraryRoute, LibraryDescription } from "~/constants/routesName";
import Container from "~/components/Container";
import AppHeader from "~/components/AppHeader";
import AppFooter from "~/components/AppFooter";

import LibraryBody from "./components/LibraryBody";

const Library = props => {
  return (
    <Container>
        <Helmet>
          <title>{LibraryRoute}</title>
          <meta name="description" content={LibraryDescription}></meta>
        </Helmet>
      <AppHeader back />
      <LibraryBody />
      <AppFooter />
    </Container>
  );
};

export default Library;
