import styled, { keyframes } from "styled-components";
import { fonts } from "~/styles";

const apperarFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  animation: ${apperarFromLeft} 1s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 20px;

  span {
    color: #34495e;
    font-family: ${fonts.VisbyRoundMedium};
    font-size: 16px;
    margin-bottom: 30px;
  }
`;

export const StepsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center !important;
`;

export const StepContainer = styled.div`
  display: grid !important;
  grid-template-rows: 1fr 0.2fr;
  width: 350px !important;
  height: auto;
  flex-direction: column !important;
  justify-content: flex-start !important;
  margin: auto;

  img {
    margin: auto;
  }
  span {
    margin-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #34495e;
    width: inherit;
  }
`;
