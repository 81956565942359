import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import MediaQuery from "react-responsive";

import logo from "~/assets/image/logo.png";
import audiodescricao from "~/assets/image/audiodescricao.png";
import { MIN_TAB } from "~/constants/config";

import {
  Content,
  Header,
  Center,
  LoboBox,
  Logo,
  BackButton,
  BackText,
  IconsArrowLeft,
  AudiodescricaoImg,
  AudiodescricaoText,
  AsideLeft,
  AsideRight,
  AudioDescriptionButton,
} from "./style";

import LoggedUser from "../LoggedUser";

export const LogoTitle = ({ history }) => {
  return (
    <LoboBox>
      <Logo
        src={logo}
        onClick={() => history.push("/")}
        alt="Voltar para página inicial"
      />
    </LoboBox>
  );
};

class AppHeader extends Component {
  goBack = (pubLink) => {
    pubLink ? this.props.history.push("/") : this.props.history.goBack();
  };

  BackButton = (props) => (
    <BackButton onClick={() => this.goBack(props.pubLink)}>
      <IconsArrowLeft />
      <MediaQuery minWidth={MIN_TAB}>
        <BackText aria-label="Voltar">Voltar</BackText>
      </MediaQuery>
    </BackButton>
  );

  render = () => {
    const { back, history, pubLink = false } = this.props;

    const { BackButton } = this;

    return (
      <Header>
        <Content>
          <AsideLeft>
            {back && <BackButton pubLink={pubLink} />}
            <AudioDescriptionButton
              onClick={() => history.push("/audiodescricao")}
            >
              <AudiodescricaoImg
                src={audiodescricao}
                alt={"Símbolo Audiodescrição"}
              />
              <MediaQuery minWidth={MIN_TAB}>
                <div>
                    <AudiodescricaoText>Audiodescrição</AudiodescricaoText>
                </div>
              </MediaQuery>
            </AudioDescriptionButton>
          </AsideLeft>

          <Center>{<LogoTitle history={history} />}</Center>

          <AsideRight>
            <LoggedUser />
          </AsideRight>
        </Content>
      </Header>
    );
  };
}

export default withRouter(AppHeader);
