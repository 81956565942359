import styled from "styled-components";
import { MaterialIcons } from "react-web-vector-icons";

import { MIN_TAB, MIN_WEB, MIN_CEL } from "~/constants/config";

import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  height: 67px;
  justify-content: center;
  align-items: center;
  max-width: 420px;
  padding: 25px 0;
  padding-bottom: 40px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    height: 100px;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    height: 120px;
  }

  @media only screen and (max-width: ${MIN_CEL}px) {
    width: 390px;
    justidy-contene: center;
    margin: 0 15px 0 15px;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #4B77FA;

  background-color: white;
  width: 550px;
  height: 50px;
  padding: 0 15px;
  border-radius: 10px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    height: 60px;
  }
`;

export const SearchIcon = styled(MaterialIcons).attrs(props => ({
  name: "search",
  size: 28,
  color: colors.headerBackground
}))`
  padding: 5px;
`;

export const SearchInput = styled.input.attrs(props => ({
  placeholder: "Ex: Habilidade"
}))`
  display: flex;
  flex: 1;
  border: none;
  padding: 0 5px;
  color: ${colors.headerBackground};
  font-size: 16px;
  font-family: ${fonts.VisbyRoundRegular};

  ::placeholder {
    color: #889baf;
  }
`;

export const FilterBox = styled.button`
  display: flex;
  align-items: center;
  color: ${colors.headerBackground};
  font-size: 14px;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  padding: 5px;

  & :hover {
    cursor: pointer;
  }
`;

export const FilterIcon = styled(MaterialIcons).attrs(props => ({
  name: "filter-list",
  size: 18,
  color: colors.headerBackground
}))`
  padding: 5px;
`;
