import styled from "styled-components";
import { colors, fonts } from "~/styles";
import { Input } from "~/components";
import { Form as Unform } from "@unform/web";

export const Form = styled(Unform)`
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid ${colors.borderForm};
`;

export const ButtonArea = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 10px;
  margin-bottom: 22px;
`;

export const ButtonOutline = styled.button`
  border-radius: 7px;
  padding: 10px 30px;
  display: flex;
  background: #4a7aff;
  color: white;
  margin-top: 14px;
  font-weight: bold;
  box-shadow: 0px 3px 4px 1px #2a353e33;
  &:hover {
    background: #3979dd;
  }
  &:focus {
    filter: drop-shadow(1px 1px 1px gray);
  }
  &:disabled {
    background: gray;
  }
  ${props =>
    props.inverse &&
    `
    background: #dbe5ff;
    color: #4a7aff;
    &:hover {
      background: #d4e0f6;
    }
  `}
  ${props => props.marginRight && `margin-right: 20px;`}
`;

export const InputBorder = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${colors.borderInput};
`;

export const FormInput = styled(Input)`
  flex: 1;
  margin: 5px 10px;
  color: ${colors.subTitle};
  border: none;
  background-color: transparent;
  font-size: 14px;

  ::placeholder {
    color: ${colors.subTitle};
    font-family: ${fonts.VisbyRoundMedium};
  }
`;
