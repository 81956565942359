import { HistoryTypes as Types } from "~/constants/types";
import { LoginTypes } from "~/constants/types";
import immer from "immer";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false
};

export default function history(state = INITIAL_STATE, { type, payload }) {
  return immer(state, draft => {
    switch (type) {
      case Types.CREATE: {
        break;
      }
      case Types.REFRESH: {
        draft.loading = false;
        draft.error = false;
        draft.data = null;
        break;
      }
      case LoginTypes.DESTROY_SESSION: {
        draft.data = null;
        draft.loading = false;
        draft.error = false;
        draft.refreshing = false;
        break;
      }
      case Types.REQUEST: {
        draft.loading = true;
        break;
      }
      case Types.SUCCESS: {
        const oldList = state.data && state.data.edges;
        const newerList = payload.edges;
        const edges = oldList ? oldList.concat(newerList) : newerList;
        draft.data = { ...payload, edges };
        draft.loading = true;
        break;
      }
      case Types.FAILURE: {
        draft.error = true;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  historyCreate: payload => ({ type: Types.CREATE, payload }),
  historyRefresh: payload => ({ type: Types.REFRESH }),
  historyRequest: payload => ({ type: Types.REQUEST, payload }),
  historySuccess: payload => ({ type: Types.SUCCESS, payload }),
  historyFailure: payload => ({ type: Types.FAILURE, payload })
};
