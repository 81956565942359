import styled from "styled-components";

import { MIN_TAB, MIN_WEB } from "~/constants/config";
import { colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.listBackground};

  @media only screen and (min-width: ${MIN_TAB}px) {
    padding: 0 10%;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    padding: 0 20%;
  }
`;

export const IFrameRedirect = styled.iframe`
  display: none;
`;
