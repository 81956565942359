import styled from "styled-components";
import { FontAwesome } from "react-web-vector-icons";

import { MIN_TAB } from "~/constants/config";

import { colors } from "~/styles";

import apple from "~/assets/image/app_apple_full.png";
import google from "~/assets/image/app_google_full.png";

export const Content = styled.div`
  position: fixed;
  right: 0;
  top: 40%;

  @media only screen and (min-width: ${MIN_TAB}px) {
    margin-left: 50px;
    top: 0;
  }
`;

export const Modal = styled.div`
  display: flex;
  z-index: 99;
  background-color: white;
  flex-direction: column;
  margin-right: 5px;
  margin-top: 5px;

  border: 1px solid ${colors.borderInput};
  border-radius: 10px;
  box-shadow: 0px 1px 3px #2a353e33;

  position: fixed;
  left: 0px;
  bottom: 40px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    position: fixed;
    left: 50px;
    bottom: 64px;
  }
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonClose = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  margin: 0 10px;
`;

export const IconClose = styled(FontAwesome).attrs((props) => ({
  name: "times",
  size: 20,
  color: "black",
}))`
  align-self: flex-end;
`;

export const Title = styled.div`
  align-self: center;
  font-family: "VisbyRoundCF-Medium";
  font-weight: bold;
  padding: 10px;
  text-align: center;
`;

export const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  justify-content: space-around;
`;

export const Apple = styled.img.attrs((props) => ({
  src: apple,
  alt:"Ir para página do SENAI Libras na Apple Store"
}))`
  width: 100px;
  height: auto;
  margin: 5px 20px;
`;

export const Google = styled.img.attrs((props) => ({
  src: google,
  alt:"Ir para página do SENAI Libras no Google Play"
}))`
  width: 100px;
  height: auto;
  margin: 5px 20px;
`;
