import styled from "styled-components";

import { MIN_TAB, MIN_WEB } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  padding: 8% 10%;
  margin: auto;
  background: ${colors.backgroundLightGray};
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 50% 20px;
    display: flex;
  }
`;

export const BackgroundEllipse = styled.div`
  width: auto;
  height: 120%;
  background: radial-gradient(
    circle closest-side,
    ${colors.primary} 0%,
    ${colors.primary} 100%,
    rgba(0, 0, 0, 0) 100%
  );
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  @media only screen and (max-width: ${MIN_TAB}px) {
    width: 130%;
    left: -15%;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: auto;
  align-items: center;
  text-align: center;
  color: ${colors.white_label};
  z-index: 100;
  @media only screen and (max-width: ${MIN_TAB}px) {
    text-align: end;
    margin: 0 0 0 -30%;
    align-items: flex-end;
  }
`;

export const Title = styled.h2`
  font-size: 50px;
  font-weight: normal;
  max-width: 500px;
  font-family: "VisbyRoundCF-Regular";
  font-weight: 100;
  @media only screen and (max-width: ${MIN_WEB}px) {
    font-size: 45px;
  }
  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 35px;
  }
`;

export const SubTitle = styled.p`
  font-size: 30px;
  margin: 50px auto;
  max-width: 620px;
  font-family: ${fonts.VisbyRoundMedium};
  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 20px;
    margin: 15px auto 30px;
    max-width: 320px;
  }
`;

export const ButtonMore = styled.a`
  padding: 10px 25px;
  border-radius: 10px;
  font-size: 20px;
  color: ${colors.white_label};
  background: ${colors.tertiary};
  &:hover {
    box-shadow: -6px 6px 10px #00000029;
  }
  i {
    margin: 0 0 0 10px;
  }
`;

export const MockupBox = styled.div`
  display: flex;
  justify-content: center;
  z-index: 100;
`;

export const MockupCelular = styled.img`
  width: 110%;
  @media only screen and (max-width: ${MIN_TAB}px) {
    margin: 0 0 0 -45%;
  }
`;
