import React from "react";
import { Helmet } from 'react-helmet';

import Container from "~/components/Container";
import { HistoryRoute, HistoryDescription } from '~/constants/routesName';

import HistoryHeader from "./components/HistoryHeader";
import HistoryFooter from "./components/HistoryFooter";
import List from "./components/List";

export default function History() {
  return (
    <Container>
      <Helmet>
        <title>{HistoryRoute}</title>
        <meta name="description" content={HistoryDescription}></meta>
      </Helmet>
      <HistoryHeader />
      <List />
      <HistoryFooter />
    </Container>
  );
}
