import styled from "styled-components";
// import styled from 'styled-components/native';

import { MIN_TAB } from "~/constants/config";

export const Container = styled.div`
  display: grid;
  /* grid-gap: 5px; */
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* grid-auto-rows: 140px; */

  @media only screen and (min-width: ${MIN_TAB}px) {
    /* grid-gap: 15px; */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* grid-auto-rows: 180px; */
  }

  @media only screen and (min-width: ${MIN_TAB}px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* grid-auto-rows: 210px; */
  }
`;
