import React, { useEffect, useState } from "react";
import { confirmEmail } from "~/constants/loginFunctions";

import { ButtonOutline, Message, InternalTitle, Content } from "./style";

const ConfirmEmail = ({ securityToken, setForm }) => {
  const [response, setResponse] = useState("");

  useEffect(() => {
    async function handleConfirmEmail() {
      setResponse(await confirmEmail(securityToken));
    }
    handleConfirmEmail();
  }, [securityToken]);

  return (
    <>
      <InternalTitle>Confirmação de e-mail</InternalTitle>
      <Content>
        <Message>{response}</Message>
        <ButtonOutline onClick={() => setForm("login")}>
          Voltar ao login
        </ButtonOutline>
      </Content>
    </>
  );
};

export default ConfirmEmail;
