import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import AppHeader from "~/components/AppHeader";

import { Content } from "./style";

const LandingPageHeader = (props) => {
  const { auth } = useSelector(state => state.login);

  if(auth === true && props.location.pathname !== "/dashboard"){
    props.history.push("/dashboard");
  }

  return (
    <Content>
      <AppHeader />
    </Content>
  );
};

export default withRouter(LandingPageHeader);
