import styled from "styled-components";
import { fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  /* border: 1px solid red; */

  flex-direction: column;
`;

export const TitleBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
`;

export const Title = styled.h5`
  color: #4a7aff;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 20px;
`;

export const BodyBox = styled.div`
  display: flex;
  flex: 2;
  /* border: 1px solid gold; */
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
`;

export const Body = styled.span`
  color: #34495e;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 15px;
  font-weight: ${props => (props.bold ? "bold" : "normal")};

  padding: 2px;
`;

export const FooterBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
`;

export const Footer = styled.div``;

export const FooterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  margin-left: 5px;

  width: 110px;
  height: 40px;

  color: ${props => (props.warn ? "white" : "#4663E3")};
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  background-color: ${props => (props.warn ? "#FF6060" : "#DBE5FF")};
  border-radius: 10px;
`;
