import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  margin: auto;
`;

export const SearchBox = styled.div`
  padding: 10% 0 5%;
  background: ${colors.primary};

  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 30% 0 15%;
  }
`;

export const SearchText = styled.h4`
  font-size: 40px;
  font-weight: normal;
  font-weight: bold;
  color: ${colors.white_label};
  font-family: ${fonts.VisbyRoundMedium};
  text-align: center;
  margin: 0 0 30px;
`;

export const DownloadBox = styled.div`
  padding: 5% 0 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 15% 0 30%;
  }
`;

export const DownloadOr = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Or = styled.p`
  font-size: 110px;
  margin: 0 5px 0 0;
  color: ${colors.tertiary};
  font-family: ${fonts.VisbyRoundMedium};
`;

export const DownloadText = styled.h4`
  font-size: 30px;
  font-weight: bold;
  color: ${colors.itemCardName};
  font-family: ${fonts.VisbyRoundMedium};
  margin: 20px 0;
`;

export const DownloadOptions = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Logo = styled.img`
  width: min-content;
`;

export const DownloadLink = styled.a``;

export const DownloadImg = styled.img`
  height: 60px;
  margin: 10px;
`;
