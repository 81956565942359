import styled from "styled-components";
import { FontAwesome } from "react-web-vector-icons";

import { MIN_WEB, MIN_TAB } from "~/constants/config";

import { colors } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
  background-color: white;
  border-radius: 10px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    margin: 10px 10%;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    margin: 10px 20%;
  }
`;

export const Box = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 5px;
 `;

export const BoxTitle = styled.div`
  display: flex;
  flex: 1;
  width:100%;
  align-items: center;
  justify-content: center;
  margin: 10px 10px;
 
`;

export const Title = styled.h2`
  font-size: 18px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: 600;
  color: ${colors.backgroundSignal};
  margin-left: 10px;
  
`;

export const Icons = styled(FontAwesome).attrs(props => ({
  name: "photo",
  size: 25,
  color: colors.backgroundSignal
}))`
`;

export const BoxMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 10px;
`;
export const ShareButton = styled.button`
display:flex;
margin-left:40%;
margin-right:2%;  

`;
export const IconShare = styled(FontAwesome).attrs(props => ({
  name: "share-alt",
  size: 25,
  color: colors.backgroundSignal
}))`
`;