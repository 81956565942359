import React from "react";

import Header from "./components/Header";
import ShowList from "./components/ShowList";

import { Content } from "./style";

const LibraryBody = props => {
  return (
    <Content>
      <Header />
      <ShowList />
    </Content>
  );
};

export default LibraryBody;
