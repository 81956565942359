import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import SocialMedias from "./components/SocialMedias";

import {
  Container,
  Close,
  CloseIcon,
  Title,
  LinkUrl,
  TitleDiv,
  Divisor,
  LinkDiv,
  CopyButton,
  CopiedIcon
} from "./styles";

class ModalShare extends Component {
  state = {
    // url: window.location.href.replace("signal", "redirect"),
    copied: false
  };

  onCopy = () => {
    this.setState({ copied: true });
  };

  render() {
    const { copied } = this.state;
    const { onModalToogle, mobile, url } = this.props;

    return (
      <Container>
        <TitleDiv mobile={mobile}>
          <Title>Compartilhar Verbete</Title>
          <Close
            aria-label={"Fechar Compartilhamento de verbete"}
            onClick={onModalToogle}
          >
            <CloseIcon />
          </Close>
        </TitleDiv>
        <SocialMedias url={url} />
        <Divisor />
        <CopyToClipboard onCopy={this.onCopy} text={url}>
          <LinkDiv mobile={mobile}>
            <LinkUrl disabled type={"text"} value={url} />
            <CopyButton onClick={() => this.onCopy}>
              COPIAR
              {copied ? <CopiedIcon /> : null}
            </CopyButton>
          </LinkDiv>
        </CopyToClipboard>
      </Container>
    );
  }
}

export default ModalShare;
