import styled, { keyframes } from "styled-components";
import { fonts } from "~/styles";

const apperarFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  animation: ${apperarFromLeft} 1s;

  ul {
    list-style: none;

    li {
      margin-top: 20px;
      img {
        margin-top: 10px;
      }

      a {
        text-decoration: none;
        color: #4a7aff;
        font-family: ${fonts.VisbyRoundMedium};
        font-weight: bold;
      }
    }
  }
`;
