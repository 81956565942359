import styled, { css } from "styled-components";
import { MaterialCommunityIcons } from "react-web-vector-icons";

// import ScrollMenu from "react-horizontal-scrolling-menu";

import { MIN_TAB } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    padding: 0 20%;
  }
`;

// export const List = styled(ScrollMenu).attrs(props => ({}))``;

export const Item = styled.span.attrs((props) => ({}))`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 40px !important;
  height: 40px !important;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: white;
  color: #889baf;

  font-family: ${fonts.VisbyRoundMedium};

  ${(props) =>
    props.mark &&
    css`
      color: white;
      background-color: #4a7aff;
    `}

  & img {
    display: none;
  }

  :hover {
    background-color: #7dadff;
    color: white;
    cursor: pointer;
  }

  & span {
    padding: 0 20px;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  height: 40px !important;
  width: 40px !important;
  border: 1px solid #eaeaea;
  border-radius: 40px;
  background-color: white;

  :hover {
    cursor: pointer;
    background-color: #7dadff;
  }

  :active {
    background-color: #4a7aff;
  }
`;

export const Icon = styled(MaterialCommunityIcons).attrs((props) => ({
  name: props.inverted ? "chevron-right" : "chevron-left",
  size: 30,
  color: colors.headerBackground,
}))`
  padding: 5px;

  :hover {
    color: white;
  }
`;

export const styles = {
  menuStyle: {
    overflow: "hidden",
    paddingRight: 10,
    paddingLeft: 10,
  },
  wrapperStyle: {
    display: "flex",
    overflow: "hidden",
    flex: "1 0",
  },
  innerWrapperStyle: {
    display: "flex",
    width: "100%",
  },
};
