import { call, put, select } from "redux-saga/effects";

import { Creators as SearchCreators } from "~/store/ducks/search";
import { Creators as InfoCreators } from "~/store/ducks/info";
import { elastic } from "~/services/api";

export function* searchRequest() {
  try {
    const body = yield select((state) => ({
      page: state.search.data && state.search.data.pageInfo.endCursor,
      q: state.search.search,
    }));

    if (!body.q) return;
    const { data, error } = yield call(elastic, { params: body });

    if (error) throw new Error("Erro ao buscar por " + body.q);

    yield put(SearchCreators.searchSuccess(data.data.sinals));
  } catch (e) {
    yield put(SearchCreators.searchFailure());
    console.tron.log(e.message);
  }
}

export function* searchSet({ payload }) {
  // try {
  yield put(InfoCreators.setMode(payload ? "search" : "dashboard"));
  // yield delay(200);
  // yield put(SearchCreators.searchRequest());
  // } catch (e) {
  // console.tron.log(e.message);
  // }
}
