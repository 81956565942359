import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import MediaQuery from "react-responsive";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { Creators as LibraryCreators } from "~/store/ducks/library";

import HOCModal from "~/components/HOCModal";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

import { Content, TitleBox, BodyBox, FooterBox } from "./style";
import { Title, FormContainer, FormInput, FooterButton } from "./style";

const Modal = ({ isOpen, close, action, width, height, mobile, title, id }) => {
  const formRef = useRef();
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    try {
      resetErros();

      const schema = Yup.object().shape({
        name: Yup.string().required("O nome é obrigatório")
      });

      await schema.validate(data, {
        abortEarly: false
      });

      dispatch(
        LibraryCreators.libraryEditingRequest({ id, titulo: data.name })
      );
      close();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        toast.error("Algo deu errado ao criar a pasta.");
        err.inner.forEach(error => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function resetErros() {
    formRef.current.setErrors({});
  }
  return (
    <HOCModal
      isOpen={isOpen}
      close={close}
      modalHeigth={height}
      modalWidth={width}
      mobile={mobile}
    >
      <FormContainer
        ref={formRef}
        onSubmit={handleSubmit}
        initialData={{ name: title }}
      >
        <Content>
          <TitleBox>
            <Title>Renomear pasta</Title>
          </TitleBox>
          <BodyBox>
            <FormInput
              name="name"
              type="text"
              placeholder="Nome"
              onFocus={resetErros}
            />
          </BodyBox>
          <FooterBox>
            <FooterButton onClick={close} warn>
              Voltar
            </FooterButton>
            <FooterButton type="submit">Salvar</FooterButton>
          </FooterBox>
        </Content>
      </FormContainer>
    </HOCModal>
  );
};

const ModalEdit = props => {
  const { isOpen } = props;

  if (!isOpen) return null;

  return (
    <>
      <MediaQuery minWidth={MIN_WEB}>
        <Modal width={650} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_WEB} minWidth={MIN_TAB}>
        <Modal width={500} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_TAB}>
        <Modal width={320} height={270} {...props} mobile />
      </MediaQuery>
    </>
  );
};

export default ModalEdit;
