import React, { Component } from "react";

import { Content, TitleRow, LogoTitle, CloseButton, CloseIcon } from "./style";

import logoTitleWhite from "~/assets/image/logo-title-white.png";

class Header extends Component {
  render() {
    const { close } = this.props;

    return (
      <Content>
        <TitleRow>
          <div></div>
          <LogoTitle src={logoTitleWhite} />
          <CloseButton aria-label={"Fechar Créditos"} onClick={close}>
            <CloseIcon />
          </CloseButton>
        </TitleRow>
      </Content>
    );
  }
}

export default Header;
