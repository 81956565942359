import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as DashboardCreators } from "~/store/ducks/dashboard";
import { Creators as SearchCreators } from "~/store/ducks/search";
import { Creators as TechCreators } from "~/store/ducks/technologies";
import { Creators as LetterCreators } from "~/store/ducks/letter";
import Error404 from "~/components/Error404";

import ShowHeader from "./components/ShowHeader";
import ShowList from "./components/ShowList";
import ShowLetters from "./components/ShowLetters";

import { Container, Content } from "./style";

class DashboardBody extends Component {
  componentDidMount() {
    this.props.dashboardRequest();
    this.props.technologiesLoad();
  }

  getRequest = () => {
    const { mode } = this.props;

    switch (mode) {
      case "dashboard":
        return this.props.dashboardRequest;
      case "search":
        return this.props.searchRequest;
      case "technologies":
        return this.props.technologyRequest;
      case "letter":
        return this.props.letterRequest;
      default:
        return () => console.log("Sem acao");
    }
  };

  render() {
    const { mode, data, listFormat } = this.props;

    const current = data[mode];

    if (!current) return null;

    if (current.error) return <Error404 />;

    return (
      <Container>
        <Content>
          <ShowLetters />
          <ShowHeader current={current} />
          <ShowList
            listFormat={listFormat}
            data={current.data}
            loading={current.loading}
            action={this.getRequest()}
          />
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  data: {
    dashboard: state.dashboard,
    technologies: state.technologies,
    search: state.search,
    letter: state.letter
  },
  // filter: state.technologies.set,
  mode: state.info.mode,
  listFormat: state.info.listFormat
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dashboardRequest: DashboardCreators.dashboardRequest,
      technologyRequest: TechCreators.technologyRequest,
      searchRequest: SearchCreators.searchRequest,
      technologiesLoad: TechCreators.technologiesLoad,
      letterRequest: LetterCreators.letterRequest
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(DashboardBody);
