import React, { Component } from "react";

import compartilheImg from "~/assets/image/landingPage/compartilhe.png";
import sugiraImg from "~/assets/image/landingPage/sugira.png";
import organizeImg from "~/assets/image/landingPage/organize.png";

import {
  Content,
  TitleBox,
  Title,
  AdvantageBox,
  AdvantageSection,
  AdvantageImg,
  AdvantageEllipse,
  AdvantageText,
} from "./style";

class TryShare extends Component {
  render() {
    return (
      <Content>
        <TitleBox>
          <div data-aos="zoom-in" data-aos-offset="200">
            <Title>Experimente e Compartilhe</Title>
          </div>
        </TitleBox>

        <AdvantageBox>
          <AdvantageSection>
            <AdvantageEllipse
              data-aos="zoom-in"
              data-aos-delay="0"
              data-aos-offset="300"
            >
              <div
                data-aos="zoom-in"
                data-aos-delay="500"
                data-aos-offset="400"
              >
                <AdvantageImg src={compartilheImg} />
              </div>
            </AdvantageEllipse>
            <AdvantageText>
              <div
                data-aos="zoom-in"
                data-aos-delay="500"
                data-aos-offset="100"
              >
                <h4>Organize seus verbetes</h4>
                <p>Salve os verbetes que mais gostou e organize em pastas.</p>
              </div>
            </AdvantageText>
          </AdvantageSection>

          <AdvantageSection>
            <AdvantageEllipse
              data-aos="zoom-in"
              data-aos-delay="100"
              data-aos-offset="300"
            >
              <div
                data-aos="zoom-in"
                data-aos-delay="700"
                data-aos-offset="400"
              >
                <AdvantageImg src={sugiraImg} />
              </div>
            </AdvantageEllipse>
            <AdvantageText>
              <div
                data-aos="zoom-in"
                data-aos-delay="700"
                data-aos-offset="100"
              >
                <h4>Sugira novos termos</h4>
                <p>
                  Agora você pode sugerir novos verbetes para completar nosso
                  Glossário.
                </p>
              </div>
            </AdvantageText>
          </AdvantageSection>

          <AdvantageSection>
            <AdvantageEllipse
              data-aos="zoom-in"
              data-aos-delay="200"
              data-aos-offset="300"
            >
              <div
                data-aos="zoom-in"
                data-aos-delay="900"
                data-aos-offset="400"
              >
                <AdvantageImg src={organizeImg} />
              </div>
            </AdvantageEllipse>
            <AdvantageText>
              <div
                data-aos="zoom-in"
                data-aos-delay="900"
                data-aos-offset="100"
              >
                <h4>Compartilhe verbetes</h4>
                <p>
                  São diversos termos para compartilhar com seus amigos e levar
                  o SENAI Libras para todos.
                </p>
              </div>
            </AdvantageText>
          </AdvantageSection>
        </AdvantageBox>
      </Content>
    );
  }
}

export default TryShare;
