import React from "react";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";

import { withRouter } from "react-router";

import { CDN } from "~/constants/config";
import { Creators as TechCreators } from "~/store/ducks/technologies";
import Waiting from "~/components/Waiting";

import { Content, TechList, TechBox, TechTitle, ShowImage } from "./style";

function Areas(props) {
  const { list_tech } = props;

  props.technologiesLoad();

  const handleFilter = (props, area) => {
    props.technologySet(area);
    props.history.push("/dashboard");
  };

  let n = 0;
  const delayItem = () => (n += 150);

  return (
    <Content>
      {!list_tech ? (
        <Waiting />
      ) : (
        <TechList>
          {list_tech.map((item) => (
            <div
              data-aos="zoom-in"
              data-aos-offset="100"
              data-aos-delay={delayItem()}
            >
              <Item
                key={item.node._id}
                item={item.node}
                action={() => handleFilter(props, item.node)}
              />
            </div>
          ))}
        </TechList>
      )}
    </Content>
  );
}

const Item = ({ item, action }) => {
  const url = `${CDN}${item.iconeRepresentativo}`;

  return (
    <TechBox
      onClick={action}
      color={item.backgroundColor}
      aria-label={`Filtrar por ${item.nome}`}
    >
      <ShowImage src={url} />
      <TechTitle>{item.nome}</TechTitle>
    </TechBox>
  );
};

const mapStateToProps = (state) => ({
  list_tech: state.technologies.list && state.technologies.list.edges,
  loading: state.technologies.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      technologyRequest: TechCreators.technologyRequest,
      technologiesLoad: TechCreators.technologiesLoad,
      technologySet: TechCreators.technologySet,
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Areas);
