import styled from "styled-components";

export const SocialButtonsView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
`;

export const SocialButton = styled.button`
  background: ${e => (e.social === "facebook" ? "#3a559f" : "#f44336")};
  color: white;
  display: flex;
  justify-content: center;
  padding: 12px;
  margin-top: 10px;
  font-size: 14px;
  &:hover {
    background: ${e => (e.social === "facebook" ? "#39487d" : "#e22114")};
  }
  &:focus {
    filter: drop-shadow(1px 1px 1px gray);
  }
  border-radius: 20px;
`;
