import styled from "styled-components";

import { colors } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.backgroundSignal};
  padding-bottom: 20px;
`;

export const Fill = styled.div`
  display: flex;
  flex: 1;
  background-color: ${colors.backgroundSignal};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.backgroundSignal};
`;
