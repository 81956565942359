import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import App from "./App";
import { findUsuarioLogado } from "~/constants/loginFunctions";
import { URL_SENTRY } from "~/constants/config";

Sentry.init({
  dsn: URL_SENTRY,
});

ReactDOM.render(<App />, document.getElementById("root"));

// If "yourapp://" is registered, the user will see a dialog
// asking if they want to open your app. If they agree, your
// app will launch immediately and the timer won't fire.
// If not installed, you'll get an ugly "Cannot Open Page"
// dialogue and your fallback page will open when the timer expires.

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

window.onload = function() {
  const urlString = window.location.href;
  const url = new URL(urlString);
  if (url.searchParams.get("auth-provider")) {
    const provider = url.searchParams.get("auth-provider");
    const token = url.searchParams.get("auth-token");

    findUsuarioLogado(provider, token);
  }
};
