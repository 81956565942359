import React, { Component } from "react";

import Waiting from "~/components/Waiting";
// import Error404 from "~/components/Error404";
// import Error404 from "~/assets/";
// import { ReactComponent as Empty } from "~/assets/svg/empty.svg";

import Item from "./components/Item";
import HasMore from "./components/HasMore";

import {
  Content,
  ContentList,
  ContentListRow,
  CenterContent,
  Astro,
  Label,
  SugerirButton
} from "./style";

class ShowList extends Component {
  render() {
    const { data, loading, action, listFormat } = this.props;

    if (!data && loading) return <Waiting />;

    if (!data) return null;

    if (data.edges.length) {
      return (
        <Content>
          <List data={data} listFormat={listFormat} />
          <HasMore
            hasMore={data.pageInfo.hasNextPage}
            action={() => action()}
            loading={loading}
          />
        </Content>
      );
    }

    return (
      <CenterContent>
        <Astro />
        <Label>Nenhum resultado encontrado</Label>
        <SugerirButton
          onClick={() => (window.location.href = "/sugerir-verbete")}
        >
          Sugerir Verbete
        </SugerirButton>
      </CenterContent>
    );
  }
}

/**
 * Component interno para renderizar as duas formas de lista
 */
const List = props => {
  const { data, listFormat } = props;

  const Wrapper = listFormat ? ContentListRow : ContentList;

  return (
    <Wrapper>
      {data.edges.map(item => (
        <Item key={item.node._id} item={item.node} row={listFormat} />
      ))}
    </Wrapper>
  );
};

export default ShowList;
