import React, { Component } from "react";

import sebastiaoNoCelular from "~/assets/image/landingPage/sebastiaoNoCelular.png";

import { Content, OpinionImg, OpinionTestimony, OpinionName, OpinionAlt } from "./style";

function Opinion(props) {

    return (
      <Content data-aos="fade-right" data-aos-offset="420" data-aos-duration="1000">
        <OpinionImg src={sebastiaoNoCelular} />
        <OpinionTestimony>“{props.testimony}”</OpinionTestimony>
        <OpinionName>{props.name}</OpinionName>
        <OpinionAlt>{props.alt}</OpinionAlt>
      </Content>
    );

}

export default Opinion;
