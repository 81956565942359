import React, { Component } from "react";

import iconHandColored from "~/assets/image/landingPage/logo_senai_libras.png";
import downloadGooglePlay from "~/assets/image/landingPage/presentation_download_play_store.png";
import downloadAppStore from "~/assets/image/landingPage/presentation_download_app_store.png";
import mockupCelularImg from "~/assets/image/landingPage/mockupCelular.png";
import mockupNotebookImg from "~/assets/image/landingPage/mockupNotebook.png";
import Search from "./components/Search";

import {
  Content,
  TitleBox,
  Title,
  SubTitle,
  LogoWrapper,
  Logo,
  Experimente,
  DisponivelPara,
  DownloadBox,
  DownloadLink,
  DownloadImg,
  MockupBox,
  MockupCelular,
  MockupNotebook,
} from "./style";
let GooglePlay =
  "https://play.google.com/store/apps/details?id=com.senailibras&hl=pt_BR";
let AppStore = "https://apps.apple.com/br/app/senai-libras/id1480431650";

class Presentation extends Component {
  render() {
    return (
      <Content>
        <TitleBox>
          <div data-aos="zoom-in">
            <LogoWrapper>
            <Logo src={iconHandColored} />
            </LogoWrapper>
            <SubTitle>
              Encontre milhares de termos em LIBRAS
            </SubTitle>
            <Experimente>
              Experimente agora
            </Experimente>
            <Search />
            <DisponivelPara>
              Disponível para:
            </DisponivelPara>
            <DownloadBox>
              <DownloadLink href={GooglePlay} target="_blank">
                <DownloadImg src={downloadGooglePlay} />
              </DownloadLink>
              <DownloadLink href={AppStore} target="_blank">
                <DownloadImg src={downloadAppStore} />
              </DownloadLink>
            </DownloadBox>
          </div>
        </TitleBox>

        <MockupBox>
          <div
            data-aos="fade-left"
            data-aos-delay="600"
            data-aos-duration="800"
          >
            <MockupNotebook src={mockupNotebookImg} />
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="300"
            data-aos-duration="800"
          >
            <MockupCelular src={mockupCelularImg} />
          </div>
        </MockupBox>
      </Content>
    );
  }
}

export default Presentation;
