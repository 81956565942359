import React from "react";
import { ModalResult } from "../";

const ModalSuccess = ({ onClose }) => (
  <ModalResult
    title="Sugestão enviada com sucesso!"
    text="Agradecemos sua colaboração para que o SENAI Libras continue crescendo."
    type="success"
    color="#FFCE00"
    icon="check"
    onClose={onClose}
  />
);

export default ModalSuccess;
