import { toast } from "react-toastify";
import { URL } from "./config";
import { rest, graphql } from "~/services/api";
import { buscarUsuarioLogado } from "./graphql";

// import FlashMessage from "react-flash-message";
import { Creators as LoginCreators } from "~/store/ducks/login";
import { store } from "~/store";

export const doLogin = async provider => {
  if ("local" === provider) {
    store.dispatch(LoginCreators.loginRequest());
    const loginButton = document.querySelector("#loginButton");
    loginButton && (loginButton.disabled = true);
    const { data, status } = await rest.post("/login/local/authorize", {
      email: document.querySelector("#email").value,
      password: document.querySelector("#password").value
    });
    if (data.code === 401) {
      toast.info(data.message);
      store.dispatch(LoginCreators.emailConfirm(data.message));
      loginButton && (loginButton.disabled = false);
      return;
    }
    if (status === 200 && data.user) {
      toast.success("Login realizado com sucesso");
      store.dispatch(LoginCreators.loginSuccess(data.user));
    } else {
      const obj = data ? data.message : "Erro ao efetuar login";
      store.dispatch(LoginCreators.loginFailure(obj));
      loginButton && (loginButton.disabled = false);
    }
    return;
  }
  window.location.href = URL + "/login/" + provider + "/authorize";
};

export const doRegister = async setForm => {
  const registerButton = document.querySelector("#registerButton");
  registerButton.disabled = true;
  const { data, status } = await rest.post("/criar_usuario", {
    email: document.querySelector("#email").value,
    password: document.querySelector("#password").value,
    name: document.querySelector("#name").value
  });
  if (status === 200 && data.code === 401) {
    toast.info(data.message);
    store.dispatch(LoginCreators.emailConfirm(data.message));
    registerButton.disabled = false;
    setForm("login");
  } else {
    const obj = data ? data.message : "Erro ao efetuar cadastro";
    store.dispatch(LoginCreators.loginFailure(obj));
    registerButton.disabled = false;
  }
};

export const doLogout = async (provider, token) => {
  const { status } = await rest.post("/logout/" + provider, { token: token });
  if (status === 200) {
    store.dispatch(LoginCreators.loginDESTROY());
    toast.success("Logout realizado com sucesso");
  }
};

export const recoveryPassword = async (email, setForm) => {
  store.dispatch(LoginCreators.resetPassRequest());
  document.querySelector("#sendButton").disabled = true;
  const { data, status } = await rest.post("/login/recovery", {
    email
  });
  if (status === 200 && data.code === 200) {
    store.dispatch(LoginCreators.resetPassSuccess());
    document.querySelector("#email").value = "";
    document.querySelector("#sendButton").disabled = false;
    setForm("mail-sent");
  } else {
    // alert(data ? data.message : "Erro ao recuperar senha");
    const obj = data ? data.message : "Erro ao recuperar senha";
    store.dispatch(LoginCreators.resetPassFailure(obj));
    document.querySelector("#sendButton").disabled = false;
  }
};

export const changePassword = async (password, token, setForm) => {
  const changePasswordButton = document.querySelector("#changePasswordButton");
  changePasswordButton && (changePasswordButton.disabled = true);
  const { data, status } = await rest.post("/login/change-password", {
    password,
    token
  });
  if (status === 200 && data.code === 200) {
    toast.info("Senha alerado com sucesso");
    setForm("login");
  } else {
    const obj = data ? data.message : "Erro ao alterar senha";
    store.dispatch(LoginCreators.loginFailure(obj));
    changePasswordButton && (changePasswordButton.disabled = false);
  }
};

export const confirmEmail = async token => {
  const { data, status } = await rest.post("/login/confirm-email", {
    token: token
  });
  if (status === 200 && data.code === 200) {
    return data.message;
  } else {
    return "Erro ao confirmar E-mail";
  }
};

export const findUsuarioLogado = async (provider, token) => {
  store.dispatch(LoginCreators.loginSuccess({ provider, token }));

  const res = await graphql(buscarUsuarioLogado());
  const usuarioLogado = res.data.data.logadoUsuario || null;
  if (usuarioLogado) {
    toast.success("Login realizado com sucesso");
    store.dispatch(
      LoginCreators.loginSuccess({ ...usuarioLogado, provider, token })
    );
  }
};
