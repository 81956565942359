import React from "react";
import { LoginForm, RegisterForm, Selector } from "../";

const LoginRegister = ({ isLogin, isRegister, setForm }) => (
  <>
    <Selector {...{ isLogin, isRegister, setForm }} />
    {isLogin && <LoginForm setForm={setForm} />}
    {isRegister && <RegisterForm setForm={setForm} />}
  </>
);

export default LoginRegister;
