import Reactotron from "reactotron-react-js";
import { reactotronRedux } from "reactotron-redux";
import sagaPlugin from "reactotron-redux-saga";
import tronsauce from "reactotron-apisauce";

if (process.env.NODE_ENV === "development") {
  const tron = Reactotron.configure()
    .use(reactotronRedux())
    .use(sagaPlugin())
    .use(tronsauce())
    .connect();

  tron.clear();

  console.tron = tron;
} else {
  if (!console.tron) console.tron = console;
}

export default console.tron;
