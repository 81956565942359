import React from "react";

import Container from "~/components/Container";
import AppHeader from "~/components/AppHeader";
import AppFooter from "~/components/AppFooter";

import FolderBody from "./components/FolderBody";

const Folder = props => {
  return (
    <Container>
      <AppHeader back />
      <FolderBody />
      <AppFooter />
    </Container>
  );
};

export default Folder;
