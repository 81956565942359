import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Creators as FolderCreators } from "~/store/ducks/folder";

import Item from "./components/Item";
import Empty from "./components/Empty";

import Waiting from "~/components/Waiting";

import { Content, List, LoadigContainer, ErrorDiv } from "./style";

const ShowList = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(state => state.folder);

  const { shareToken } = useParams();

  const loadMore = useCallback(() => {
    dispatch(FolderCreators.folderRequest({ shareToken }));
  }, [dispatch, shareToken]);

  if (error)
    return (
      <Content>
        <ErrorDiv>Algo inesperado aconteceu =(</ErrorDiv>
      </Content>
    );

  if (!data)
    return (
      <Content>
        <Waiting size={"small"} />
      </Content>
    );

  const currentList = data && data.sinais.edges.length;

  if (!currentList && !loading)
    return (
      <Content>
        <Empty />
      </Content>
    );

  return (
    <>
      {!loading && (
        <Content>
          <List
            loadMore={loadMore}
            hasMore={data.sinais.pageInfo.hasNextPage}
            loader={
              <LoadigContainer>
                <Waiting size={"medium"} />
              </LoadigContainer>
            }
          >
            {data.sinais.edges.map(item => (
              <Item item={item.node} key={`${item.node._id}`} />
            ))}
          </List>
        </Content>
      )}
    </>
  );
};

/**
 * Componente interno
 */
// const Item = props => {
//   const [modal, setModal] = React.useState(null);

//   const { item } = props;

//   return (
//     <FolderItem>
//       <ModalShare isOpen={modal == "share"} close={() => setModal(null)} />
//       <ModalEdit isOpen={modal == "edit"} close={() => setModal(null)} />
//       <ModalDelete isOpen={modal == "delete"} close={() => setModal(null)} />
//       <FolderBox row>
//         <ShowIcon name={"folder"} font={"MaterialCommunityIcons"} />
//         <FolderBox>
//           <FolderTitle>{item.title}</FolderTitle>
//           <FolderDesc>
//             {item.total ? `${item.total} Verbetes` : "Vazio"}
//           </FolderDesc>
//         </FolderBox>
//       </FolderBox>
//       <FolderBox row>
//         <MenuMobile setModal={setModal} />
//         <MenuWeb setModal={setModal} />
//       </FolderBox>
//     </FolderItem>
//   );
// };

/**
 * Componente interno
 */
// const MenuMobile = () => {
//   const id = Math.floor(Math.random() * 1000);

//   return (
//     <MediaQuery maxWidth={MIN_TAB - 1}>
//       {/* <ShowIcon name={"ellipsis-v"} font={"FontAwesome"} action={() => {}} /> */}
//       {/* <DropDown options={options} defaultValue={1} action={() => {}} /> */}
//       <label for={String(id)}>
//         <ShowIcon name={"ellipsis-v"} font={"FontAwesome"} action={() => {}} />
//       </label>

//       <select id={String(id)} style={{ display: "none" }}>
//         <option value="volvo">Volvo</option>
//         <option value="saab">Saab</option>
//         <option value="mercedes">Mercedes</option>
//         <option value="audi">Audi</option>
//       </select>
//     </MediaQuery>
//   );
// };

/**
 * Componente interno
 */
// const MenuWeb = ({ setModal }) => {
//   return (
//     <MediaQuery minWidth={MIN_TAB}>
//       <ShowIcon
//         name={"eye"}
//         font={"AntDesign"}
//         action={() => {
//           console.tron.log("NAVEGANDO");
//         }}
//       />
//       <ShowIcon
//         name={"pencil"}
//         font={"MaterialCommunityIcons"}
//         action={() => setModal("edit")}
//       />
//       <ShowIcon
//         name={"trash"}
//         font={"Foundation"}
//         action={() => setModal("delete")}
//       />
//       <ShowIcon
//         name={"share-variant"}
//         font={"MaterialCommunityIcons"}
//         action={() => setModal("share")}
//       />
//     </MediaQuery>
//   );
// };

/**
 * Componente interno
 * Needs params: (name, font, action)
 */
// const ShowIcon = ({ name, font, action }) => {
//   const [hover, set] = React.useState(false);

//   return (
//     <Center
//       onMouseEnter={() => set(true)}
//       onMouseLeave={() => set(false)}
//       onClick={action}
//     >
//       <FolderIconAll name={name} font={font} hover={hover} />
//     </Center>
//   );
// };

export default ShowList;
