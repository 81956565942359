import React, { Component } from "react";

import Search from "./components/Search";

import iconHandColored from "~/assets/image/icon-hand-colored.png";
import downloadGooglePlay from "~/assets/image/download_google_play-pt.png";
import downloadAppStore from "~/assets/image/download_app_store-pt.png";

import {
  Content,
  SearchBox,
  SearchText,
  DownloadBox,
  DownloadOr,
  Or,
  Logo,
  DownloadText,
  DownloadOptions,
  DownloadLink,
  DownloadImg,
} from "./style";
let GooglePlay =
  "https://play.google.com/store/apps/details?id=com.senailibras&hl=pt_BR";
let AppStore = "https://apps.apple.com/br/app/senai-libras/id1480431650";

class Finishing extends Component {
  render() {
    return (
      <Content id="search">
        <DownloadBox>
          <DownloadText>Baixe nosso App</DownloadText>
          <DownloadOptions>
            <DownloadLink href={GooglePlay} target="_blank">
              <DownloadImg src={downloadGooglePlay} />
            </DownloadLink>
            <DownloadLink href={AppStore} target="_blank">
              <DownloadImg src={downloadAppStore} />
            </DownloadLink>
          </DownloadOptions>
        </DownloadBox>
      </Content>
    );
  }
}

export default Finishing;
