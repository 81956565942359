import styled from "styled-components";
import { fonts, colors } from "~/styles";
import { Form } from "@unform/web";

import Input from "~/components/Input";

export const FormContainer = styled(Form)`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleBox = styled.div`
  margin: 10px 0 20px;
  padding: 0 0 20px;
  border-bottom: 1px solid #eaeaea;
`;

export const Title = styled.h5`
  color: #4a7aff;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 20px;
`;

export const BodyBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  max-height: 50px;
  border: 1px solid ${colors.borderInput};
`;

export const InputLabel = styled.span`
  color: #889baf;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 13px;
  padding: 2px;
`;

export const InputEdit = styled.input`
  color: #889baf;
  border: 1px solid #d9dbed;
  border-radius: 8px;
  height: 40px;
  padding: 0 10px;
`;

export const FormInput = styled(Input)`
  flex: 1;
  margin: 10px 0;
  color: ${colors.subTitle};
  border: none;
  background-color: transparent;
  font-size: 14px;
  height: 40px;

  ::placeholder {
    color: ${colors.subTitle};
    font-family: ${fonts.VisbyRoundMedium};
  }
`;

export const FooterBox = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
`;

export const FooterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  margin-left: 5px;

  width: 110px;
  height: 40px;

  color: ${props => (props.warn ? "#4663E3" : "white")};
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  background-color: ${props => (props.warn ? "#DBE5FF" : "#4663E3")};
  border-radius: 10px;
`;
