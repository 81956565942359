import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { doLogout } from "~/constants/loginFunctions";

import MediaQuery from "react-responsive";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MIN_WEB, MIN_TAB } from "~/constants/config";
import { Creators as SearchCreators } from "~/store/ducks/search";
import ModalCreditos from "~/components/ModalCreditos";
import HOCModal from "~/components/HOCModal";

import {
  Container,
  UserAvatar,
  Content,
  Profile,
  OptionsList,
  Icons,
  ButtonCredit,
  ButtonLogout,
} from "./styles";

import defaultAvatar from "~/assets/image/default_avatar.png";
import { Link } from "react-router-dom";

function Avatar({ profile }) {
  const [visible, setVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();

  const openOrCloseModal = () => {
    setIsOpen(!isOpen);
  };

  const ResizeModal = ({ modalWidth, modalHeigth }) => {
    return (
      <HOCModal
        isOpen={isOpen}
        close={openOrCloseModal}
        modalWidth={modalWidth}
        modalHeigth={modalHeigth}
      >
        <ModalCreditos close={openOrCloseModal} />
      </HOCModal>
    );
  };

  function handleVisible() {
    setVisible(!visible);
  }

  async function logout() {
    await doLogout(profile.provider, profile.token);
    history.push("/");
  }

  return (
    <Container>
      <MediaQuery minWidth={MIN_WEB}>
        <ResizeModal modalWidth={"60%"} modalHeigth={"70%"} />
      </MediaQuery>
      <MediaQuery minWidth={MIN_TAB} maxWidth={MIN_WEB}>
        <ResizeModal modalWidth={"80%"} modalHeigth={"70%"} />
      </MediaQuery>
      <MediaQuery maxWidth={MIN_TAB}>
        <ResizeModal modalWidth={"95%"} modalHeigth={"80%"} />
      </MediaQuery>

      <UserAvatar onClick={handleVisible}>
        <img
          src={profile.avatar !== "local" ? profile.avatar : defaultAvatar}
          alt={`Clique para abrir o perfil de ${profile.name}`}
        />
      </UserAvatar>

      <Content visible={visible}>
        <Profile>
          <img
            src={profile.avatar !== "local" ? profile.avatar : defaultAvatar}
            alt={`Foto de perfil de ${profile.name}`}
          />
          <div>
            <strong>{profile.name}</strong>
            <span>{profile.email}</span>
          </div>
        </Profile>

        <OptionsList>
          <div>
            <Icons name="history" />
            <Link to="/historico">Histórico</Link>
          </div>
          <div>
            <Icons name="thumbs-up" />
            <Link to="/sugerir-verbete">Sugerir novos verbetes</Link>
          </div>
          <div>
            <Icons name="folder" />
            <Link to="/biblioteca">Biblioteca</Link>
          </div>
        </OptionsList>

        <OptionsList>
          <div>
            <ButtonCredit
              aria-label={"Abrir Créditos"}
              onClick={openOrCloseModal}
            >
              Créditos
            </ButtonCredit>
          </div>
        </OptionsList>

        <ButtonLogout onClick={() => logout()}>Sair</ButtonLogout>
      </Content>
    </Container>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearch: SearchCreators.setSearch,
      searchRequest: SearchCreators.searchRequest,
    },
    dispatch
  );

export default connect(mapDispatchToProps)(Avatar);
