import styled from "styled-components";
import { FontAwesome, MaterialCommunityIcons } from "react-web-vector-icons";
import { colors } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  flex-direction: column;
  font-family: "VisbyRoundCF-Medium";
  z-index: 1000;
`;

export const CloseIcon = styled(MaterialCommunityIcons).attrs(props => ({
  name: "close",
  size: 30,
  color: colors.button
}))`
  padding: 5px;
`;

export const Close = styled.button`
  color: ${colors.button};
  font-size: 20px;
  justify-content: center;
  font-weight: bold;
`;

export const Title = styled.h1`
  color: ${colors.button};
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
`;

export const Divisor = styled.div`
  border: 0.5px solid ${colors.borderInput};
  width: 99%;
`;

export const TitleDiv = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
`;

export const CopyButton = styled.button`
  justify-content: flex-end;
  margin-left: 5%;
  color: ${colors.button};
`;

export const LinkUrl = styled.input`
  font-size: 15px;
  border: none;
  width: 80%;
  color: ${colors.itemCardName};
  text-align: center;
`;

export const LinkDiv = styled.div`
  display: flex;
  width: ${props => (props.mobile ? "100%" : "90%")};
  flex-direction: row;
  justify-content: center;
  border: 1px solid ${colors.borderInput};
  border-radius: 5px;
  background-color: #fafafa;
  padding: 10px;
  margin: 10px 0;
`;

export const CopiedIcon = styled(FontAwesome).attrs(props => ({
  name: "check",
  size: 15,
  color: colors.primary
}))`
  padding: 5px;
`;
