export default {
  transparent: "transparent",
  darkTransparent: "rgba(0, 0, 0, 0.6)",
  whiteTransparent: "rgba(255, 255, 255, 0.5)",

  primary: "#4B78FB",
  secundary: "#4B78FB",
  tertiary: "#FFCE00",

  white_label: "#FFFFFF",
  black_label: "#484848",
  gray: "#818A91",
  gray_faint: "#CCCCCC",
  success: "#3CA744",
  failure: "#D54747",

  headerBackground: "#4B78FB",
  backgroundSignal: "#4B78FB",
  button: "#4B78FB",
  listBackground: "#FBFAFD",
  borderInput: "#D9DBED",
  borderForm: "#D9DBED88",
  icons: "#4A7AFF",
  subTitle: "#889BAF",
  itemCardName: "#34495E",
  internalTitle: "#34495E",
  backgroundLightBlue: "#DBE5FF",
  backgroundLightGray: "#F1F8FE",
};
