import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  padding: 10% 0;
  background: ${colors.white_label};
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 20% 0;
  }
`;

export const TitleBox = styled.div`
  text-align: center;
  max-width: 700px;
  margin: auto;
`;

export const Title = styled.h1`
  font-size: 50px;
  font-weight: bold;
  color: ${colors.primary};
  font-family: ${fonts.VisbyRoundMedium};

  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 45px;
  }
`;

export const AdvantageBox = styled.div`
  display: flex;
  justify-content: center;
  margin: 6% 2% 0;
  div:nth-child(2) {
    margin: 0 5%;
  }

  @media only screen and (max-width: ${MIN_TAB}px) {
    flex-direction: column-reverse;
    margin: 10% 2% 0;
    div:nth-child(2) {
      margin: 0 0 5%;
    }
  }
`;

export const AdvantageSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: ${MIN_TAB}px) {
    margin: 0 0 5%;
  }
`;

export const AdvantageImg = styled.img`
  width: 100%;
`;

export const AdvantageEllipse = styled.div`
  width: 100%;
  max-width: 300px;
  height: auto;
  background: ${colors.backgroundLightBlue};
  border-radius: 100%;
`;

export const AdvantageText = styled.div`
  max-width: 300px;
  text-align: center;
  h4 {
    font-size: 25px;
    color: ${colors.primary};
    font-family: ${fonts.VisbyRoundMedium};
    margin: 10px 0;
  }
  p {
    font-size: 18px;
    color: ${colors.itemCardName};
    font-family: ${fonts.VisbyRoundMedium};
  }
`;
