import styled from "styled-components";
import { AntDesign } from "react-web-vector-icons";

import { fonts } from "~/styles";

/**
 * Top of body
 */
export const Content = styled.div`
  display: flex;
  flex: 4;
  /* border: 1px solid red; */
  flex-direction: column;
  padding: 5px 0;
`;

export const NewFolderTitle = styled.h1`
  display: flex;
  align-items: center;
  padding: 20px 10px;
  /* border: 1px solid green; */

  color: #34495e;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 30px;
`;

export const NewFolderRow = styled.div`
  display: flex;
  height: 60px;

  align-items: center;
  padding: 0 10px;
`;

export const NewFolderButtom = styled.button`
  background-color: white;
  border: 1px solid #eaeaea;
  border-radius: 6px;
  box-shadow: 0px 1px 3px #00000029;
  width: 40px;
  height: 40px;

  :hover {
    background-color: #eaeaea;
  }
`;

export const NewFolderIcon = styled(AntDesign).attrs(props => ({
  name: "plus",
  size: 20,
  color: "#4A7AFF"
}))`
  padding: 5px;
  font-weight: bold;
`;

export const NewFolderLabel = styled.span`
  padding: 6px 15px;
  color: #34495e;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 16px;
  font-weight: bold;
`;
