import styled from "styled-components";
import { FontAwesome } from "react-web-vector-icons";
import ReactModal from "react-modal";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid white;
`;

export const ShowModal = styled(ReactModal).attrs(props => ({
  shouldCloseOnOverlayClick: true,
  style: {
    content: {
      width: "33%",
      height: "28%",
      marginTop: "16%",
      marginLeft: "30%",
      borderRadius: "20px",
      alignContent: "center"
    },
    overlay: {
      backgroundColor: "rgb(1,1,1,0.5)"
    }
  }
}))`
  width: 20%;
  height: 230px;
  position: absolute;
  background-color: red;
  top: 40%;
  left: 40%;
`;

export const ShareIcon = styled(FontAwesome).attrs(props => ({
  name: "share-alt",
  size: 25,
  color: "white"
}))`
  /* padding: 5px; */
`;

export const ShareButton = styled.button`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #ffce00 0% 0% no-repeat padding-box;

  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  position: fixed;
  z-index: 1000;
  top: 90%;
  right: 5%;
`;
export const ContainerApps = styled.div`
  align-self: center;
  width: 80%;
  height: 40px;
  background-color: red;
`;
