import { call, put } from "redux-saga/effects";

import { Creators as SugestaoCreators } from "~/store/ducks/sugestao";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

export function* sugestaoMutation(action) {
  const body = yield query.enviarSugestaoVerbete(action.payload);
  const response = yield call(graphql, body);

  const {
    status,
    data: { data }
  } = response;

  if (status === 200) {
    if (data.createSugestao) {
      yield put(SugestaoCreators.sugestaoSuccess(data.createSugestao));
    } else {
      const error = "Erro ao gravar dados";
      yield put(SugestaoCreators.sugestaoError({ error }));
    }
  } else {
    const error = "Algo inesperado aconteceu";
    yield put(SugestaoCreators.sugestaoError({ error }));
  }
}
