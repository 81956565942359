import React, { Component } from "react";
import MediaQuery from "react-responsive";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { debounce } from "lodash";

import { MIN_WEB, MIN_TAB } from "~/constants/config";
import { Creators as SearchCreators } from "~/store/ducks/search";
import ModalTechnologies from "~/components/ModalTechnologies";
import HOCModal from "~/components/HOCModal";

import {
  Content,
  SearchBox,
  SearchIcon,
  SearchInput,
  FilterBox,
  FilterIcon,
} from "./style";

class Search extends Component {
  state = {
    isOpen: false,
  };

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  requestDebounce = debounce(() => {
    this.props.searchRequest(this.props.search);
  }, 400);

  handleSearch = (e) => {
    this.props.setSearch(e.target.value);
    this.requestDebounce();
  };

  ResizeModal = ({ modalWidth, modalHeigth }) => {
    const { isOpen } = this.state;

    return (
      <HOCModal
        isOpen={isOpen}
        close={this.closeModal}
        modalWidth={modalWidth}
        modalHeigth={modalHeigth}
      >
        <ModalTechnologies close={this.closeModal} />
      </HOCModal>
    );
  };

  render() {
    const { ResizeModal } = this;
    const { search } = this.props;

    this.requestDebounce();

    return (
      <Content>
        <MediaQuery minWidth={MIN_WEB}>
          <ResizeModal modalWidth={"60%"} modalHeigth={"70%"} />
        </MediaQuery>
        <MediaQuery minWidth={MIN_TAB} maxWidth={MIN_WEB}>
          <ResizeModal modalWidth={"80%"} modalHeigth={"70%"} />
        </MediaQuery>
        <MediaQuery maxWidth={MIN_TAB}>
          <ResizeModal modalWidth={"95%"} modalHeigth={"80%"} />
        </MediaQuery>
        <SearchBox>
          <SearchIcon />
          <SearchInput value={search} onChange={this.handleSearch} />
          <FilterBox
            aria-label={"Abrir filtro por area tecnológica"}
            onClick={() => this.setState({ isOpen: true })}
          >
            <FilterIcon />
            <MediaQuery minWidth={MIN_TAB}>FILTRAR</MediaQuery>
          </FilterBox>
        </SearchBox>
      </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  search: state.search.search,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSearch: SearchCreators.setSearch,
      searchRequest: SearchCreators.searchRequest,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Search);
