import React, { Component } from "react";
import { isMobile, isIOS } from "react-device-detect";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Creators as SignalCreators } from "~/store/ducks/signal";

import Container from "~/components/Container";
import Waiting from "~/components/Waiting";
import Error404 from "~/components/Error404";
import AppFooter from "~/components/AppFooter";

import SignalHeader from "./components/SignalHeader";
import SignalBody from "./components/SignalBody";

import { IFrameRedirect } from "./style";

class Signal extends Component {
  componentDidMount() {
    const { match, signalRequest } = this.props;
    const { id } = match.params;
    signalRequest(id);
  }

  Main = props => {
    const { loading, error } = this.props.signal;
    if (loading) return <Waiting />;
    if (error) return <Error404 />;
    return <SignalBody />;
  };

  RedirectMobile = () => {
    if (isMobile) {
      const { id } = this.props.match.params;
      if (!id) return null;
      const prefix = isIOS ? "senailibras://" : "senailibras://senailibras/";
      return <IFrameRedirect src={`${prefix}redirect/${id}`} />;
    }
    return null;
  };

  render() {
    const { Main, RedirectMobile } = this;

    return (
      <Container>
        <SignalHeader />
        <RedirectMobile />
        <Main />
        <AppFooter />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  signal: state.signal
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      signalRequest: SignalCreators.signalRequest
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Signal);
