import React, { useState } from "react";

import { Content, Title, TitleLists, SubTitleLists, BoxLists, NameLists, Lists} from "./style";

function Body() {
  const [creditos, getCreditos] = useState([
    {
      tittle: "SENAI AP",
      deifiniçao: "Centro de Formação Profissional Macapá",
      areaAtuante: "Alimentos e Bebidas",
      pessoas: [
        {
          id: 1,
          nome: "Ana Paula Rodrigues de Moraes",
          func: "Interlocutora do Programa SENAI de Ações Inclusiva",
        },
        {
          id: 2,
          nome: "Caio Mendes Pedrozo ",
          func: "Intérprete de Libras",
        },
        {
          id: 3,
          nome: "Lucivan Souza Fernandes ",
          func: "Intérprete de Libras",
        },
      ],
    },
    {
      tittle: "SENAI GO",
      deifiniçao: "Faculdade SENAI Ítalo Bologna ",
      areaAtuante: "cursos de Vestuário e Elétrica",
      pessoas: [
        {
          id: 1,
          nome: "Weysller Matuzinhos ",
          func: "Gerente de Educação Profissional do SENAI Goiás",
        },
        {
          id: 2,
          nome: "Fernanda Marques de Oliveira ",
          func: "responsável pelo Projeto & Analista de Educação Profissional",
        },
        {
          id: 3,
          nome: "Dario Queija de Siqueira ",
          func: "Diretor da Faculdade Senai Ítalo Bologna",
        },

        {
          id: 4,
          nome: "Everton Divino Fernandes Paulino ",
          func: "Supervisão de Educação e Tecnologia",
        },

        {
          id: 5,
          nome: "Julia Maria Gonçalves Cardoso ",
          func: "Coordenação Pedagógica",
        },

        {
          id: 6,
          nome: "Vânia de Souza Santos  ",
          func: "Coordenação Pedagógica",
        },
        {
          id: 7,
          nome: "Alessandra Miranda Fidélis   ",
          func: "Analista de Educação",
        },
        {
          id: 8,
          nome: "Lorimar Souza Fernandes ",
          func: "surdo e Técnico em Eletrotécnica",
        },
        {
          id: 9,
          nome: "Cleiton Abadia Duarte ",
          func: "Coordenador Técnico EAD",
        },
        {
          id: 10,
          nome: "Eva Carolina Sousa Melo Eustáquio ",
          func: "Técnico em Informática",
        },
        {
          id: 11,
          nome: "Gabriel Gonçalves Brandão ",
          func: "Estagiário do Núcleo Integrado de Educação à Distância",
        },
        {
          id: 12,
          nome: "Micheuri da Silva Teles ",
          func: "Assistente de Coordenação Técnico e Pedagógica",
        },
      ],
    },
    {
      tittle: "SENAI SC",
      deifiniçao: "Unidade de São José ",
      areaAtuante:
        "Validação e Gravações TI, Alimentos e Bebidas e Metalmecânica",
      pessoas: [
        {
          id: 1,
          nome: "Fadia Pereira Nör ",
          func: "Intéprete de Libras e revisora do verbetes",
        },
        {
          id: 2,
          nome: "Kailani Santos da Silva ",
          func: "Intéprete de Libras",
        },
        {
          id: 3,
          nome: "Júlia Stolf da Silva  ",
          func: "Intéprete de Libras",
        },

        {
          id: 4,
          nome: "Emeline Camila Ceolin Grotto ",
          func: "Intéprete de Libras",
        },
      ],
    },
    {
      tittle: "SENAI SC",
      deifiniçao: "Tubarão ",
      areaAtuante: "Equipe de desenvolvimento e mídias",
      pessoas: [
        {
          id: 1,
          nome: "Caira de Abreu de Souza ",
          func: "Modelagem 3D",
        },
        {
          id: 2,
          nome: "Elias Mendes ",
          func: "Gestor de Projetos",
        },
        {
          id: 3,
          nome: "Fernando Pitt ",
          func: "Coordenador de Educação e Tecnologia",
        },

        {
          id: 4,
          nome: "Luis Felipe Mattos ",
          func: "Programador",
        },
        {
          id: 5,
          nome: "Isabelle Brandelero Porto ",
          func: "Modelagem 3D",
        },
        {
          id: 6,
          nome: "João Vitor Resende ",
          func: "Modelagem 3D",
        },
        {
          id: 6,
          nome: "Jhonatas Mathias Maurilio ",
          func: "Especialista UX/UI",
        },
        {
          id: 7,
          nome: "Marcos Roberto Cordeiro  ",
          func: "Programador",
        },

        {
          id: 8,
          nome: "Mateus Scheper Mendes  ",
          func: "Revisão e publicações",
        },
        {
          id: 9,
          nome: "Robson De Faveri  ",
          func: "Auditor de Qualidade",
        },
        {
          id: 10,
          nome: "Sandro Costa da Rosa  ",
          func: "Analista de Gestão",
        },
      ],
    },
    {
      tittle: "SENAI SC (SEDE)",
      deifiniçao: "Florianópolis",
      areaAtuante: "Apoio",
      pessoas: [
        {
          id: 1,
          nome: "Luciano de Amorim Amorim ",
          func: "representando a comunidade surda",
        },
        {
          id: 2,
          nome: "Rosana Baron Zimmer Mendes ",
          func:
            "Interlocução Estadual de SC do programa SESI/SENAI de Ações Inclusiva  e validação da qualidade do projeto",
        },
      ],
    },
    {
      tittle: "SENAI ES (SEDE)",
      deifiniçao: "Vitória",
      areaAtuante: "Apoio",
      pessoas:  [
        {
          id: 1,
          nome: "Márcia Cristina Pereira Fortuna",
          func: "Coordenadora pedagógica"
        }
      ]
    }
  ]);

  return (
    <Content>
      <Title>Agradecimentos Especiais</Title>
      <Lists>
        {creditos.map((credito) => (
          <div>
            <TitleLists>{credito.tittle}</TitleLists>
            <SubTitleLists>{credito.deifiniçao}</SubTitleLists>
            <SubTitleLists>({credito.areaAtuante})</SubTitleLists>

            {credito.pessoas.map((pessoa) => (
              <BoxLists>
                <NameLists>{pessoa.nome}</NameLists>
                <SubTitleLists>{pessoa.func}</SubTitleLists>
              </BoxLists>
            ))}
          </div>
        ))}
      </Lists>
    </Content>
  );
}

export default Body;
