import styled from "styled-components";
import { FontAwesome } from "react-web-vector-icons";
import { Link } from "react-router-dom";

import { colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
  justify-content: center;
`;

export const Socials = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icon = styled(FontAwesome).attrs((props) => ({
  name: props.icon || "user",
  color: props.hover ? colors.backgroundSignal : "white",
  size: 20,
}))`
  padding: 5px;
`;

export const Cicle = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${colors.borderInput};
  }
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
`;

export const Privacity = styled(Link)`
  color: white;
  padding: 5px;
  font-size: 13px;
`;
