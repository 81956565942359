import React, { useRef } from "react";
import * as Yup from "yup";

import { doRegister } from "~/constants/loginFunctions";
import {
  ButtonArea,
  ButtonOutline,
  InputBorder,
  FormInput,
  Form,
} from "./style";

function RegisterForm({ setForm }) {
  const formRef = useRef(null);

  async function handleSubmit(data) {
    try {
      resetErros();

      const schema = Yup.object().shape({
        name: Yup.string()
          .matches(/(.+) (.+)/, {
            message: "Insira nome completo",
            excludeEmptyString: false,
          })
          .required("O nome é obrigatório"),
        email: Yup.string()
          .email("Insira um e-mail válido")
          .required("O e-mail é obrigatório"),
        password: Yup.string()
          .min(8, "No mínimo 8 caracteres")
          .required("A senha é obrigatória"),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Senhas não coincidem"
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      doRegister(setForm);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function resetErros() {
    formRef.current.setErrors({});
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit}>
      <InputBorder>
        <FormInput
          aria-label={"Inserir seu nome completo"}
          type="text"
          name="name"
          placeholder="Nome completo"
          onFocus={resetErros}
        />
      </InputBorder>
      <InputBorder>
        <FormInput
          aria-label={"Inserir seu e-mail"}
          type="text"
          name="email"
          placeholder="E-mail"
          onFocus={resetErros}
        />
      </InputBorder>
      <InputBorder>
        <FormInput
          aria-label={"Inserir sua nova senha"}
          type="password"
          name="password"
          placeholder="Senha"
          onFocus={resetErros}
        />
      </InputBorder>
      <InputBorder>
        <FormInput
          aria-label={"Inserir sua nova senha novamente"}
          type="password"
          name="confirmPassword"
          placeholder="Confirmar Senha"
          onFocus={resetErros}
        />
      </InputBorder>
      <ButtonArea>
        <div></div>
        <ButtonOutline id="registerButton" type="submit">
          Enviar
        </ButtonOutline>
      </ButtonArea>
    </Form>
  );
}

export default RegisterForm;
