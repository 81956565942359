import React from "react";
import Gallery from "react-grid-gallery";

import { CDN } from "~/constants/config";

import { Container } from "./style";

const ItemGallery = ({ signal }) => {
  const { edges } = signal.arquivos;

  const images = [];
  edges.forEach((item) => {
    const src = `${CDN}${item.node.caminho}`;
    images.push({
      src,
      thumbnail: src,
      thumbnailWidth: 1080,
      thumbnailHeight: 1920,
    });
  });

  return (
    <Container>
      <Gallery
        images={images}
        enableImageSelection={false}
        imageCountSeparator={" de "}
        margin={6}
        tileViewportStyle={() => ({
          backgroundColor: "white",
          height: 1920 / 6,
          width: 1080 / 6,
        })}
        thumbnailStyle={() => ({
          width: "100%",
          borderRadius: 18,
        })}
      />
    </Container>
  );
};

export default ItemGallery;
