import styled from "styled-components";

import { colors, fonts } from "~/styles";

import { FontAwesome } from "react-web-vector-icons";

export const Container = styled.div`
  background-color: #a0a0a0;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Modal = styled.div`
  display: flex;
  border-radius: 20px;
  shadow-color: ${colors.primary};
  shadow-opacity: 0.23;
  shadow-radius: 2.62px;
  elevation: 3;
  height: 260px;
  width: 96%;
  padding: 10px 26px;
  background: white;
  flex-direction: column;
  max-width: 500px;
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Close = styled.span`
  color: ${colors.button};
  font-size: 42px;
  text-align: right;
  cursor: pointer;
`;

export const Icon = styled(FontAwesome).attrs(props => ({
  color: props.color,
  name: props.name,
  size: 82
}))`
  padding: 0 5px;
`;

export const Title = styled.span`
  color: ${colors.button};
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 20px;
  padding: 6px;
  text-align: center;
`;

export const Text = styled.span`
  color: ${colors.subTitle};
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 17px;
  padding: 0 6px;
  text-align: center;
`;

export const Body = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
