import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as HistoryCreators } from "~/store/ducks/history";

import Waiting from "~/components/Waiting";
import { Container, Content, NomeGrupo } from "./style";

import Empty from "../Empty";
import Item from "../Item";

export default function List({ history }) {
  const dispatch = useDispatch();
  const { data, error } = useSelector(state => state.history);

  const requestMore = useCallback(
    () => dispatch(HistoryCreators.historyRequest()),
    [dispatch]
  );

  useEffect(() => {
    dispatch(HistoryCreators.historyRefresh());
    requestMore();
  }, [dispatch, requestMore]);

  function Group(edges) {
    return data.edges.map((item, index, array) => (
      <>
        {index === 0 ||
        item.formattedDate !== array[index - 1].formattedDate ? (
          <>
            <NomeGrupo>{item.formattedDate}</NomeGrupo>
            <Item key={index} signal={item} />
          </>
        ) : (
          <Item key={index} signal={item} />
        )}
      </>
    ));
  }

  if (error) return <div>Algo inesperado aconteceu =(</div>;
  if (!data) return <Waiting size={"small"} />;

  return (
    <Container>
      <h1>Histórico</h1>
      {data.edges ? (
        <Content
          loadMore={requestMore}
          hasMore={data.pageInfo && data.pageInfo.hasNextPage}
          loader={<Waiting size={"small"} />}
        >
          <Group />
        </Content>
      ) : (
        <Empty />
      )}
    </Container>
  );
}
