import { all, takeLatest } from "redux-saga/effects";

import { DashboardTypes } from "~/constants/types";
import { SearchTypes } from "~/constants/types";
import { SignalTypes } from "~/constants/types";
import { TechTypes } from "~/constants/types";
import { HistoryTypes } from "~/constants/types";
import { LetterTypes } from "~/constants/types";
import { SugestaoTypes } from "~/constants/types";
import { LibraryTypes } from "~/constants/types";
import { FolderTypes } from "~/constants/types";

import * as HistorySaga from "./history";
import * as DashboardSaga from "./dashboard";
import * as SignalSaga from "./signal";
import * as SearchSaga from "./search";
import * as TechSaga from "./technologies";
import * as LetterSaga from "./letter";
import * as LibrarySaga from "./library";
import * as FolderSaga from "./folder";
import { sugestaoMutation } from "./sugestao";

export default function* rootSaga() {
  const list = [];

  list.push(takeLatest(DashboardTypes.REQUEST, DashboardSaga.dashboardRequest));
  // list.push(takeLatest(DashboardTypes.REQUESTMORE, dashboardRequestMore));

  list.push(takeLatest(SignalTypes.REQUEST, SignalSaga.signalRequest));
  list.push(takeLatest(SugestaoTypes.MUTATION, sugestaoMutation));

  list.push(takeLatest(HistoryTypes.CREATE, HistorySaga.historyCreateItem));
  list.push(takeLatest(HistoryTypes.REQUEST, HistorySaga.historyRequest));

  list.push(takeLatest(SearchTypes.SET, SearchSaga.searchSet));
  list.push(takeLatest(SearchTypes.REQUEST, SearchSaga.searchRequest));

  list.push(takeLatest(TechTypes.LOAD, TechSaga.technologiesLoad));
  list.push(takeLatest(TechTypes.SELECTED, TechSaga.technologySet));
  list.push(takeLatest(TechTypes.REQUEST, TechSaga.technologyRequest));

  list.push(takeLatest(LetterTypes.LOADING, LetterSaga.letterLoading));
  list.push(takeLatest(LetterTypes.REQUEST, LetterSaga.letterRequest));

  list.push(takeLatest(LibraryTypes.CREATING, LibrarySaga.folderCreate));
  list.push(takeLatest(LibraryTypes.REQUEST, LibrarySaga.libraryRequest));
  list.push(takeLatest(LibraryTypes.EDITING_REQUEST, LibrarySaga.folderEdit));
  list.push(takeLatest(LibraryTypes.DELETE_REQUEST, LibrarySaga.folderDelete));
  list.push(takeLatest(LibraryTypes.ADD_SIGNAL, LibrarySaga.folderAddSinal));

  list.push(takeLatest(FolderTypes.REQUEST, FolderSaga.folderRequest));
  list.push(
    takeLatest(FolderTypes.DELETE_REQUEST, FolderSaga.folderDeleteRequest)
  );

  yield all([...list]);
}
