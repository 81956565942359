import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 15;
`;

export const List = styled(InfiniteScroll)`
  list-style: none;

  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-auto-rows: 140px;
  padding: 10px;
  /* border: 1px solid red; */

  @media only screen and (min-width: ${MIN_TAB}px) {
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-auto-rows: 180px;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-auto-rows: 240px;
  }
`;

export const LoadigContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;
