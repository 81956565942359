import React, { Component } from "react";
import MediaQuery from "react-responsive";

import { MIN_TAB, MIN_WEB } from "~/constants/config";

import ModalShare from "~/components/ModalShare";

import { Container, ShareButton, ShareIcon } from "./style";

class ButtonShare extends Component {
  state = {
    show: false
  };

  toogleModal = async () => {
    await this.setState({ show: !this.state.show });
  };

  GetModal = props => {
    const { show } = this.state;
    const url = window.location.href.replace("signal", "redirect");

    return (
      <ModalShare
        show={show}
        close={this.toogleModal}
        width={650}
        height={300}
        url={url}
        {...props}
      />
    );
  };

  render() {
    const { GetModal } = this;

    return (
      <Container>
        <ShareButton
          aria-label={"abrir Compartilhamento de verbete"}
          onClick={() => this.toogleModal()}
        >
          <ShareIcon />
        </ShareButton>

        <MediaQuery minWidth={MIN_WEB}>
          <GetModal width={650} height={300} />
        </MediaQuery>

        <MediaQuery maxWidth={MIN_WEB} minWidth={MIN_TAB}>
          <GetModal width={500} height={300} />
        </MediaQuery>

        <MediaQuery maxWidth={MIN_TAB}>
          <GetModal width={320} height={280} mobile />
        </MediaQuery>
      </Container>
    );
  }
}

export default ButtonShare;
