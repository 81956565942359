import styled from "styled-components";
import { colors } from "~/styles";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

export const ButtonPlatform = styled.button`
  width: 195px;
  height: 82px;
  background: ${colors.white_label};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(props) => (props.selected ? colors.primary : "#EDEAF4")};
  border-radius: 10px;
  margin: 5px;
  @media only screen and (max-width: ${MIN_TAB}px) {
    width: -webkit-fill-available;
    height: 50px;
  }
`;
