export const ApplicationName = 'SENAI Libras';

export const PoliciesRoute = 'Política de Privacidade - ' + ApplicationName;
export const PoliciesDescription = 'Política de Privacidade APP SENAI LIBRAS. A finalidade dessa Política de Privacidade é descrever como o SENAI coleta, utiliza e compartilha informações particulares por meio do aplicativo para dispositivos móveis.';

export const DashboardRoute = 'Página Inicial - ' + ApplicationName;
export const DashboardDescription = 'Visualize os verbetes de termos técnicos disponíveis na página inicial.';

export const LoginRoute = 'Faça Login ou Cadastre-se - ' + ApplicationName;
export const LoginDescription = 'Faça login ou cadastre-se para ter acesso a mais funcionalidades do ' + ApplicationName;

export const ActivateAudioDescriptionRoute = 'Ativar Audiodescrição - ' + ApplicationName;
export const ActivateAudioDescription = 'Como ativar a função de audiodescrição em seu navegador ou dispositivo móvel.';

export const HistoryRoute = 'Meu Histórico - ' + ApplicationName;
export const HistoryDescription = 'Reveja através do meu histórico os verbetes buscados por você.';

export const LibraryRoute = 'Minha Biblioteca - ' + ApplicationName;
export const LibraryDescription = 'Salve os verbetes em minha biblioteca da forma que você quiser.';

export const SuggestNewVerbeteRoute = 'Sugerir Novo Verbete - ' + ApplicationName;
export const SuggestNewVerbeteDescription = 'Contribua sugerindo novos verbetes técnicos para que possamos expandir nosso glossário.';

export const VerbeteDefaultName = 'Verbete - ' + ApplicationName;

export const LandingPageRoute = ApplicationName;
export const LandingPageDescription = 'Glossário de termos técnicos para língua brasileira de sinais.';