import styled from "styled-components";
import { MaterialCommunityIcons } from "react-web-vector-icons";

import { colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;
`;

export const TitleRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  align-items: end;
`;

export const LogoTitle = styled.img`
  max-height: 56px;
`;

export const CloseButton = styled.button``;

export const CloseIcon = styled(MaterialCommunityIcons).attrs((props) => ({
  name: "close",
  size: 30,
  color: colors.white_label,
}))`
  padding: 5px;
`;
