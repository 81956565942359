import styled from "styled-components";

import { MIN_TAB, MIN_WEB } from "~/constants/config";
import { colors } from "~/styles";

export const Content = styled.div`
  background-color: ${colors.headerBackground};
  padding: 10px 0;
  z-index: 100;

  @media only screen and (min-width: ${MIN_TAB}px) {
    padding: 10px 10%;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    padding: 10px 20%;
  }
`;

export const Banner = styled.div`
  display: grid;
  grid-template: 1fr 1fr 1fr / 1fr;

  @media only screen and (min-width: ${MIN_TAB}px) {
    grid-template: 1fr 1fr / 1fr 1fr;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    grid-template: 1fr / 1fr 1fr 1fr;
  }
`;
