import { call, put, select } from "redux-saga/effects";
import { toast } from "react-toastify";

import { Creators as FolderCreators } from "~/store/ducks/folder";
import { Creators as LibraryCreators } from "~/store/ducks/library";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

export function* folderRequest({ payload }) {
  try {
    const { cursor } = yield select(state => ({
      cursor: state.folder.data && state.folder.data.sinais.pageInfo.endCursor
    }));

    const queryExecute = payload.folderId
      ? query.buscarSinaisPasta(payload.folderId, cursor)
      : query.buscarSinaisPublicPasta(payload.shareToken, cursor);

    const response = yield call(graphql, queryExecute);

    const { error, data } = response;

    if (error) {
      throw new { error: "@folder/graphql_request_error" }();
    }

    const dataRetrive = payload.folderId
      ? data.data.pasta
      : data.data.publicPasta;

    yield put(FolderCreators.folderSuccess(dataRetrive));
  } catch (error) {
    console.tron.log("Error saga", error.message);
    yield put(FolderCreators.folderFailure());
  }
}

export function* folderDeleteRequest({ payload }) {
  try {
    const response = yield call(graphql, query.deleteSinalPasta(payload));

    const { errors } = response;

    if (errors) {
      throw new { error: "@folder/graphql_delete_error" }();
    }

    const { data } = yield select(state => ({
      data: state.folder.data
    }));

    const edges = data.sinais.edges.filter(
      node => node.node._id !== payload.signalId
    );

    const totalCount = data.sinais.totalCount - 1;

    yield put(
      FolderCreators.folderDeleteSuccess({
        ...data,
        sinais: {
          ...data.sinais,
          totalCount,
          edges
        }
      })
    );
    yield put(LibraryCreators.libraryRefresh());
    yield put(LibraryCreators.libraryRequest());

    toast.success("Verbete removido com sucesso da pasta");
  } catch (error) {
    console.tron.log("Error saga", error.message);
    toast.error("Não foi possível remover o verbete da pasta");
    yield put(FolderCreators.folderFailure());
  }
}
