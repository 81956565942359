import React from "react";

import {
  Content,
  ColumnIcon,
  ColumnInfo,
  Icon,
  TextInfo,
  LinkContact
} from "./style";

const Card = props => {
  const card = infos[props.card];

  return (
    <Content>
      <ColumnIcon>
        <Icon icon={card.icon} />
      </ColumnIcon>
      <ColumnInfo>
        <TextInfo bold>{card.title}</TextInfo>
        <TextInfo small>{card.message}</TextInfo>
      </ColumnInfo>
    </Content>
  );
};

const infos = {
  sac: {
    icon: "phone",
    title: "SAC - Serviço de Atendimento ao Cliente",
    message: (
      <>
        (61) 3317 9989 <br /> (61) 3317 9992
      </>
    )
  },
  contact: {
    icon: "envelope",
    title: "Contatos",
    message: <LinkContact>Fale Conosco</LinkContact>
  },
  loc_sao_paulo: {
    icon: "map-marker",
    title: "Escritório São Paulo",
    message: (
      <>
        Rua Surubim, 504 - Brooklin Novo <br />
        São Paulo - SP - CEP 04571-050 <br />
        CNI - Tel. (11) 3040-3860 <br />
        IEL - Tel. (11) 3040-3370
      </>
    )
  }
};

export default Card;
