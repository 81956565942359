import React from "react";

import ChromeStep from "~/assets/image/audioDescription/chrome-step.png";

import { Content } from "./styles";

const ChromeSteps = () => (
  <Content>
    <ul>
      <li tabIndex={5}>
        <b>1 - </b>
        <a
          href="https://chrome.google.com/webstore/detail/chromevox-classic-extensi/kgejglhpjiefppelpmljglcjbhoiplfn?hl=pt-BR"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clique aqui
        </a>
        <span> para baixar a extensão em seu navegador</span>
        <img src={ChromeStep} alt="Extensão chrome" />
      </li>
      <li tabIndex={6}>
        <b>2 - </b>
        <span>Clique em Usar no Chrome para adicionar a extensão.</span>
      </li>
      <li tabIndex={7}>
        <b>3 - </b>
        <span>Depois de instalado já está pronto para usar.</span>
      </li>
      <li tabIndex={8}>
        <b>4 - </b>
        <span>
          Você pode ativá-lo ou desativá-lo acessando sua lista de extensões do
          Chrome ou digite na url “chrome://extensions”
        </span>
      </li>
    </ul>
  </Content>
);

export default ChromeSteps;
