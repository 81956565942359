import { DashboardTypes as Types } from "~/constants/types";
import immer from "immer";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false
};

export default function dashboard(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case Types.REQUEST:
      return { ...state, error: false, loading: true };

    case Types.SUCCESS:
      return immer(state, draft => {
        const oldList = state.data && state.data.edges;
        const newerList = payload.edges;
        const edges = oldList ? oldList.concat(newerList) : newerList;
        draft.data = { ...payload, edges };
        draft.loading = false;
      });

    case Types.FAILURE:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
}

export const Creators = {
  // dashboardRequestMore: payload => ({ type: Types.REQUESTMORE, payload }),
  dashboardRequest: payload => ({ type: Types.REQUEST, payload }),
  dashboardSuccess: payload => ({ type: Types.SUCCESS, payload }),
  dashboardFailure: payload => ({ type: Types.FAILURE, payload })
};
