import React, { Component } from "react";

import { Container, SearchButton, FilterIcon } from "./style";

class ButtonSearch extends Component {
  render() {
    return (
      <Container>
        <SearchButton href="#search">
          <FilterIcon />
        </SearchButton>
      </Container>
    );
  }
}

export default ButtonSearch;
