import React, { Component } from "react";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  FacebookIcon
} from "react-share";

import { Container, AppName, styles } from "./styles";

class SocialMedias extends Component {
  render() {
    const { url } = this.props;

    return (
      <Container>
        <TwitterShareButton
          aria-label={"Compartilhar com Twitter"}
          style={styles.margin}
          url={url}
        >
          <TwitterIcon size={80} borderRadius={50} />
          <AppName>Twitter</AppName>
        </TwitterShareButton>
        <FacebookShareButton
          aria-label={"Compartilhar com Facebook"}
          style={styles.margin}
          url={url}
        >
          <FacebookIcon size={80} borderRadius={50} />
          <AppName>Facebook</AppName>
        </FacebookShareButton>
        <WhatsappShareButton
          aria-label={"Compartilhar com Whatsapp"}
          style={styles.margin}
          url={url}
          windowWidth={720}
          windowHeight={610}
        >
          <WhatsappIcon size={80} borderRadius={50} />
          <AppName>Whatsapp</AppName>
        </WhatsappShareButton>
      </Container>
    );
  }
}

export default SocialMedias;
