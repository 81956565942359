import styled from "styled-components";

import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: auto;
  padding: 10px;
  background: ${colors.primary};
  margin: -20px;
`;
