import React from "react";
import { Helmet } from 'react-helmet';

import { Container, AppFooter, AppHeader } from "~/components";
import { ActivateAudioDescriptionRoute, ActivateAudioDescription } from '~/constants/routesName';

import Body from "./components/Body";

import { Content } from "./styles";

const AudioDescription = () => {
  return (
    <Container>
      <Helmet>
        <title>{ActivateAudioDescriptionRoute}</title>
        <meta name="description" content={ActivateAudioDescription}></meta>
      </Helmet>
      <AppHeader back />
      <Content>
        <div>
          <div>
            <h1>Audiodescrição</h1>
            <p>
              Como ativar a função de audiodescrição em seu navegador ou
              dispositivo móvel.
            </p>
          </div>
          <Body />
        </div>
      </Content>
      <AppFooter />
    </Container>
  );
};

export default AudioDescription;
