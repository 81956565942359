import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { darken } from "polished";

import { MaterialIcons } from "react-web-vector-icons";

import { fonts, colors } from "~/styles";

export const Container = styled.div`
  flex: 1;
  align-items: flex-start;
  height: 100%;
`;

export const ListFolders = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ItemContainer = styled.button`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: left;
  color: ${colors.subTitle};

  &:hover {
    color: ${darken(0.3, colors.subTitle)};
  }
`;

export const ItemText = styled.span`
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 18px;
  word-break: break-all;
  margin-left: 15px;
`;

export const ItemIcon = styled(MaterialIcons).attrs(props => ({
  name: "create-new-folder",
  color: props.hover ? darken(0.3, colors.primary) : colors.primary,
  size: 24
}))``;
