import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { isMobile, isAndroid } from "react-device-detect";

import Header from "./components/Header";
import ShowList from "./components/ShowList";

import { Creators as FolderCreators } from "~/store/ducks/folder";

import { Content, IFrameRedirect } from "./style";

const FolderBody = () => {
  const dispatch = useDispatch();

  const { shareToken } = useParams();

  useEffect(() => {
    dispatch(FolderCreators.folderRequest({ shareToken }));
  }, [dispatch, shareToken]);

  const RedirectMobile = () => {
    if (isMobile) {
      const prefix = isAndroid
        ? "senailibras://senailibras/"
        : "senailibras://";
      return <IFrameRedirect src={`${prefix}public-folder/${shareToken}`} />;
    }
    return null;
  };

  return (
    <Content>
      <RedirectMobile />
      <Header />
      <ShowList />
    </Content>
  );
};
export default FolderBody;
