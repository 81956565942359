import React, { useState } from "react";
import MediaQuery from "react-responsive";
import ReactPlayer from "react-player";

import { MIN_TAB } from "~/constants/config";

import { Container, Speed, SpeedBox } from "./style";

const ItemVideo = ({ signal }) => {
  const {
    arquivos: { edges }
  } = signal;

  const video = edges[0].node.caminho;

  const [speed, setSpeed] = useState(1.0);
  const [play, setPlay] = useState(false);
  const [showSpeedBox, setShowSpeedBox] = useState(false);

  const onEnd = () => {
    setShowSpeedBox(false);
    setPlay(false);
  };

  const toogleSpeedBox = () => setShowSpeedBox(!showSpeedBox);

  const handleShowSpeedBox = speed => {
    setShowSpeedBox(false);
    setSpeed(speed);
  };

  const ShowVideoPlayer = ({ width }) => (
    <ReactPlayer
      url={"https://api-senai-libras.azureedge.net" + video}
      // url={`${CDN}${video}`}
      playbackRate={speed}
      controls
      id="player"
      playing={play}
      onPlay={() => setPlay(true)}
      onEnded={onEnd}
      onPause={() => setPlay(false)}
      width={width}
      aria-label={`Demonstração do movimento de ${signal.titulo}`}
    />
  );

  const ShowControls = ({ mobile }) => {
    // if (play) return null;

    return (
      <>
        <Speed play={play} mobile={mobile} onClick={toogleSpeedBox}>
          {speed === 1.0 ? "Velocidade" : "Vel: " + speed}
        </Speed>
        <SpeedBox showSpeedBox={showSpeedBox} mobile={mobile}>
          <li onClick={() => handleShowSpeedBox(0.25)}>0.25</li>
          <li onClick={() => handleShowSpeedBox(0.5)}>0.5</li>
          <li onClick={() => handleShowSpeedBox(0.75)}>0.75</li>
          <li onClick={() => handleShowSpeedBox(1.0)}>Normal</li>
        </SpeedBox>
      </>
    );
  };

  return (
    <Container>
      <MediaQuery maxWidth={MIN_TAB}>
        <ShowVideoPlayer width={250} />
        <ShowControls mobile />
      </MediaQuery>
      <MediaQuery minWidth={MIN_TAB}>
        <ShowVideoPlayer width={560} />
        <ShowControls />
      </MediaQuery>
    </Container>
  );
};

export default ItemVideo;
