import { LoginTypes, LibraryTypes as Types } from "~/constants/types";
import immer from "immer";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  refreshing: false
};

export default function library(state = INITIAL_STATE, { type, payload }) {
  return immer(state, draft => {
    switch (type) {
      case Types.REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case Types.EDITING_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case Types.MODIFY_SUCCESS: {
        draft.loading = false;
        draft.data = payload;
        break;
      }
      case LoginTypes.DESTROY_SESSION: {
        draft.data = null;
        draft.loading = false;
        draft.error = false;
        draft.refreshing = false;
        break;
      }
      case Types.REFRESH: {
        draft.data = null;
        draft.refreshing = true;
        break;
      }
      case Types.SUCCESS: {
        const oldList = state.data && state.data.edges;
        const newerList = payload.edges;
        const edges = oldList ? oldList.concat(newerList) : newerList;

        draft.data = { ...payload, edges };
        draft.loading = false;
        draft.refreshing = false;
        break;
      }
      case Types.ADD_SIGNAL: {
        break;
      }
      case Types.FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  libraryCreating: payload => ({ type: Types.CREATING, payload }),
  libraryEditingRequest: payload => ({ type: Types.EDITING_REQUEST, payload }),
  libraryDeleteRequest: payload => ({ type: Types.DELETE_REQUEST, payload }),
  libraryModifySuccess: payload => ({ type: Types.MODIFY_SUCCESS, payload }),
  libraryRequest: payload => ({ type: Types.REQUEST, payload }),
  libraryRefresh: payload => ({ type: Types.REFRESH, payload }),
  librarySuccess: payload => ({ type: Types.SUCCESS, payload }),
  libraryAddSignal: payload => ({ type: Types.ADD_SIGNAL, payload }),
  libraryFailure: payload => ({ type: Types.FAILURE, payload })
};
