import styled from "styled-components";
import { MaterialCommunityIcons } from "react-web-vector-icons";

import { MIN_TAB, MIN_WEB } from "~/constants/config";

import { colors, fonts } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${colors.listBackground};
  /* border: 1px solid black; */

  @media only screen and (min-width: ${MIN_TAB}px) {
    padding: 0 10%;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    padding: 0 20%;
  }
`;

export const ShowInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 10px;
  border: 1px solid red;
`;

export const TechBox = styled.div`
  display: flex;
  align-items: center;
  background-color: ${props => props.color || colors.primary};
  padding: 5px 10px;
  border-radius: 5px;
`;

export const TechLabel = styled.span`
  color: white;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 15px;
`;

export const TechCloseButton = styled.button`
  padding: 5px;
`;

export const TechClose = styled(MaterialCommunityIcons).attrs(props => ({
  name: "close",
  size: 14,
  color: "white"
}))`
  padding: 5px;
`;

export const Info = styled.span`
  color: #889baf;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 15px;
`;
