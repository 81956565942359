import styled from "styled-components";
import { FontAwesome } from "react-web-vector-icons";

import { fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 5px 0;
`;

// 'map-marker'
export const Icon = styled(FontAwesome).attrs(props => ({
  name: props.icon || "phone",
  color: "white",
  size: 22
}))`
  padding: 5px;
`;

export const ColumnIcon = styled.div`
  display: flex;
  flex: 4;
  justify-content: center;
  padding: 5px 0;
`;

export const ColumnInfo = styled.div`
  flex: 20;
  padding: 0 5px;
`;

export const TextInfo = styled.p`
  padding: 5px;
  color: white;
  font-size: ${props => (props.small ? "13px" : "16px")};
  font-weight: ${props => (props.bold ? "bold" : "normal")};
  font-family: ${fonts.VisbyRoundMedium};
  /* padding: 0 5px; */
`;

export const LinkContact = styled.a.attrs(props => ({
  href: "https://www.portaldaindustria.com.br/senai/canais/transparencia/sac/",
  target:'_blank'
}))`
  text-decoration: none;
  color: white;
`;
