import styled from "styled-components";
import InfiniteScroll from "react-infinite-scroller";
import { darken } from "polished";

import Icon, { MaterialCommunityIcons } from "react-web-vector-icons";

import { fonts, colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  /* flex-direction: row; */
  flex: 15;
  /* border: 1px solid blue; */
`;

export const EmptyContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const FolderList = styled(InfiniteScroll)`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  margin: 10px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
`;

export const FolderItem = styled.li`
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 65px;
  padding: 0 20px;
  color: #34495e;
  border-bottom: 1px solid #eaeaea;
  word-break: break-all;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: ${darken(0.03, "#fff")};
  }
`;

export const Center = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

export const FolderIcon = styled(MaterialCommunityIcons).attrs((props) => ({
  name: "folder",
  size: 22,
  color: "#ffce00",
}))`
  padding: 5px;
`;

export const FolderIconAll = styled(Icon).attrs((props) => ({
  name: props.name,
  size: 22,
  color: props.hover ? "#cc9b00" : "#ffce00",
}))`
  padding: 5px;

  :hover {
    color: #cc9b00;
  }
`;

export const FolderBox = styled.div`
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  /* flex-direction: */

  &:hover {
    background: ${darken(0.03, "#fff")};
    cursor: pointer;
  }
`;

export const FolderTitle = styled.span`
  padding: 0 5px;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 16px;
`;

export const FolderDesc = styled.span`
  padding: 0 5px;
  color: #889baf;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 14px;
`;

export const LoadigContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const MenuMobileContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background: ${colors.listBackground};
  border-radius: 4px;
  box-shadow: 0px 3px 6px 4px #00000029;
  width: 150px;
  z-index: 2;
  right: -1px;
  top: 40px;

  &::before {
    content: "";
    position: absolute;
    right: 15px;
    top: -5px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid #ffffff;
  }
`;

export const ContainerMobile = styled.div`
  position: relative;
  padding: 10px;
`;

export const MobileItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 5px;

  div {
    span {
      font-family: ${fonts.VisbyRoundMedium};
      font-size: 14px;
      color: ${colors.internalTitle};
      font-weight: bold;
    }
  }
`;
