import React from "react";

import AndroidStep1 from "~/assets/image/audioDescription/android-step1.png";
import AndroidStep2 from "~/assets/image/audioDescription/android-step2.png";
import AndroidStep3 from "~/assets/image/audioDescription/android-step3.png";
import AndroidStep4 from "~/assets/image/audioDescription/android-step4.png";

import { Content, StepsBox, StepContainer } from "./styles";

const ChromeSteps = () => (
  <Content>
    <span tabIndex={5}>
      Existem vários aplicativos de acessibilidade. Recomendamos o “Talkback” e
      vamos explicar como configurar. Vamos lá.
    </span>

    <StepsBox>
      <StepContainer tabIndex={6}>
        <img src={AndroidStep1} alt="Passo 1 ativação talkback" />
        <span>
          Acesse o aplicativo google Play Store e busque por "pacote de
          acessibilidade do Android" e instale em seu smartphone. Certifique-se
          que que o sistema do seu Celular está em Português do Brasil.
        </span>
      </StepContainer>
      <StepContainer tabIndex={7}>
        <img src={AndroidStep2} alt="Passo 2 ativação talkback" />
        <span>
          Vá em configurações do seu Android, e acesse as opções de
          “Acessibilidade”
        </span>
      </StepContainer>
      <StepContainer tabIndex={8}>
        <img src={AndroidStep3} alt="Passo 3 ativação talkback" />
        <span>Acesse a opção de TalkBack</span>
      </StepContainer>
      <StepContainer tabIndex={9}>
        <img src={AndroidStep4} alt="Passo 4 ativação talkback" />
        <span>Ative a função do TalkBack</span>
      </StepContainer>
    </StepsBox>
  </Content>
);

export default ChromeSteps;
