import React, { Component } from "react";

import { Content, TitleRow, Title } from "./style";

class CategoriesHeader extends Component {
  render() {

    return (
      <Content>
        <TitleRow>
          <Title>Categorias</Title>
        </TitleRow>
      </Content>
    );
  }
}

export default CategoriesHeader;
