import React from "react";
import { useSelector } from "react-redux";

import { CDN } from "~/constants/config";
import iconHand from "~/assets/image/icon-hand.png";

import Favorite from "~/components/Favorite";

import {
  Container,
  Content,
  Image,
  Title,
  Avatar,
  AvatarImg,
  AvatarName,
  TitleFavorite
} from "./style";

export default function Item({ signal }) {
  const { node } = signal;
  const {
    iconeRepresentativo: icon,
    titulo,
    idSinal,
    personaNome,
    personaAvatar
  } = node;
  const url = icon ? `${CDN}${icon}` : iconHand;
  const { favorite } = useSelector(state => state.signal.isFavorite);

  const PersonAvatar = props => {
    return (
      <Avatar>
        <AvatarImg image={personaAvatar} />
        <AvatarName>Por {personaNome}</AvatarName>
      </Avatar>
    );
  };

  const errorHandler = e => {
    e.target.src = iconHand;
  };

  return (
    <Container to={`/verbete/${idSinal}`}>
      <Content>
        <TitleFavorite>
          <Title>{titulo}</Title>
          {favorite ? <Favorite isFavorite={favorite} size={18} /> : <></>}
        </TitleFavorite>
        <Image onError={errorHandler} src={url} />
        <PersonAvatar />
      </Content>
    </Container>
  );
}
