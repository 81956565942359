import styled from "styled-components";

import { colors } from "~/styles";

export const Content = styled.div`
  max-width: 370px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  background: ${colors.white_label};
  box-shadow: 0px 3px 6px #00000029;
  padding: 20px;
  margin: 30px auto 60px;
  font-size: 16px;
`;

export const OpinionImg = styled.img`
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 100%;
  max-width: 50px;
  margin: 0 0 6px;
`;

export const OpinionTestimony = styled.p`
  font-family: "VisbyRoundCF-Regular";
  font-weight: 400;
  color: ${colors.itemCardName};
`;

export const OpinionName = styled.p`
  font-family: "VisbyRoundCF-Medium";
  font-weight: 800;
  color: ${colors.itemCardName};
  margin: 10px 0 3px;
`;

export const OpinionAlt = styled.p`
  font-family: "VisbyRoundCF-Regular";
  font-weight: 400;
  color: ${colors.gray_faint};
  font-size: 16px;
`;
