import React, { useState } from "react";
import { truncate } from "lodash";

import ModalDelete from "../ModalDelete";

import {
  Content,
  Title,
  AvatarRow,
  AvatarName,
  Container,
  IconRemove,
  RemoveContainer,
  ExternalContainer,
  ImageVerbete,
  ImageAvatar,
} from "./style";

function Item({ item, folderId }) {
  const [hover, setHover] = useState(false);
  const [modal, setModal] = useState(false);

  return (
    <ExternalContainer>
      <Container
        to={`/signal/${item._id}`}
        aria-label={`Abrir Verbete, ${item.titulo}`}
      >
        <Content>
          <Title>{truncate(item.titulo, { length: 30 })}</Title>
          <ImageVerbete image={item.iconeRepresentativo} />
          <Persona item={item} />
        </Content>
      </Container>
      <RemoveContainer
        onMouseLeave={() => setHover(false)}
        onMouseEnter={() => setHover(true)}
        onClick={() => setModal(true)}
        aria-label={`Excluir Verbete, ${item.titulo} da pasta`}
      >
        <IconRemove hover={hover} />
      </RemoveContainer>
      <ModalDelete
        isOpen={modal}
        close={() => setModal(null)}
        title={item.titulo}
        signalId={item._id}
        folderId={folderId}
      />
    </ExternalContainer>
  );
}

/**
 * Componente Interno
 */
const Persona = (props) => {
  const { persona } = props.item;

  if (!persona) return null;

  return (
    <AvatarRow>
      <ImageAvatar image={persona.avatar} />
      <AvatarName>Por {persona.nome}</AvatarName>
    </AvatarRow>
  );
};

export default Item;
