import { combineReducers } from "redux";

import login from "./login";
import dashboard from "./dashboard";
import letter from "./letter";
import search from "./search";
import technologies from "./technologies";
import info from "./info";
import signal from "./signal";
import history from "./history";
import sugestao from "./sugestao";
import library from "./library";
import folder from "./folder";

export default combineReducers({
  login,
  dashboard,
  info,
  history,
  search,
  signal,
  technologies,
  letter,
  sugestao,
  library,
  folder
});
