import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, Redirect } from "react-router-dom";

import Header from "./components/Header";
import ShowList from "./components/ShowList";

import { Creators as FolderCreators } from "~/store/ducks/folder";

import { Content } from "./style";

const FolderBody = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const folderId = location.state ? location.state.folderId : null;

  useEffect(() => {
    dispatch(FolderCreators.folderRequest({ folderId }));
  }, [dispatch, folderId]);

  return (
    <>
      {!folderId && <Redirect to="page404" />}
      <Content>
        <Header />
        <ShowList />
      </Content>
    </>
  );
};
export default FolderBody;
