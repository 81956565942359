import React, { Component } from "react";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";

import Waiting from "~/components/Waiting";
// import { Creators as InfoCreators } from "~/store/ducks/info";

import { Content, Button, Label } from "./style";

class HasMore extends Component {
  render() {
    const { action, loading, hasMore } = this.props;

    if (!hasMore) return <Content />;

    if (loading)
      return (
        <Content>
          <Waiting />
        </Content>
      );

    return (
      <Content>
        <Button onClick={() => action()}>
          <Label>Ver mais</Label>
        </Button>
      </Content>
    );
  }
}

// const mapStateToProps = state => ({
//   filter: state.technologies.set,
//   mode: state.info.mode
// });

// const mapDispatchToProps = dispatch =>
//   bindActionCreators(
//     {
//       setMode: InfoCreators.setMode
//     },
//     dispatch
//   );

// export default connect(mapStateToProps, mapDispatchToProps)(HasMore);
export default HasMore;
