import React from "react";
import { doLogin } from "~/constants/loginFunctions";

import { SocialButtonsView, SocialButton } from "./style";

const SocialButtons = () => (
  <SocialButtonsView>
    {/* <SocialButton
      tabindex="0"
      social={"facebook"}
      onClick={() => doLogin("facebook")}
    >
      Login via Facebook
    </SocialButton> */}
    <SocialButton
      aria-label={"Usa sua conta google para entrar no Senai libras"}
      tabindex="0"
      social={"google"}
      onClick={() => doLogin("google")}
    >
      Login via Google
    </SocialButton>
  </SocialButtonsView>
);

export default SocialButtons;
