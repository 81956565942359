import styled from "styled-components";

import { fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-between;
  padding: 5px 0;
`;

export const Title = styled.h3`
  display: flex;
  flex: 1;
  align-items: center;
  padding: 30px 10px;
  /* border: 1px solid green; */

  color: #34495e;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 30px;
`;

export const Info = styled.span`
  color: #889baf;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 15px;
  margin: 0 15px;
  align-self: flex-end;
  text-align: center;
`;
