import React from "react";

import ModalNewFolder from "./components/ModalNewFolder";

import {
  Content,
  NewFolderTitle,
  NewFolderRow,
  NewFolderButtom,
  NewFolderIcon,
  NewFolderLabel,
} from "./style";

/* eslint eqeqeq: 0 */
const Header = (props) => {
  const [modal, setModal] = React.useState(null);

  return (
    <Content>
      <ModalNewFolder
        isOpen={modal == "newfolder"}
        close={() => setModal(null)}
      />
      <NewFolderTitle>Minhas pastas</NewFolderTitle>
      <NewFolderRow>
        <NewFolderButtom
          aria-label="Ícone criar nova biblioteca"
          onClick={() => setModal("newfolder")}
        >
          <NewFolderIcon />
        </NewFolderButtom>
        <NewFolderLabel
          aria-label={"Cadastrar nova biblioteca"}
          onClick={() => setModal("newfolder")}
        >
          Nova biblioteca
        </NewFolderLabel>
      </NewFolderRow>
    </Content>
  );
};

export default Header;
