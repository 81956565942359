import React, { Component } from "react";
import Modal from "react-modal";

import { customStyles } from "./style";

class HOC extends Component {
  render() {
    // Use os props modalWidth, modalHeigth para definir o tamanho do modal
    const { isOpen, close, modalWidth, modalHeigth } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={close}
        style={customStyles({ modalWidth, modalHeigth })}
        appElement={document.getElementById("root")}
      >
        {this.props.children}
      </Modal>
    );
  }
}

export default HOC;
