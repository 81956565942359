import React, { Component } from "react";

import Header from "./components/Header";
import Body from "./components/Body";

import { Content } from "./style";

class ModalCreditos extends Component {
  render() {
    const { close } = this.props;

    return (
      <Content>
        <Header {...this.prop} close={() => close()} />
        <Body {...this.prop} close={() => close()} />
      </Content>
    );
  }
}

export default ModalCreditos;
