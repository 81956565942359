import styled from "styled-components";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 45px;

  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    @media only screen and (max-width: ${MIN_TAB}px) {
      justify-content: center;
  }
  }
`;

export const StepsContainer = styled.div`
  flex: 1;
  padding: 0 0 50px 0;
`;
