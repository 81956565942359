import React from "react";

import { connect } from "react-redux";

import {
  Container,
  Content,
  BoxHeader,
  BoxTitle,
  BoxStep,
  LogoPalms
} from "./style";

const StepByStep = props => {
  const { signal } = props;

  if (!signal) return null;

  const reg = new RegExp(/\d+[ ]*[–-]/);

  const infos = signal.descricaoMovimento.split(reg).map((item, index) => {
    return (
      <p key={index}>
        {index} - {item}
        <br />
      </p>
    );
  });

  infos.shift();

  const movimentos = infos.length ? infos : signal.descricaoMovimento;

  return (
    <Container>
      <Content>
        <BoxHeader>
          <LogoPalms />
          <BoxTitle>Passo a passo</BoxTitle>
        </BoxHeader>
        <BoxStep>{movimentos}</BoxStep>
      </Content>
    </Container>
  );
};

const mapStateToProps = state => ({
  signal: state.signal.data
});

export default connect(mapStateToProps)(StepByStep);
