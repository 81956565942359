import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";
import { Collapse } from "react-collapse";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from "react-helmet";

import Waiting from "~/components/Waiting";
import ModalAddFolder from "~/components/ModalAddFolder";
import ModalNewFolder from "~/components/ModalAddFolder/components/ModalNewFolder";
import { ApplicationName } from '~/constants/routesName';

import { Creators as InfoCreators } from "~/store/ducks/info";
import { Creators as SignalCreators } from "~/store/ducks/signal";
import { Creators as HistoryCreators } from "~/store/ducks/history";

import { MIN_TAB } from "~/constants/config";

import {
  Container,
  Content,
  BoxMobile,
  BoxImage,
  Image,
  BoxTitle,
  Title,
  Box,
  BoxDescription,
  Icons,
  TitleFavorite,
  Center,
  IconAdd,
  TextAdd
} from "./style";

const Description = props => {
  const { collapsedRequest, collapsed, signal, createHistory } = props;

  const { auth } = useSelector(state => state.login);

  const [hover, setHover] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalCreate, setModalCreate] = useState(false);

  useEffect(() => {
    createHistory(signal);
  }, [createHistory, signal]);

  if (!signal || Number.isInteger(signal)) return null;

  const onCollapse = e => {
    e.preventDefault();
    collapsedRequest();
  };

  const Mobile = props => (
    <>
      <Helmet>
        <title>{signal.titulo + ' - ' + ApplicationName}</title>
        <meta name="description" content={signal.descricao
          || `Aprenda o verbete ${signal.titulo + ' - ' + ApplicationName} visualizando o vídeo de demonstração e acompanhando o passo a passo do movimento.`}>
        </meta>
      </Helmet>
      <BoxMobile onClick={onCollapse}>
        <BoxImage>
          <Image
            alt={"Abrir Descriçao de "}
            image={signal.iconeRepresentativo}
            loader={<Waiting />}
          />
        </BoxImage>
        <BoxTitle>
          <Title>{signal.titulo}</Title>
          <Icons collapsed={collapsed} />
          {auth && <ShowModal />}
        </BoxTitle>
      </BoxMobile>
      <Collapse isOpened={collapsed}>
        <BoxDescription>{signal.descricao}</BoxDescription>
      </Collapse>
    </>
  );

  const Web = props => (
    <>
      <Helmet>
        <title>{signal.titulo + ' - ' + ApplicationName}</title>
        <meta name="description" content={signal.descricao
          || `Aprenda o verbete ${signal.titulo + ' - ' + ApplicationName} visualizando o vídeo de demonstração e acompanhando o passo a passo do movimento.`}>
        </meta>
      </Helmet>
      <BoxImage>
        <Image
          image={signal.iconeRepresentativo}
          loader={<Waiting />}
          alt={`Imagem Representativa do Verbete ${signal.titulo}`}
        />
      </BoxImage>
      <Box>
        <TitleFavorite>
          <Title>{signal.titulo}</Title>
          {auth && <ShowModal />}
        </TitleFavorite>
        <BoxDescription>{signal.descricao}</BoxDescription>
      </Box>
    </>
  );

  const AddSinalFolder = () => {
    return (
      <>
        <ModalAddFolder
          isOpen={modal}
          close={() => setModal(null)}
          signalId={signal._id}
          setModalCreate={setModalCreate}
        />
        <ModalNewFolder
          isOpen={modalCreate}
          close={() => setModalCreate(null)}
          signalId={signal._id}
          setModalAdd={setModal}
        />
      </>
    );
  };

  const ShowModal = () => {
    return (
      <MediaQuery minWidth={MIN_TAB}>
        <Center
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          onClick={() => {
            setModal(true);
            setHover(false);
          }}
        >
          <IconAdd hover={hover} />
          <TextAdd>Adicionar à pasta</TextAdd>
        </Center>
      </MediaQuery>
    );
  };

  return (
    <Container>
      <Content>
        <MediaQuery maxWidth={MIN_TAB}>
          <Mobile />
        </MediaQuery>
        <MediaQuery minWidth={MIN_TAB}>
          <Web />
        </MediaQuery>
      </Content>
      <AddSinalFolder />
    </Container>
  );
};

const mapStateToProps = state => ({
  signal: state.signal.data,
  favorite: state.signal.isFavorite,
  collapsed: state.info.collapsed
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      collapsedRequest: InfoCreators.collapsedRequest,
      signalIsFavorite: SignalCreators.signalIsFavorite,
      createHistory: HistoryCreators.historyCreate
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Description);
