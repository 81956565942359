import React from "react";
import { useDispatch } from "react-redux";
import MediaQuery from "react-responsive";

import { Creators as FolderCreators } from "~/store/ducks/folder";

import HOCModal from "~/components/HOCModal";
import { MIN_TAB, MIN_WEB } from "~/constants/config";

import { Content, TitleBox, BodyBox, FooterBox } from "./style";
import { Title, Body, FooterButton } from "./style";

const Modal = ({
  isOpen,
  height,
  width,
  mobile,
  close,
  title,
  folderId,
  signalId,
}) => {
  const dispatch = useDispatch();

  function handleDelete() {
    dispatch(FolderCreators.folderDeleteRequest({ folderId, signalId }));
    close();
  }
  return (
    <HOCModal
      isOpen={isOpen}
      close={close}
      modalHeigth={height}
      modalWidth={width}
      mobile={mobile}
    >
      <Content>
        <TitleBox>
          <Title>Remover pasta</Title>
        </TitleBox>
        <BodyBox>
          <Body>
            Você realmente deseja deletar a pasta <Body bold>"{title}"</Body>
          </Body>
        </BodyBox>
        <FooterBox>
          <FooterButton aria-label={"Cancela a ação e volta"} onClick={close}>
            Não
          </FooterButton>
          <FooterButton
            aria-label={"Confirma remoção da pasta"}
            warn
            onClick={handleDelete}
          >
            Sim
          </FooterButton>
        </FooterBox>
      </Content>
    </HOCModal>
  );
};

const ModalDelete = (props) => {
  const { isOpen } = props;

  if (!isOpen) return null;

  return (
    <>
      <MediaQuery minWidth={MIN_WEB}>
        <Modal width={550} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_WEB} minWidth={MIN_TAB}>
        <Modal width={500} height={270} {...props} />
      </MediaQuery>

      <MediaQuery maxWidth={MIN_TAB}>
        <Modal width={320} height={270} {...props} mobile />
      </MediaQuery>
    </>
  );
};

export default ModalDelete;
