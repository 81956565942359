import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Span } from "./styles";

export default function Input({ name, label, textarea, ...rest }) {
  const inputRef = useRef(null);

  const { fieldName, defaultValue = "", registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value"
    });
  }, [fieldName, registerField]);
  return (
    <>
      {label && <label htmlFor={fieldName}>{label}</label>}

      {(textarea && (
        <textarea
          ref={inputRef}
          id={fieldName}
          defaultValue={defaultValue}
          {...rest}
        />
      )) || (
        <input
          ref={inputRef}
          id={fieldName}
          defaultValue={defaultValue}
          {...rest}
        />
      )}

      {error && <Span style={{ color: "#f00" }}>{error}</Span>}
    </>
  );
}
