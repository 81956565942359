import styled from "styled-components";
import { MaterialCommunityIcons } from "react-web-vector-icons";

import { MIN_TAB } from "~/constants/config";

import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;

  /* @media only screen and (max-width: ${MIN_TAB}px) {
    flex: 1;
  } */
`;

export const TitleRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  /* @media only screen and (max-width: ${MIN_TAB}px) { */
    padding: 0px 0 20px;
    /* padding-bottom: 20px; */
  /* } */

  /* @media screen {

  } */
`;

export const Title = styled.h5`
  color: ${colors.primary};
  font-size: 20px;
  font-family: ${fonts.VisbyRoundMedium};
`;

export const CloseButton = styled.button``;

export const CloseIcon = styled(MaterialCommunityIcons).attrs(props => ({
  name: "close",
  size: 30,
  color: colors.button
}))`
  padding: 5px;
`;
