import styled from "styled-components";
import { darken } from "polished";
import { fonts, colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
`;

export const TitleBox = styled.div`
  padding: 10px 0;
  border-bottom: 1px solid #eaeaea;
`;

export const Title = styled.h5`
  color: #4a7aff;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 20px;
`;

export const BodyBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 5px;
  overflow: auto;
  margin: 10px 0;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-track {
    background: ${colors.whiteTransparent};
  }

  ::-webkit-scrollbar-thumb {
    background: ${colors.primary};
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.2, colors.primary)};
  }
`;

export const FooterBox = styled.div`
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-top: 20px;
  border-top: 1px solid #eaeaea;
`;

export const FooterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;
  margin-left: 5px;

  min-width: 110px;
  min-height: 40px;

  padding: 10px;

  color: ${props => (props.warn ? "#4663E3" : "white")};
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  background-color: ${props => (props.warn ? "#DBE5FF" : "#4663E3")};
  border-radius: 10px;
`;
