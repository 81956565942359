import { LoginTypes, FolderTypes as Types } from "~/constants/types";
import immer from "immer";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  refreshing: false
};

export default function library(state = INITIAL_STATE, { type, payload }) {
  return immer(state, draft => {
    switch (type) {
      case Types.REQUEST: {
        draft.loading = true;
        break;
      }
      case LoginTypes.DESTROY_SESSION: {
        draft.data = null;
        draft.loading = false;
        draft.error = false;
        draft.refreshing = false;
        break;
      }
      case Types.REFRESH: {
        draft.data = null;
        draft.refreshing = true;
        break;
      }
      case Types.SUCCESS: {
        const oldList = state.data && state.data.sinais.edges;
        const newerList = payload.sinais.edges;
        const edges = oldList ? oldList.concat(newerList) : newerList;

        draft.data = {
          ...payload,
          sinais: { ...payload.sinais, edges }
        };
        draft.loading = false;
        draft.refreshing = false;
        break;
      }
      case Types.DELETE_REQUEST: {
        draft.loading = true;
        draft.error = false;
        break;
      }
      case Types.DELETE_SUCCESS: {
        draft.loading = false;
        draft.data = payload;
        break;
      }
      case Types.FAILURE: {
        draft.loading = false;
        draft.error = true;
        break;
      }
      default:
    }
  });
}

export const Creators = {
  folderRefresh: payload => ({ type: Types.REFRESH, payload }),
  folderRequest: payload => ({ type: Types.REQUEST, payload }),
  folderSuccess: payload => ({ type: Types.SUCCESS, payload }),
  folderDeleteRequest: payload => ({ type: Types.DELETE_REQUEST, payload }),
  folderDeleteSuccess: payload => ({ type: Types.DELETE_SUCCESS, payload }),
  folderFailure: payload => ({ type: Types.FAILURE, payload })
};
