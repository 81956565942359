import styled, { keyframes, css } from "styled-components";
import { Input } from "~/components";

import { MIN_TAB, MIN_WEB } from "~/constants/config";

import { fonts, colors } from "~/styles";

export const ExternalContainer = styled.div`
  display: flex;
  justify-content: space-between !important;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 10px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    max-width: 960px;
    margin: 50px auto;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    max-width: 1024px;
    margin: 50px auto;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-self: flex-start;
  max-width: 760px;
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 20px;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: 600;
  letter-spacing: 1px;
  color: ${colors.pageSubTitle};

  @media (min-width: ${MIN_TAB}px) {
    margin: 26px 0;
  }
`;

export const Box = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  max-height: 500px;
  min-width: 600px;
  padding: 20px;
  border: 1px solid ${colors.borderForm};

  @media only screen and (max-width: ${MIN_TAB}px) {
    width: 100%;
    min-width: initial;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputTitle = styled.span`
  color: ${colors.subTitle};
  font-size: 14px;
  padding: 6px 0;
`;

export const InputBorder = styled.div`
  padding: 10px;
  border-radius: 10px;
  border: 1px solid ${colors.borderInput};
  margin-bottom: 6px;
  flex-grow: 1;
  display: flex;
`;

export const FormInput = styled(Input)`
  margin: 5px 10px;
  color: ${colors.subTitle};
  border: none;
  background-color: transparent;
  flex: 1;
`;

export const FormTextArea = styled(Input).attrs({ textarea: true })`
  background-color: transparent;
  width: 100%;
  margin: 5px 0 0 5px;
  color: ${colors.subTitle};
  border: none;
  height: 66px;
`;

const rotate = keyframes`
  from{
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const Button = styled.button.attrs((props) => ({
  disabled: props.loading,
}))`
  background: ${(props) => props.color || colors.button};
  color: white;
  border-radius: 8px;
  font-size: 16px;
  font-family: "VisbyRoundCF-Medium";
  height: 46px;
  width: 132px;
  min-width: 98px;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  ${(props) =>
    props.loading &&
    css`
      svg {
        animation: ${rotate} linear infinite 2s;
      }
    `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: flex-end;
`;

export const InputFileContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SugestaoMessageContainer = styled(InputContainer)`
  margin-top: 20px;
`;
