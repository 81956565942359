import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ScrollMenu from "react-horizontal-scrolling-menu";

// import { AZ } from "~/constants/letters";
import { Creators as LetterCreators } from "~/store/ducks/letter";

import { Item, Button, Icon, styles } from "./style";

/* eslint eqeqeq: 0 */
class ShowLetters extends Component {
  state = {
    lHover: false,
    rHover: false,
  };

  selectItem = async (letterPosition) => {
    const { letters } = this.props;
    this.props.letterLoading(letters[letterPosition]);
  };

  Arrows = (props) => {
    const { field } = props;

    const { [field]: hover } = this.state;

    return (
      <Button
        onMouseEnter={() => this.setState({ [field]: true })}
        onMouseLeave={() => this.setState({ [field]: false })}
        aria-label={
          field == "rHover" ? "Rolar para direita" : "Rolar para esquerda"
        }
      >
        <Icon color={hover && "white"} inverted={field == "rHover"} />
      </Button>
    );
  };

  render() {
    const { Arrows } = this;
    const { letters, letter, mode } = this.props;

    if (!letters) return null;

    const data = letters.map((e, i) => (
      <Item
        key={i}
        mark={mode == "letter" && letter === e}
        aria-label={`Filtrar por letra ${e}`}
        // aria-label={`Filtrar por letra ${e}`}
      >
        {e}
      </Item>
    ));

    return (
      // <Content>
      <ScrollMenu
        data={data}
        // selected={selected}
        onSelect={this.selectItem}
        arrowLeft={<Arrows field={"lHover"} />}
        itemsCount={data.length}
        translate={0}
        transition={0.2}
        scrollToSelected={true}
        arrowRight={<Arrows field={"rHover"} />}
        hideArrows={false}
        menuStyle={styles.menuStyle}
        wrapperStyle={styles.wrapperStyle}
        innerWrapperStyle={styles.innerWrapperStyle}
        // onUpdate={e => console.tron.log(e, "onUpdate")}
      />
      // </Content>
    );
  }
}

const mapStateToProps = (state) => ({
  letter: state.letter.letter,
  letters: state.letter.letters,
  mode: state.info.mode,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      letterSet: LetterCreators.letterSet,
      letterRequest: LetterCreators.letterRequest,
      letterLoading: LetterCreators.letterLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ShowLetters);
