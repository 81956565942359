import React from "react";

import { Content, Banner, Message } from "./style";

const Empty = props => {
  return (
    <Content>
      <Banner />
      <Message>Nenhum resultado encontrado</Message>
    </Content>
  );
};

export default Empty;
