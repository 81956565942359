import React from "react";
import { withRouter } from "react-router-dom";

import {
  Container,
  Content,
  ErrorImage,
  ErrorMessage,
  ErrorButton
} from "./style";

const Error404 = props => {
  const goBack = e => {
    e.preventDefault();
    props.history.push("/");
  };

  return (
    <Container>
      <Content>
        <ErrorImage />
        <ErrorMessage>
          A página que você procura não foi encontrada
        </ErrorMessage>
        <ErrorButton onClick={goBack}>Voltar</ErrorButton>
      </Content>
    </Container>
  );
};

export default withRouter(Error404);
