import React from "react";

import { FACEBOOK_SENAI, TWITTER_SENAI, YOUTUBE_SENAI } from '~/constants/config';

import { Content, Cicle, Icon, Socials, Privacity, Bottom } from "./style";

/* eslint eqeqeq: 0 */
const Social = (props) => {
  const [hover, setHover] = React.useState(0);

  return (
    <Content>
      <Socials>
        <Cicle
          onMouseEnter={() => setHover(1)}
          onMouseLeave={() => setHover(0)}
          aria-label={"Facebook"}
          target="_blank"
          href={FACEBOOK_SENAI}
        >
          <Icon icon={"facebook"} hover={hover == 1} />
        </Cicle>
        <Cicle
          onMouseEnter={() => setHover(2)}
          onMouseLeave={() => setHover(0)}
          aria-label={"Twitter"}
          target="_blank"
          href={TWITTER_SENAI}
        >
          <Icon icon={"twitter"} hover={hover == 2} />
        </Cicle>
        <Cicle
          onMouseEnter={() => setHover(3)}
          onMouseLeave={() => setHover(0)}
          aria-label={"YouTube"}
          target="_blank"
          href={YOUTUBE_SENAI}
        >
          <Icon icon={"youtube-play"} hover={hover == 3} />
        </Cicle>
      </Socials>
      <Bottom>
        <Privacity to="politica-de-privacidade" target='_blank'>POLÍTICA DE PRIVACIDADE</Privacity>
      </Bottom>
    </Content>
  );
};

export default Social;
