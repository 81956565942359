import React, { Component } from "react";
import Carousel from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";

import Opinion from "./components/Opinion";

import {
  Content,
  TitleBox,
  Title,
  OpinionsBox,
  OpinionsBackground,
} from "./style";

class Opinions extends Component {
  render() {
    return (
      <Content>
        <TitleBox>
          <div data-aos="zoom-in" data-aos-offset="200">
            <Title>Opinião da comunidade</Title>
          </div>
        </TitleBox>

        <OpinionsBox>
          <OpinionsBackground
            data-aos="zoom-in-up"
            data-aos-offset="300"
            data-aos-duration="800"
          />
          <Carousel
            arrows
            infinite
            centered
            slidesPerPage={5}
            clickToChange
            offset={30}
            breakpoints={{
              2300: {
                slidesPerPage: 3,
              },
              1600: {
                slidesPerPage: 3,
              },
              1400: {
                slidesPerPage: 1,
                arrows: false,
              },
            }}
          >
            <Opinion name="Camila Barufaldi" alt="Administradora de empresas"
            testimony="O app SENAI Libras é uma maneira fácil e gratuita para iniciar a integração entre pessoas surdas e ouvintes!"/>
            <Opinion name="Alessandra Fidélis" alt="Professores de LIBRAS (experiência de 9 anos)"
            testimony="O Senai LIBRAS App é um aplicativo extremamente relevante para quem atua na educação profissional como um todo. É um aplicativo simples e fácil de ser usado. Todos, ouvintes e surdos, podem se beneficiar dos sinais criados de 7 área diferentes da educação profissional. Será de grande ajuda para os professores e intérpretes de LIBRAS que atuem com alunos surdos."/>
            <Opinion name="Fernanda Marques" alt="Analista de Educação Profissional - GO"
            testimony="O app SENAI LIBRAS representa mais que uma tecnologia assistiva. É uma maneira de aproximar a comunidade surda da profissionalização e do mundo do trabalho. Assim, a ferramenta é inclusiva desde a concepção, passando pelo desenvolvimento, por meio de pesquisa junto à comunidade surda, até os personagens que valorizam a diversidade humana: Felipe, Sandra, Sebastião. O SENAI LIBRAS, tecnologia de conteúdo inédito, proporciona mais qualidade à educação do SENAI, sem dúvida, uma excelente iniciativa. Parabéns app SENAI LIBRAS!"/>
            <Opinion name="Eloisa Naue" alt="Interlocutora do Programa SESI SENAI de Ações Inclusivas em SC"
            testimony="O Aplicativo SENAI Libras representa uma conquista de acessibilidade e autonomia para as pessoas com deficiência auditiva. É um trabalho primoroso, idealizado e desenvolvido por profissionais da casa, que materializa o compromisso da instituição com a inclusão e a formação profissional para todos"/>
          </Carousel>
        </OpinionsBox>
      </Content>
    );
  }
}

export default Opinions;
