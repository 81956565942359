import React from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { withRouter } from "react-router";

import { CDN } from "~/constants/config";
import { Creators as TechCreators } from "~/store/ducks/technologies";
import Waiting from "~/components/Waiting";

import { Content, TechList, TechBox, TechTitle, ShowImage } from "./style";
import CategoriesHeader from "~/components/ModalTechnologies/components/CategoriesHeader";

function Body(props) {
  const { list_tech } = props;
  const location = useLocation();

  const getCategories = lista => {
    const categories = [];
    lista.map(item => item.node.isCategoria && categories.push(item));
    return categories;
  };

  const listaCategorias = getCategories(list_tech);

  const handleFilter = (area) => {
    props.technologySet(area);
    props.close();
    if (location.pathname === "/") {
      props.history.push("/dashboard");
    }
  };

    return (
      <Content>
        <TechList>
          {list_tech.map(item => (
            !item.node.isCategoria && (
            <Item
              key={item.node._id}
              item={item.node}
              action={() => handleFilter(item.node)}
            />
            )
          ))}
        </TechList>
        {listaCategorias.length > 0 && <CategoriesHeader />}
          <TechList>
          {listaCategorias.map(item => (
            item.node.isCategoria && (
            <Item
              key={item.node._id}
              item={item.node}
              action={() => handleFilter(item.node)}
            />
            )
          ))}
        </TechList>
      </Content>
    );
}

const Item = ({ item, action }) => {
  const url = `${CDN}${item.iconeRepresentativo}`;

  return (
    <TechBox
      onClick={action}
      color={item.backgroundColor}
      aria-label={`Filtrar por ${item.nome}`}
    >
      <ShowImage src={url} />
      <TechTitle>{item.nome}</TechTitle>
    </TechBox>
  );
};

const mapStateToProps = (state) => ({
  list_tech: state.technologies.list && state.technologies.list.edges,
  loading: state.technologies.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      technologySet: TechCreators.technologySet,
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Body);
