import React from "react";

import { Content, Banner } from "./style";

import Card from "./components/Card";
import Social from "./components/Social";

const AppFooter = props => {
  return (
    <Content>
      <Banner>
        <Card card={"sac"} />
        <Card card={"contact"} />
        <Card card={"loc_sao_paulo"} />
      </Banner>
      <Social />
    </Content>
  );
};

export default AppFooter;
