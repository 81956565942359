import styled from "styled-components";

import {
  MaterialCommunityIcons,
  AntDesign,
  FontAwesome,
} from "react-web-vector-icons";

import { MIN_TAB, MIN_WEB } from "~/constants/config";

import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  justify-content: ${(props) => (props.show ? "space-between" : "flex-end")};
  align-items: center;
  padding: 10px;

  @media only screen and (max-width: ${MIN_TAB}px) {
    display: block;
  }
`;

export const TechBox = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.color || colors.primary};
  padding: 5px 10px;
  border-radius: 5px;
  width: fit-content;
`;

export const TechLabel = styled.span`
  color: white;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 15px;
`;

export const TechCloseButton = styled.button`
  padding: 5px;
`;

export const TechClose = styled(MaterialCommunityIcons).attrs((props) => ({
  name: "close",
  size: 14,
  color: "white",
}))``;

export const BoxInfo = styled.div`
  flex-direction: row;
  align-items: center;
  width: fit-content;
  margin: 0 0 0 auto;
`;

export const Info = styled.span`
  color: #889baf;
  font-family: ${fonts.VisbyRoundMedium};
  font-size: 15px;
  padding: 0 5px;
`;

export const ButtonList = styled.button`
  padding: 6px;
`;

export const IconBlock = styled(AntDesign).attrs((props) => ({
  name: "appstore1",
  size: 18,
  color: colors.headerBackground,
}))`
  padding: 5px;
`;

export const IconList = styled(FontAwesome).attrs((props) => ({
  name: "list-ul",
  size: 20,
  color: colors.headerBackground,
}))`
  padding: 5px;
`;
