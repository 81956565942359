import axios from "axios";
import { URL } from "~/constants/config";
import { doLogout } from "~/constants/loginFunctions";
import { store } from "~/store";

const urlGraphql = "/graphql";

const authHeaders = ({ provider, token }) => ({
  ...(() =>
    provider &&
    provider !== "null" &&
    ({ "X-Auth-Provider": provider } || null))(),
  ...(() => token && token !== "null" && ({ "X-Auth-Token": token } || null))(),
});

const createAuthApi = (headers = {}) => {
  const { profile } = store.getState().login;
  const api = axios.create({
    baseURL: URL,
    headers: { ...authHeaders(profile), ...headers },
  });
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (401 === error.response.status) {
        doLogout();
      }
    }
  );
  return api;
};

export const graphql = async (body) => {
  const api = createAuthApi();
  try {
    return await api.post(urlGraphql, body);
  } catch (error) {
    return {
      status: 500,
      error: "Erro na forma de recuperar dados",
      data: { data: null },
    };
  }
};

export const elastic = async (body) => {
  try {
    return await api.get("/sinal/desc", body);
  } catch (e) {
    throw e;
  }
};

export const rest = {
  post: async (endpoint, body) => {
    try {
      return await axios.post(URL + endpoint, body);
    } catch (error) {
      return { error: "Erro na forma de recuperar dados" };
    }
  },
};

export const api = axios.create({
  baseURL: URL,
});
