import styled from "styled-components";
import { colors, fonts } from "~/styles";

export const Span = styled.span`
  font-family: ${fonts.VisbyRoundMedium};
  color: ${colors.failure};
  font-size: 12px;
  display: flex;
  align-items: center;
`;
