import React from "react";
import { connect } from "react-redux";
import { Helmet } from 'react-helmet';

import Container from "~/components/Container";
import PopUp from "~/components/PopUp";
import { DashboardRoute, DashboardDescription } from '~/constants/routesName';

import DashboardHeader from "./components/DashboardHeader";
import DashboardBody from "./components/DashboardBody";
import DashboardFooter from "./components/DashboardFooter";

const Dashboard = ({ notify }) => {
  return (
    <Container>
      <Helmet>
        <title>{DashboardRoute}</title>
        <meta name="description" content={DashboardDescription}></meta>
        <link rel="canonical" href="https://senai-libras.senai.br/"></link>
      </Helmet>
      <DashboardHeader />
      <DashboardBody />
      <DashboardFooter />
      {notify && <PopUp />}
    </Container>
  );
}

const mapStateToProps = state => ({
  notify: state.info.notify
});

export default connect(mapStateToProps)(Dashboard);
