import React, { Component } from "react";

import mockupVideoImg from "~/assets/image/landingPage/mockupVideo.png";
import { CDN } from "~/constants/config";

import {
  Content,
  TitleBox,
  Title,
  SubTitle,
  MockupBox,
  MockupVideo,
} from "./style";

const Video = '/media/landpage/videos/Neuron%20IST-SD%20Techdemo%20V2.mp4';

class AnimationProcess extends Component {
  render() {
    return (
      <Content>
        <TitleBox>
          <div data-aos="fade-right">
            <Title>
              <b>Nosso processo de Animação</b>
            </Title>
          </div>
          <div data-aos="fade-right" data-aos-delay="400">
            <SubTitle>
              O aplicativo SENAI LIBRAS é um glossário de termos técnicos da
              indústria, que por meio de avatares animados serve como apoio a
              docentes, alunos e profissionais auxiliando na tradução de termos
              técnicos da educação profissional para a língua brasileira de
              sinais.
            </SubTitle>
            <SubTitle>
              O processo para a inclusão de novos sinais no banco de dados
              funciona assim: um intérprete grava os termos em vídeo e repassa à
              equipe de animadores, que reproduz os movimentos no avatar
              manualmente.
            </SubTitle>
            <SubTitle>
              Buscando uma solução mais eficiente para o processo de animação, a
              equipe da UNIEP com o apoio técnico de seu time de
              desenvolvimento, adquiriu através de importação um equipamento de
              captura de movimentos chamado Perception Neuron Motion Capture
              System.
            </SubTitle>
            <SubTitle>
              A tecnologia de captura de movimentos é largamente utilizada em
              filmes, jogos e animações. Essa nova aquisição nos habilita uma
              poderosa ferramenta para utilização não somente neste projeto, mas
              para elaboração de novos aplicativos e formas de interação que
              necessitem a reprodução de movimentos complexos com precisão.
            </SubTitle>
          </div>
        </TitleBox>

        <MockupBox>
          <div
            data-aos="fade-left"
            data-aos-delay="400"
            data-aos-duration="800"
            data-aos-offset="350"
          >
            <MockupVideo src={mockupVideoImg} />
            {/* <iframe
              src="https://www.youtube.com/embed/uPXyWCkJRoc"
              frameborder="0"
              allow="autoplay; encrypted-media"
              allowfullscreen
              title="video"
            /> */}
            <video controls src={CDN + Video} type="video/mp4" />
          </div>
        </MockupBox>
      </Content>
    );
  }
}

export default AnimationProcess;
