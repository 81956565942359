import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: "senailibras",
      storage,
      whitelist: ["login"],
      blacklist: [
        "dashboard",
        "letter",
        "search",
        "signal",
        "info",
        "technologies",
        "history",
        "sugestao"
      ]
    },
    reducers
  );

  return persistedReducer;
};
