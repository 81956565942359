import React, { Component } from "react";
import { truncate } from "lodash";

// import Favorite from "~/components/Favorite";

import { Container, ContentRow, Row } from "./style";
import { Content, Title, AvatarRow, AvatarName } from "./style";
import { ImageVerbete, ImageAvatar } from "./style";

class Item extends Component {
  render() {
    const { item, row } = this.props;

    if (row) {
      return (
        <ContentRow>
          <Row to={`/verbete/${item._id}`}>
            <ImageVerbete image={item.iconeRepresentativo} row="true" title={item.titulo} />
            <Title row="true">
              {truncate(item.titulo, { length: 30 })}
              {/* <Favorite isFavorite /> */}
            </Title>
            <Persona item={item} persona={item.persona.nome}/>
          </Row>
        </ContentRow>
      );
    }

    return (
      <Container
        aria-label={`Abrir Verbete, ${item.titulo}`}
        to={`/verbete/${item._id}`}
      >
        <Content>
          <Title>
            {truncate(item.titulo, { length: 30 })}
            {/* <Favorite isFavorite /> */}
          </Title>
          <ImageVerbete image={item.iconeRepresentativo} title={item.titulo}/>
          <Persona item={item} />
        </Content>
      </Container>
    );
  }
}

/**
 * Componente Interno
 */
const Persona = props => {
  const { persona } = props.item;

  if (!persona) return null;

  return (
    <AvatarRow>
      <ImageAvatar image={persona.avatar} persona={persona.nome} />
      <AvatarName>Por {persona.nome}</AvatarName>
    </AvatarRow>
  );
};

export default Item;
