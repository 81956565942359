import React, { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { doLogin } from "~/constants/loginFunctions";
import { Form } from "@unform/web";
import { SocialButtons } from "../";
import * as Yup from "yup";

import {
  Container,
  ButtonArea,
  ButtonOutline,
  InputBorder,
  FormInput,
  TextForgetPassword,
} from "./style";

function LoginForm({ setForm }) {
  const formRef = useRef(null);
  const history = useHistory();
  const { auth } = useSelector((state) => state.login);

  useEffect(() => {
    auth && history.push("/");
  }, [auth, history]);

  async function handleSubmit(data) {
    try {
      resetErros();

      const schema = Yup.object().shape({
        email: Yup.string()
          .email("Insira um e-mail válido")
          .required("O e-mail é obrigatório"),
        password: Yup.string().required("A senha é obrigatória"),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      doLogin("local");
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
    }
  }

  function resetErros() {
    formRef.current.setErrors({});
  }

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <InputBorder>
          <FormInput
            aria-label={"Inserir e-mail para login"}
            name="email"
            type="text"
            placeholder="E-mail"
            onFocus={resetErros}
          />
        </InputBorder>
        <InputBorder>
          <FormInput
            aria-label={"Inserir senha para login"}
            name="password"
            type="password"
            placeholder="Senha"
            onFocus={resetErros}
          />
        </InputBorder>
        <ButtonArea>
          <TextForgetPassword
            aria-label={"Abre formulário para recuperar senha"}
            onClick={() => setForm("forget-password")}
          >
            Esqueceu sua senha?
          </TextForgetPassword>
          <ButtonOutline
            aria-label={"Envia os dados e efetua o login"}
            id="loginButton"
            type="submit"
          >
            Entrar
          </ButtonOutline>
        </ButtonArea>
      </Form>
      <SocialButtons />
    </Container>
  );
}

export default LoginForm;
