import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

export const AppName = styled.p`
  font-size: 13px;
  text-align: center;
  letter-spacing: 0;
  color: #34495e;
  opacity: 1;
`;

export const styles = {
  margin: {
    margin: "10px"
  },
  whats: {
    width: "620px",
    heigth: "810px"
  }
};
