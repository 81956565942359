import React, { useRef } from "react";
import { changePassword } from "~/constants/loginFunctions";
import * as Yup from "yup";

import {
  Form,
  ButtonOutline,
  InputBorder,
  FormInput,
  ForgetPasswordButtons,
  InternalTitle,
} from "./style";

const ChangePasswordForm = ({ securityToken, setForm }) => {
  const formRef = useRef(null);

  async function handleSubmit(data) {
    try {
      resetErros();

      const schema = Yup.object().shape({
        password: Yup.string()
          .min(8, "No mínimo 8 caracteres")
          .required("A senha é obrigatória"),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref("password"), null],
          "Senhas não coincidem"
        ),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      changePassword(data.password, securityToken, setForm);
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
      }
      formRef.current.clearField("password");
      formRef.current.clearField("confirmPassword");
    }
  }

  function resetErros() {
    formRef.current.setErrors({});
  }

  return (
    <>
      <InternalTitle>Modificar senha</InternalTitle>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <InputBorder>
          <FormInput
            aria-label={"Inserir sua nova senha"}
            tabindex="0"
            id="password"
            type="password"
            name="password"
            placeholder="Nova senha"
            onFocus={resetErros}
          />
        </InputBorder>
        <InputBorder>
          <FormInput
            aria-label={"Inserir sua nova senha novamente"}
            tabindex="0"
            type="password"
            name="confirmPassword"
            placeholder="Confirmar nova senha"
            onFocus={resetErros}
          />
        </InputBorder>
        <ForgetPasswordButtons>
          <ButtonOutline
            aria-label={"Cancela e volta para a tela de login"}
            inverse
            marginRight
            tabindex="0"
            onClick={() => setForm("login")}
          >
            Cancelar
          </ButtonOutline>
          <ButtonOutline
            aria-label={"Confirma e envia dados para atualizar sua senha"}
            right
            tabindex="0"
            id="changePasswordButton"
            type="submit"
          >
            Mudar senha
          </ButtonOutline>
        </ForgetPasswordButtons>
      </Form>
    </>
  );
};

export default ChangePasswordForm;
