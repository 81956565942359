const dashboard = "dashboard";

export const DashboardTypes = {
  REQUESTMORE: `@${dashboard}/REQUESTMORE`,
  LOADING: `@${dashboard}/LOADING`,
  REQUEST: `@${dashboard}/REQUEST`,
  SUCCESS: `@${dashboard}/SUCCESS`,
  FAILURE: `@${dashboard}/FAILURE`
};

const signal = "signal";

export const SignalTypes = {
  LOADING: `@${signal}/LOADING`,
  REQUEST: `@${signal}/REQUEST`,
  SUCCESS: `@${signal}/SUCCESS`,
  FAILURE: `@${signal}/FAILURE`,
  FAVORITE: `@${signal}/FAVORITE`
};

const tech = "tech";

export const TechTypes = {
  SELECTED: `@${tech}/SELECTED`,
  LOAD: `@${tech}/LOAD`,
  SAVE: `@${tech}/SAVE`,
  REQUEST: `@${tech}/REQUEST`,
  SUCCESS: `@${tech}/SUCCESS`,
  FAILURE: `@${tech}/FAILURE`
};

const search = "search";

export const SearchTypes = {
  LOADING: `@${search}/LOADING`,
  REQUEST: `@${search}/REQUEST`,
  SUCCESS: `@${search}/SUCCESS`,
  FAILURE: `@${search}/FAILURE`,
  SET: `@${search}/SET`
};

const info = "info";

export const InfoTypes = {
  SEARCH: `@${info}/search/REQUEST`,
  COLLAPSED: `@${info}/collapsed/REQUEST`,
  NOTIFY: `@${info}/notify/REQUEST`,
  LIST_FORMAT: `@${info}/listFormat/REQUEST`,
  MODE: `@${info}/mode/REQUEST`
};

const history = "history";

export const HistoryTypes = {
  CREATE: `@${history}/CREATE_ITEM`,
  LOADING: `@${history}/LOADING`,
  REFRESH: `@${history}/REFRESH`,
  REQUEST: `@${history}/REQUEST`,
  SUCCESS: `@${history}/SUCCESS`,
  FAILURE: `@${history}/FAILURE`
};

const letter = "letter";

export const LetterTypes = {
  SET: `@${letter}/SET`,
  LOADING: `@${letter}/LOADING`,
  REQUEST: `@${letter}/REQUEST`,
  SUCCESS: `@${letter}/SUCCESS`,
  FAILURE: `@${letter}/FAILURE`
};

export const SugestaoTypes = {
  MUTATION: "sugestao/MUTATION",
  SUCCESS: "sugestao/SUCCESS",
  ERROR: "sugestao/ERROR",
  INIT: "sugestao/INIT"
};

const login = "login";

export const LoginTypes = {
  REQUEST: `@${login}/LOGIN_REQUEST`,
  SUCCESS: `@${login}/LOGIN_SUCCESS`,
  FAILURE: `@${login}/LOGIN_FAILURE`,
  RESET_REQUEST: `@${login}/LOGIN_RESET_REQUEST`,
  RESET_SUCCESS: `@${login}/LOGIN_RESET_SUCCESS`,
  RESET_FAILURE: `@${login}/LOGIN_RESET_FAILURE`,
  ERROR_CLEAR: `@${login}/ERROR_CLEAR`,
  DESTROY_SESSION: `@${login}/DESTROY_SESSION`,
  CONFIRM_EMAIL: `@${login}/CONFIRM_EMAIL`
};

const library = "library";

export const LibraryTypes = {
  CREATING: `@${library}/CREATING`,
  EDITING_REQUEST: `@${library}/EDITING_REQUEST`,
  DELETE_REQUEST: `@${library}/DELETE_REQUEST`,
  MODIFY_SUCCESS: `@${library}/MODIFY_SUCCESS`,
  LOADING: `@${library}/LOADING`,
  REFRESH: `@${library}/REFRESH`,
  REQUEST: `@${library}/REQUEST`,
  SUCCESS: `@${library}/SUCCESS`,
  ADD_SIGNAL: `@${library}/ADD_SINAL`,
  FAILURE: `@${library}/FAILURE`
};

const folder = "folder";

export const FolderTypes = {
  LOADING: `@${folder}/LOADING`,
  REFRESH: `@${folder}/REFRESH`,
  REQUEST: `@${folder}/REQUEST`,
  SUCCESS: `@${folder}/SUCCESS`,
  DELETE_REQUEST: `@${folder}/DELETE_REQUEST`,
  DELETE_SUCCESS: `@${folder}/DELETE_SUCCESS`,
  FAILURE: `@${folder}/FAILURE`
};
