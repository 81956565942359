import React from "react";
import { truncate } from "lodash";

import {
  Content,
  Title,
  AvatarRow,
  AvatarName,
  Container,
  ImageVerbete,
  ImageAvatar,
} from "./style";

function Item({ item }) {
  return (
    <Container to={`/signal/${item._id}`}>
      <Content aria-label={`Abrir Verbete, ${item.titulo}`}>
        <Title>{truncate(item.titulo, { length: 30 })}</Title>
        <ImageVerbete image={item.iconeRepresentativo} />
        <Persona item={item} />
      </Content>
    </Container>
  );
}

/**
 * Componente Interno
 */
const Persona = (props) => {
  const { persona } = props.item;

  if (!persona) return null;

  return (
    <AvatarRow>
      <ImageAvatar image={persona.avatar} />
      <AvatarName>Por {persona.nome}</AvatarName>
    </AvatarRow>
  );
};

export default Item;
