import { SignalTypes as Types } from "~/constants/types";

const INITIAL_STATE = {
  data: null,
  loading: false,
  error: false,
  isFavorite: true
};

export default function signal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOADING:
      return { ...INITIAL_STATE, data: action.payload };
    case Types.REQUEST:
      return { ...state, data: action.payload, loading: true };
    case Types.SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case Types.FAILURE:
      return { ...INITIAL_STATE, error: true };
    case Types.FAVORITE:
      return { ...state, isFavorite: !state.isFavorite };
    default:
      return { ...state };
  }
}

export const Creators = {
  signalRequest: payload => ({ type: Types.REQUEST, payload }),
  signalSuccess: payload => ({ type: Types.SUCCESS, payload }),
  signalFailure: payload => ({ type: Types.FAILURE, payload }),
  signalIsFavorite: payload => ({ type: Types.FAVORITE, payload }),
};
