import React, { Component } from "react";

import mockupFilipe from "~/assets/image/landingPage/mockupFilipe.png";
import mockupSebastiao from "~/assets/image/landingPage/mockupSebastiao.png";
import mockupSandra from "~/assets/image/landingPage/mockupSandra.png";

import videoFelipe from "~/assets/image/landingPage/felipe.mp4";
import videoSabastiao from "~/assets/image/landingPage/sabastiao.mp4";
import videoSandra from "~/assets/image/landingPage/sandra.mp4";

import backgroundCharacters from "~/assets/image/landingPage/backgroundCharacters.png";

import {
  Content,
  TitleBox,
  Title,
  MockupBox,
  MockupSection,
  MockupCelular,
  BackgroundCharacters,
} from "./style";

class Characters extends Component {
  render() {
    return (
      <Content>
        <TitleBox>
          <div data-aos="zoom-in">
            <Title>Aprenda passo a passo com nossos personagens</Title>
          </div>
        </TitleBox>

        <MockupBox>
          <MockupSection>
            <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="400">
              <MockupCelular src={mockupFilipe} />
              <video
                onMouseOver={(event) => event.target.play()}
                src={videoFelipe}
                type="video/mp4"
              />
            </div>
            <div data-aos="zoom-in" data-aos-delay="0" data-aos-offset="100">
              <BackgroundCharacters src={backgroundCharacters} />
            </div>
          </MockupSection>
          <MockupSection>
            <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="400">
              <MockupCelular src={mockupSebastiao} />
              <video
                onMouseOver={(event) => event.target.play()}
                src={videoSabastiao}
                type="video/mp4"
              />
            </div>
            <div data-aos="zoom-in" data-aos-delay="0" data-aos-offset="100">
              <BackgroundCharacters src={backgroundCharacters} />
            </div>
          </MockupSection>
          <MockupSection>
            <div data-aos="fade-up" data-aos-delay="400" data-aos-offset="400">
              <MockupCelular src={mockupSandra} />
              <video
                onMouseOver={(event) => event.target.play()}
                src={videoSandra}
                type="video/mp4"
              />
            </div>
            <div data-aos="zoom-in" data-aos-delay="0" data-aos-offset="100">
              <BackgroundCharacters src={backgroundCharacters} />
            </div>
          </MockupSection>
        </MockupBox>
      </Content>
    );
  }
}

export default Characters;
