import { call, put, select } from "redux-saga/effects";

import { Creators as SearchCreators } from "~/store/ducks/search";
import { Creators as TechCreators } from "~/store/ducks/technologies";
import { Creators as InfoCreators } from "~/store/ducks/info";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

export function* technologyRequest() {
  try {
    const { cursor, tech } = yield select(({ technologies }) => ({
      cursor: technologies.data && technologies.data.pageInfo.endCursor,
      tech: technologies.set
    }));

    if (!tech) throw new Error("Sem tech selecionada");

    const { data, error } = yield call(
      graphql,
      query.technology(cursor, tech._id)
    );

    if (error) throw new Error("Erro ao solicitar lista de Área Tecnológica");

    yield put(TechCreators.technologySuccess(data.data.areaTecnologica.sinais));
  } catch (e) {
    yield put(TechCreators.technologyFailure());
    console.tron.log(e.message);
  }
}

export function* technologySet({ payload }) {
  try {
    yield put(SearchCreators.setSearch(""));
    yield put(InfoCreators.setMode("technologies"));
    yield put(TechCreators.technologyRequest());
  } catch (e) {
    console.tron.log(e.message);
  }
}

export function* technologiesLoad() {
  try {
    const { list } = yield select(state => state.technologies);

    if (list) return;

    const { data, error } = yield call(graphql, query.technologies());

    if (error) throw new Error("Erro durante request das Áres Tecnológicas");

    yield put(TechCreators.technologiesSave(data.data.areaTecnologicas));
  } catch (e) {
    yield put(TechCreators.technologyFailure());
    console.tron.log(e.message);
  }
}
