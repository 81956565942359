import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Creators as LibraryCreators } from "~/store/ducks/library";

import Waiting from "~/components/Waiting";
import Empty from "../Empty";
import {
  Container,
  ListFolders,
  ItemContainer,
  ItemIcon,
  ItemText,
} from "./styles";

export default function List({ signalId, close, width }) {
  const dispatch = useDispatch();
  const { data, error } = useSelector((state) => state.library);

  const [folderList, setFolderList] = useState([]);

  const loadMore = useCallback(() => {
    dispatch(LibraryCreators.libraryRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(LibraryCreators.libraryRefresh());
    loadMore();
  }, [dispatch, loadMore]);

  useEffect(() => {
    const filteredList =
      data &&
      data.edges &&
      data.edges.filter(
        (node) =>
          !node.node.sinais.edges.find(
            (nodeSinal) => nodeSinal.node._id === signalId
          )
      );

    setFolderList(filteredList);
  }, [data, signalId]);

  const handleClickItem = (folderId, folderTitle) => {
    try {
      dispatch(
        LibraryCreators.libraryAddSignal({ folderId, signalId, folderTitle })
      );
      close();
    } catch (error) {}
  };

  if (error) return <div>Algo inesperado aconteceu =(</div>;
  if (!data) return <Waiting size={"small"} />;

  const Item = ({ item }) => {
    const [hover, setHover] = useState(false);

    return (
      <ItemContainer
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => handleClickItem(item._id, item.titulo)}
        aria-label={`Adicionar verbete a pasta ${item.titulo}`}
      >
        <ItemIcon hover={hover} />
        <ItemText maxWidth={width}>{item.titulo}</ItemText>
      </ItemContainer>
    );
  };

  return (
    <Container>
      {folderList && folderList.length ? (
        <ListFolders
          loadMore={loadMore}
          hasMore={data.pageInfo && data.pageInfo.hasNextPage}
          loader={<Waiting size={"small"} />}
          useWindow={true}
        >
          {folderList.map((folder) => (
            <Item key={folder.node._id} item={folder.node} />
          ))}
        </ListFolders>
      ) : (
        <Empty />
      )}
    </Container>
  );
}
