import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

import { Container, AppFooter } from "~/components";
import { ApplicationName, LoginRoute, LoginDescription } from '~/constants/routesName';

import { Creators as LoginCreators } from "~/store/ducks/login";

import {
  LoginHeader,
  LoginRegister,
  ForgetPasswordForm,
  ChangePasswordForm,
  ConfirmEmail
} from "./components";

import { LoginBody } from "./style";

const Login = () => {
  const [, page, securityToken] = window.location.pathname.split("/");
  const [form, setForm] = useState(page);
  const dispatch = useDispatch();
  const [
    isLogin,
    isRegister,
    isChangePassword,
    isForgetPassword,
    isMailSent,
    isConfirmEmail
  ] = [
    form === "login",
    form === "register",
    form === "change-password",
    form === "forget-password",
    form === "mail-sent",
    form === "confirm-email"
  ];

  useEffect(() => {
    dispatch(LoginCreators.loginErrorClear());
  }, [dispatch, form]);

  return (
    <Container>
      <Helmet>
        <title>{LoginRoute}</title>
        <meta name="description" content={LoginDescription}></meta>
      </Helmet>
      <LoginHeader />
      <LoginBody>
        {(isLogin || isRegister) && (
          <LoginRegister {...{ isLogin, isRegister, setForm }} />
        )}

        {(isForgetPassword || isMailSent) && (
          <ForgetPasswordForm {...{ isMailSent, setForm }} />
        )}

        {isChangePassword && (
          <ChangePasswordForm {...{ securityToken, setForm }} />
        )}

        {isConfirmEmail && <ConfirmEmail {...{ securityToken, setForm }} />}
      </LoginBody>
      <AppFooter />
    </Container>
  );
};

export default Login;
