import styled from "styled-components";
import { darken } from "polished";

import { FontAwesome } from "react-web-vector-icons";

import { colors, fonts } from "~/styles";

export const Container = styled.div`
  position: relative;
  font-family: ${fonts.VisbyRoundMedium};
  color: #34495e;
  z-index: 2;
`;

export const UserAvatar = styled.button`
  background: none;

  img {
    background: #999;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    border: 3px solid #ffffff;
  }
`;

export const Content = styled.div`
  position: absolute;
  flex-direction: column;
  max-height: 390px;
  width: 280px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 4px #00000029;
  right: calc(100% - 50px);
  top: calc(100% + 15px);
  border-radius: 3%;
  padding: 10px 0;
  display: ${(props) => (props.visible ? "block" : "none")};

  &::before {
    content: "";
    position: absolute;
    left: calc(100% - 47px);
    top: -15px;
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid #ffffff;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-around;
  border-bottom: 1px solid #00000029;
  padding: 20px 10px;

  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }

  div {
    display: flex;
    flex-direction: column;

    strong {
      font-size: 14px;
    }

    span {
      font-size: 12px;
    }
  }
`;

export const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 20px 20px;
  border-bottom: 1px solid #00000029;

  div {
    display: flex;
    align-items: center;
    margin-top: 15px;

    a {
      color: #34495e;
      font-size: 15px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        color: ${darken(0.5, "#34495e")};
        text-decoration: underline;
      }
    }

    i {
      margin-right: 20px;
    }
  }
`;

export const ButtonCredit = styled.button`
    &:hover {
    color: ${darken(0.3, "#34495e")};
    text-decoration: underline;
  }
`;

export const ButtonLogout = styled.button`
  padding: 20px;
  text-decoration: none;
  background: none;

  &:hover {
    color: ${darken(0.3, "#34495e")};
    text-decoration: underline;
  }
`;

export const Icons = styled(FontAwesome).attrs((props) => ({
  size: 20,
  color: colors.icons,
}))``;
