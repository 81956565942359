import React from "react";
import HOCModal from "~/components/HOCModal";

import Share from "./components/Share";

const ModalShare = ({ url, show, close, width, height, mobile }) => {
  return (
    <HOCModal
      isOpen={show}
      close={close}
      modalWidth={width}
      modalHeigth={height}
    >
      <Share url={url} onModalToogle={close} mobile={mobile} />
    </HOCModal>
  );
};

export default ModalShare;
