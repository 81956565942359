import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  padding: 10% 0;
  background: linear-gradient(180deg, #FFCE00 0%, #FFCE00 35%, #FFCE00B3 100%) no-repeat;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 50% 0;
  }
`;

export const TitleBox = styled.div`
  text-align: center;
  max-width: 700px;
  margin: auto;
  @media only screen and (max-width: ${MIN_TAB}px) {
    max-width: 450px;
  }
`;

export const Title = styled.h1`
  font-size: 50px;
  font-weight: bold;
  color: ${colors.white_label};
  font-family: ${fonts.VisbyRoundMedium};

  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 30px;
    margin: 0 20px 10%;
  }
`;

export const MockupBox = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 10% auto 0;
  max-width: 1000px;
`;

export const MockupSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 2%;
  div[data-aos="fade-up"] {
    z-index: 1;
    margin: 0 0 2%;
  }
  video {
    width: 100%;
    max-width: 183px;
    border-radius: 15%;
    padding-right: 1%;
  }

  div[data-aos="zoom-in"] {
    position: absolute;
    bottom: -14%;
    width: 125%;
  }

  @media only screen and (max-width: ${MIN_TAB}px) {
    margin: 0 5% 3%;
  }
`;

export const MockupCelular = styled.img`
  width: 100%;
  max-width: 183px;
  height: auto;
  position: absolute;
`;

export const BackgroundCharacters = styled.img`
  width: 100%;
  height: auto;
  max-width: 280px;
`;
