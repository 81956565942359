import { call, put, select } from "redux-saga/effects";

import { Creators as HistoryCreators } from "~/store/ducks/history";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

import { format, parseISO, isToday } from "date-fns";
import pt from "date-fns/locale/pt";

export function* historyRequest() {
  const { cursor, profile } = yield select(state => ({
    cursor: state.history.data && state.history.data.pageInfo.endCursor,
    profile: state.login.profile
  }));

  try {
    const response = yield call(
      graphql,
      query.buscarUsuarioHistorico(profile.email, cursor)
    );

    const { error, data } = response;

    if (error) {
      throw new { error: "@history/graphql_request_error" }();
    }

    const edges = data.data.usuarioHistoricos.edges.map(item => ({
      ...item,
      formattedDate: isToday(parseISO(item.node.updateAt))
        ? "Hoje"
        : format(parseISO(item.node.updateAt), "dd 'de' MMM'.'", { locale: pt })
    }));

    yield put(
      HistoryCreators.historySuccess({
        edges,
        pageInfo: data.data.usuarioHistoricos.pageInfo
      })
    );
  } catch (error) {
    // console.tron.log(error);
    yield put(HistoryCreators.historyFailure());
  }
}

export function* historyCreateItem({ payload }) {
  try {
    const { profile } = yield select(state => ({
      profile: state.login.profile
    }));

    const data = {
      idSinal: payload._id,
      emailUsuario: profile.email,
      titulo: payload.titulo,
      iconeRepresentativo: payload.iconeRepresentativo,
      personaNome: payload.persona.nome,
      personaAvatar: payload.persona.avatar
    };

    const response = yield call(graphql, query.addHistoricoUsuario(data));

    if (response.error) {
      throw new { error: "@history/graphql_request_error" }();
    }
  } catch (error) {
    yield put(HistoryCreators.historyFailure());
  }
}
