import styled from "styled-components";
import { FontAwesome } from "react-web-vector-icons";

export const Star = styled(FontAwesome).attrs(props => ({
  name: props.isFavorite ? "star" : "star-o",
  size: props.size || 16,
  color: "gold"
}))`
  padding: 5px 8px;
`;
