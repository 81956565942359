// import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";

import { ReactComponent as Empty } from "~/assets/svg/empty.svg";
import { MIN_TAB, MIN_WEB } from "~/constants/config";
import { fonts, colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  /* border: 1px solid gold; */
`;

export const ContentList = styled.ul`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-auto-rows: 140px;
  padding: 10px;
  /* border: 1px solid red; */

  @media only screen and (min-width: ${MIN_TAB}px) {
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-auto-rows: 180px;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-auto-rows: 240px;
  }
`;

export const ContentListRow = styled.ul`
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 1px solid #eaeaea;
  box-shadow: 0px 1px 3px #2a353e33;
  border-radius: 10px;
  margin: 10px;

  background-color: white;

  & :nth-child() {
    border-bottom: 1px solid #eaeaea;
  }

  & :last-child {
    border-bottom: none;
  }
`;

export const CenterContent = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
`;

export const Astro = styled(Empty)`
  width: 180px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    width: 210px;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    width: 270px;
  }
`;

export const Label = styled.span`
  color: #889baf;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 25px;
  padding: 20px;
  text-align: center;
  width: 300px;
`;
export const SugerirButton = styled.button`
  justify-content: center;
  width: 200px;
  height: 60px;
  background-color: ${colors.primary};
  margin: 30px 0 50px 0;
  border-radius: 5px;
  font-family: ${fonts.VisbyRoundMedium};
  color: white;
  font-weight: bold;
`;
