import React, { Component } from "react";
import { FontAwesome } from "react-web-vector-icons";

import mockupCelularRotateImg from "~/assets/image/landingPage/mockupCelularRotate.png";

import {
  Content,
  BackgroundEllipse,
  TitleBox,
  Title,
  SubTitle,
  MockupBox,
  MockupCelular,
  ButtonMore,
} from "./style";

let acoesInclusivas =
  "http://www.portaldaindustria.com.br/senai/canais/educacao-profissional/acoes-inclusivas/";

class InclusiveActions extends Component {
  render() {
    return (
      <Content>
        <BackgroundEllipse
          data-aos="zoom-in"
          data-aos-offset="600"
          data-aos-duration="800"
        />

        <MockupBox>
          <div
            data-aos="fade-right"
            data-aos-offset="600"
            data-aos-duration="800"
          >
            <MockupCelular src={mockupCelularRotateImg} />
          </div>
        </MockupBox>

        <TitleBox>
          <div data-aos="zoom-out" data-aos-offset="400">
            <Title>
              <b>Uma iniciativa SENAI para ações inclusivas</b>
            </Title>
          </div>
          <div data-aos="zoom-out" data-aos-offset="400">
            <SubTitle>
              O SENAI valoriza a diversidade e promove a formação profissional
              de todas as pessoas
            </SubTitle>
            <ButtonMore href={acoesInclusivas} target="_blank">
              Saiba mais
              <FontAwesome name="arrow-right" size={20} color="#fff" />
            </ButtonMore>
          </div>
        </TitleBox>
      </Content>
    );
  }
}

export default InclusiveActions;
