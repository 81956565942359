import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  padding: 10% 0;
  background: ${colors.backgroundLightGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  div[data-aos="fade-up"] {
    width: 80%;
  }
  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 20% 0;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  max-width: 700px;
  z-index: 1;
  @media only screen and (max-width: ${MIN_TAB}px) {
    flex-direction: column-reverse;
  }
`;

export const Title = styled.h1`
  font-size: 50px;
  color: ${colors.internalTitle};
  font-family: ${fonts.VisbyRoundMedium};
  @media only screen and (max-width: ${MIN_TAB}px) {
    max-width: 350px;
    font-size: 40px;
    text-align: center;
    margin: 0 0 5%;
  }
`;

export const TitleImg = styled.img`
  width: 100%;
  max-width: 350px;
  height: auto;
  @media only screen and (max-width: ${MIN_TAB}px) {
    max-width: 250px;
  }
`;

export const AreasBox = styled.div`
  background: ${colors.backgroundLightBlue};
  padding: 60px 40px;
  max-width: 1010px;
  margin: -30px auto 0;
  border-radius: 10px;
`;
