import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding: 5px;

  @media only screen and (max-width: ${MIN_TAB}px) {
    padding: 0;
  }
`;
