import React from "react";
import {
  Container,
  Modal,
  Action,
  Close,
  Icon,
  Title,
  Text,
  Body
} from "./style";

const ModalResult = ({ onClose, color, icon, title, text }) => (
  <Container>
    <Modal>
      <Action>
        <Close onClick={onClose}>×</Close>
      </Action>
      <Body>
        <Icon color={color} name={icon} />
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Body>
    </Modal>
  </Container>
);

export default ModalResult;
