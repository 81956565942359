import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { URL_APP_APPLE, URL_APP_GOOGLE } from "~/constants/config";

import { Creators as InfoCreators } from "~/store/ducks/info";

import {
  Content,
  Modal,
  Title,
  TitleRow,
  Box,
  Google,
  Apple,
  ButtonClose,
  IconClose,
} from "./style";

const PopUp = (props) => {
  return (
    <Content>
      <Modal tabindex="0">
        <TitleRow>
          <Title
            tabindex="1"
            aria-label={"Conheça o aplicativo SENAI Libras. Link para as lojas"}
          >
            Conheça o aplicativo SENAI Libras
          </Title>
          <ButtonClose
            tabindex="2"
            aria-label={
              "Fechar caixa de notificação dos aplicativos do Senai Libras"
            }
            onClick={() => props.notifyRequest(false)}
          >
            <IconClose />
          </ButtonClose>
        </TitleRow>
        <Box>
          <a
            aria-label={"Ir para página do Senai Libras na google play"}
            href={URL_APP_GOOGLE}
            target="_blank"
          >
            <Google />
          </a>
          <a
            aria-label={"Ir para página do Senai Libras na apple store"}
            href={URL_APP_APPLE}
            target="_blank"
          >
            <Apple />
          </a>
        </Box>
      </Modal>
    </Content>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      notifyRequest: InfoCreators.notifyRequest,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(PopUp);
