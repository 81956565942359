import React from "react";
import { useSelector } from "react-redux";
import MediaQuery from "react-responsive";

import { MIN_TAB } from "~/constants/config";
import Avatar from "./Avatar";

import { Container, ButtonLogin, IconsSingIn } from "./style";

const LoggedUser = () => {
  const { auth, profile } = useSelector((state) => state.login);

  return (
    <Container>
      {(auth && (
        <>
          <Avatar profile={profile} />
        </>
      )) ||
        (window.location.pathname !== "/login" && (
          <ButtonLogin to="/login">
            <MediaQuery minWidth={MIN_TAB}>Fazer Login</MediaQuery>
            <MediaQuery maxWidth={MIN_TAB}>
              <IconsSingIn />
            </MediaQuery>
          </ButtonLogin>
        ))}
    </Container>
  );
};

export default LoggedUser;
