import styled from "styled-components";
// import styled from 'styled-components/native';

import { ReactComponent as Error404 } from "~/assets/svg/error404.svg";
import { MIN_TAB } from "~/constants/config";

import { colors } from "~/styles";

export const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  /* align-items: center; */
  background-color: ${colors.backgroundSignal};
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  padding-top: 30px;
  flex-direction: column;
  align-items: center;
`;

export const ErrorImage = styled(Error404)`
  width: 240px;
  height: auto;

  @media only screen and (min-width: ${MIN_TAB}px) {
    width: 450px;
  }
`;

export const ErrorMessage = styled.span`
  padding: 20px 10px;
  font-family: "VisbyRoundCF-Medium";
  font-weight: bold;
  font-size: 28px;
  color: white;
  text-align: center;
`;

export const ErrorButton = styled.button`
  color: ${colors.backgroundSignal};
  font-family: "VisbyRoundCF-Medium";
  font-weight: bold;
  font-size: 16px;
  background-color: white;
  padding: 15px 25px;
  border-radius: 10px;
`;
