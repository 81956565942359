import { call, put } from "redux-saga/effects";

import { Creators as SignalCreators } from "~/store/ducks/signal";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

export function* signalRequest(action) {
  const body = yield query.signal(action.payload);
  const response = yield call(graphql, body);

  const {
    status,
    data: { data }
  } = response;

  if (status === 200) {
    if (data.sinal) {
      let sinal = data.sinal;

      if (!data.sinal.isVideo) {
        const regex = /[\/.](gif|jpg|jpeg|tiff|png)$/i;

        const edges = sinal.arquivos.edges.filter(
          node => !!regex.exec(node.node.caminho)
        );

        sinal = { ...data.sinal, arquivos: { edges } };
        console.tron.log("sinal", sinal);
      }

      yield put(SignalCreators.signalSuccess(sinal));
    } else {
      const error = "Simbolo não encontrado";
      yield put(SignalCreators.signalFailure({ error }));
    }
  } else {
    const error = "Algo inesperado aconteceu";
    yield put(SignalCreators.signalFailure({ error }));
  }
}
