import { InfoTypes as Types } from "~/constants/types";

const INITIAL_STATE = {
  search: null,
  collapsed: false,
  notify: true,
  listFormat: false,
  mode: "dashboard"
};

export default function info(state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case Types.SEARCH:
      return { ...state, search: payload };

    case Types.COLLAPSED:
      const { collapsed } = state;
      if (payload) return { ...state, collapsed: payload };
      return { ...state, collapsed: !collapsed };

    case Types.NOTIFY:
      return { ...state, notify: payload };

    case Types.LIST_FORMAT:
      return { ...state, listFormat: !state.listFormat };

    case Types.MODE:
      return { ...state, mode: payload };

    default:
      return state;
  }
}

export const Creators = {
  searchRequest: payload => ({ type: Types.SEARCH, payload }),
  collapsedRequest: payload => ({ type: Types.COLLAPSED, payload }),
  notifyRequest: payload => ({ type: Types.NOTIFY, payload }),
  listFormatRequest: payload => ({ type: Types.LIST_FORMAT, payload }),
  setMode: payload => ({ type: Types.MODE, payload })
};
