export const URL = process.env.REACT_APP_API_URL;

export const API = `${URL}/graphql`;

export const CDN = process.env.REACT_APP_CDN_URL;

export const URL_SENTRY = process.env.REACT_APP_URL_SENTRY;

export const MIN_TAB = 768;
export const MIN_WEB = 1366;
export const MIN_CEL = 430;
const apple = "https://www.apple.com/ios/app-store/";
const google =
  "https://play.google.com/store/apps/details?id=com.senailibras&hl=pt_BR";

export const URL_APP_APPLE = process.env.REACT_APP_URL_APPLE || apple;
export const URL_APP_GOOGLE = process.env.REACT_APP_URL_GOOGLE || google;

export const FACEBOOK_SENAI = 'https://facebook.com/senainacional/';
export const TWITTER_SENAI = 'https://twitter.com/SENAInacional/';
export const YOUTUBE_SENAI = 'https://www.youtube.com/user/senaibr';

export const VERSION = "1.0.2";
