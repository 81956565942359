import React, { Component } from "react";
import { Helmet } from "react-helmet";
import AOS from "aos";

import { LandingPageRoute, LandingPageDescription } from '~/constants/routesName';

import Container from "~/components/Container";

import Header from "./components/LandingPageHeader";
import Footer from "./components/LandingPageFooter";
import Presentation from "./components/Presentation";
import ButtonSearch from "./components/ButtonSearch";
import Characters from "./components/Characters";
import TryShare from "./components/TryShare";
import ExploreAreas from "./components/ExploreAreas";
import AnimationProcess from "./components/AnimationProcess";
import InclusiveActions from "./components/InclusiveActions";
import Opinions from "./components/Opinions";
import Finishing from "./components/Finishing";

import { BoxSection } from "./style";

class LandingPage extends Component {
  render() {
    return (
      <Container>
        <Helmet>
          <title>{LandingPageRoute}</title>
          <meta name="description" content={LandingPageDescription}>
          </meta>
        </Helmet>
        <Header />
        <BoxSection>
          <Presentation />
          <InclusiveActions /> {/*ações inclusivas*/}
          <ExploreAreas /> {/*Explore nossas áreas tecnológicas*/}
          <Characters /> {/*aprenda com nossos personagens*/}
          <Opinions />{/*opinião da comunidade*/}
          <AnimationProcess />{/*Nosso processo de Animação*/}
          <TryShare /> {/*experimente e compartilhe*/}
          <Finishing />
        </BoxSection>
        <Footer />
      </Container>
    );
  }

  componentDidMount() {
    AOS.init({});
  }
}

export default LandingPage;
