import InfiniteScroll from "react-infinite-scroller";
import styled from "styled-components";

import { MIN_TAB, MIN_WEB } from "~/constants/config";

export const Container = styled.div`
  flex: 1;
  margin: 10px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    padding: 0 20%;
  }

  h1 {
    color: #34495e;
    margin: 15px 0;
  }
`;

export const Content = styled(InfiniteScroll)`
  display: grid;
  grid-gap: 5px;
  /* grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); */
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  grid-auto-rows: 35px;
  padding-bottom: 50px;

  @media only screen and (min-width: ${MIN_TAB}px) {
    grid-gap: 15px;
    /* grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    grid-auto-rows: 45px;
  }

  @media only screen and (min-width: ${MIN_WEB}px) {
    /* grid-template-columns: repeat(5, minmax(240px, 1fr)); */
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    grid-auto-rows: 50px;
  }

  a {
    grid-row: span 4;
  }
`;

export const NomeGrupo = styled.span`
  grid-column: 1 / -1;
  text-transform: uppercase;
  color: #889baf;
  margin: 20px 0px;
  height: 30px;
`;
