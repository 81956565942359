import { call, put, select } from "redux-saga/effects";

import { Creators as DashboardCreators } from "~/store/ducks/dashboard";
import * as query from "~/constants/graphql";
import { graphql } from "~/services/api";

export function* dashboardRequest({ payload }) {
  try {
    const { cursor } = yield select(state => ({
      cursor: state.dashboard.data && state.dashboard.data.pageInfo.endCursor
    }));

    const { data, error } = yield call(graphql, query.dashboard(cursor));

    if (error) throw new Error("Erro durante o request");

    yield put(DashboardCreators.dashboardSuccess(data.data.sinals));
  } catch (e) {
    yield put(DashboardCreators.dashboardFailure());
  }

  // Buscando por área tecnológica.
  // const areaId = action.payload;

  // if (areaId !== undefined && areaId !== 'nenhuma') {
  //   const response = yield call(graphql, query.buscarPorAreaTecnologica(areaId));
  //   const { error, data: { data } } = response;

  //   if (!error) {
  //     const sinals = data.areaTecnologica.sinais;
  //     const areaTecnologicas = yield select(state => state.dashboard.areaTecnologicas);
  //     const filtro = sinals.edges.lenght > 0 ? sinals.edges[0].node.areaTecnologica.nome : null;
  //     delete data.areaTecnologica;

  //     yield put(DashboardCreators.dashboardSuccess({ ...data, sinals, areaTecnologicas, filtro }));
  //   }
  // }

  // try {
  //   const dashboard = yield select(state => state.dashboard);
  //   if (dashboard.data && areaId !== 'nenhuma') {
  //     return;
  //   }

  //   const response = yield call(graphql, query.dashboard());

  //   const {
  //     error,
  //     data: { data }
  //   } = response;

  //   if (!error) {
  //     const sinals = data.sinals;
  //     yield put(DashboardCreators.dashboardSuccess({ ...data, sinals }));
  //   } else {
  //     console.tron.log("ops...");
  //     const erro = "Erro durante o dashboardRequest";
  //     yield put(DashboardCreators.dashboardFailure(erro));
  //   }
  // } catch (error) {
  //   console.tron.log("Erro na requisição", error);
  // }
}

// export function* dashboardRequestMore(action) {
//   try {
//     const dashboard = yield select(state => state.dashboard);

//     let response;

//     if (!dashboard.data.sinals.edges[0].node.areaTecnologica) {
//       response = yield call(graphql, query.dashboard(action.payload));
//     } else if (
//       dashboard.data.sinals.edges[0].node.areaTecnologica &&
//       dashboard.data.sinals.pageInfo.hasNextPage
//     ) {
//       const id = dashboard.data.sinals.edges[0].node.areaTecnologica._id;
//       response = yield call(
//         graphql,
//         query.buscarPorAreaTecnologica(id, action.payload)
//       );
//     }

//     const {
//       error,
//       data: { data }
//     } = response;

//     if (!error) {
//       const sinals = {};
//       let areaTecnologicas;

//       if (data.areaTecnologica) {
//         sinals.totalCount = data.areaTecnologica.sinais.totalCount;
//         sinals.edges = dashboard.data.sinals.edges;
//         sinals.edges = sinals.edges.concat(data.areaTecnologica.sinais.edges);
//         sinals.pageInfo = data.areaTecnologica.sinais.pageInfo;
//         areaTecnologicas = dashboard.data.areaTecnologicas;
//         delete data.areaTecnologica;
//       } else {
//         sinals.totalCount = dashboard.data.sinals.totalCount;
//         sinals.edges = dashboard.data.sinals.edges;
//         sinals.edges = sinals.edges.concat(data.sinals.edges);
//         sinals.pageInfo = data.sinals.pageInfo;
//         areaTecnologicas = dashboard.data.areaTecnologicas;
//       }
//       yield put(
//         DashboardCreators.dashboardSuccess({
//           ...data,
//           sinals,
//           areaTecnologicas
//         })
//       );
//     } else {
//       console.tron.log("ops....");
//       const erro = "Erro durante o dashboardRequest";
//       yield put(DashboardCreators.dashboardFailure(erro));
//     }
//   } catch (error) {
//     console.tron.log("Erro na requisição", error);
//   }
// }
