import styled from "styled-components";
import { colors, fonts } from "~/styles";

export const Content = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  border: 1px solid ${colors.borderForm};
`;

export const ButtonOutline = styled.button`
  border-radius: 7px;
  padding: 10px 30px;
  display: flex;
  background: #4a7aff;
  color: white;
  margin: 14px 0;
  font-weight: bold;
  max-width: 170px;
  box-shadow: 0px 3px 4px 1px #2a353e33;
  &:hover {
    background: #3979dd;
  }
  &:focus {
    filter: drop-shadow(1px 1px 1px gray);
  }
  &:disabled {
    background: gray;
  }
  ${props =>
    props.inverse &&
    `
      background: #dbe5ff;
      color: #4a7aff;
      &:hover {
        background: #d4e0f6;
      }
    `}
  ${props => props.marginRight && `margin-right: 20px;`}
`;

export const Message = styled.span`
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  font-size: 16px;
  margin: 30px 0;
`;

export const InternalTitle = styled.div`
  font-family: ${fonts.VisbyRoundMedium};
  color: ${colors.internalTitle};
  font-weight: bold;
  font-size: 24px;
  margin: 20px 0 42px 0;
`;
