import React, { useState, useCallback } from "react";

import FireFoxLogo from "~/assets/image/audioDescription/firefox-logo-min.png";
import ChromeLogo from "~/assets/image/audioDescription/chrome-logo.png";
import IOSLogo from "~/assets/image/audioDescription/ios-logo.png";
import AndroidLogo from "~/assets/image/audioDescription/android-logo.png";

import ChromeSteps from "./components/ChromeSteps";
import FirefoxSteps from "./components/FirefoxSteps";
import AndroidSteps from "./components/AndroidSteps";
import IOSSteps from "./components/IOSSteps";

import Button from "../Button";

import { Content, StepsContainer } from "./styles";

const Body = () => {
  const [selected, setSelected] = useState("chrome");

  const handleShowSteps = useCallback((select) => {
    setSelected(select);
    console.log(select);
  }, []);

  const HandleTextTool = useCallback(() => {
    switch (selected) {
      case "chrome":
        return <ChromeSteps />;
      case "firefox":
        return <FirefoxSteps />;
      case "android":
        return <AndroidSteps />;
      case "ios":
        return <IOSSteps />;
      default:
        break;
    }
  }, [selected]);

  return (
    <Content>
      <div>
        <Button
          image={ChromeLogo}
          alt="Chrome"
          index={1}
          selected={selected === "chrome"}
          onClick={() => handleShowSteps("chrome")}
        />
        <Button
          image={FireFoxLogo}
          alt="FireFox"
          index={2}
          selected={selected === "firefox"}
          onClick={() => handleShowSteps("firefox")}
        />
        <Button
          image={AndroidLogo}
          alt="Android"
          index={3}
          selected={selected === "android"}
          onClick={() => handleShowSteps("android")}
        />
        <Button
          image={IOSLogo}
          alt="IOS"
          index={4}
          selected={selected === "ios"}
          onClick={() => handleShowSteps("ios")}
        />
      </div>
      <StepsContainer>
        <HandleTextTool />
      </StepsContainer>
    </Content>
  );
};

export default Body;
