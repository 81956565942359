import styled from "styled-components";

import { MIN_TAB } from "~/constants/config";
import { fonts, colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 30px 0;
  @media only screen and (max-width: ${MIN_TAB}px) {
    margin: 0 0 20px 0;
  }
`;

export const Title = styled.div`
  font-size: 25px;
  color: ${colors.tertiary};
  text-align: center;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  margin: 0 0 30px;

  @media only screen and (max-width: ${MIN_TAB}px) {
    font-size: 20px;
    margin: 0 0 20px;
  }
`;

export const TitleLists = styled.div`
  font-size: 20px;
  color: ${colors.tertiary};
  text-align: center;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
  margin: 0 0 5px;
`;

export const SubTitleLists = styled.div`
  font-size: 13px;
  color: ${colors.white_label};
  text-align: center;
  font-family: "VisbyRoundCF-Regular";
`;

export const BoxLists = styled.div`
  margin: 10px 0;
`;

export const NameLists = styled.div`
  font-size: 16px;
  color: ${colors.white_label};
  text-align: center;
  font-family: ${fonts.VisbyRoundMedium};
  font-weight: bold;
`;

export const Lists = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
  grid-template-rows: repeat(auto-fill, 1fr);
`;
