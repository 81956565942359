import React from "react";

import FirefoxStep from "~/assets/image/audioDescription/firefox-step.png";

import { Content } from "./styles";

const ChromeSteps = () => (
  <Content>
    <ul>
      <li tabIndex={5}>
        <b>1 - </b>
        <a
          href="https://addons.mozilla.org/pt-BR/firefox/addon/hatemile-for-browser/?src=search"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clique aqui
        </a>
        <span> para baixar a extensão em seu navegador</span>
        <img src={FirefoxStep} alt="Extensão firefox" />
      </li>
      <li tabIndex={6}>
        <b>2 - </b>
        <span>Clique em “Adicionar ao Firefox”.</span>
      </li>
      <li tabIndex={7}>
        <b>3 - </b>
        <span>Depois de instalado já está pronto para usar.</span>
      </li>
    </ul>
  </Content>
);

export default ChromeSteps;
