import styled from "styled-components";

import { ReactComponent as EmptyResult } from "~/assets/svg/empty.svg";
import { MIN_TAB } from "~/constants/config";

import { colors } from "~/styles";

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Banner = styled(EmptyResult)`
  width: 200px;
  height: auto;
  flex: 1;

  @media only screen and (min-width: ${MIN_TAB}px) {
    width: 300px;
  }
`;

export const Message = styled.span`
  font-family: "VisbyRoundCF-Medium";
  font-weight: bold;
  font-size: 16px;
  padding: 20px 10px;
  text-align: center;
  color: ${colors.subTitle};

  @media only screen and (min-width: ${MIN_TAB}px) {
    font-size: 28px;
  }
`;
